<script setup lang="ts">
import { computed, ref } from 'vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import Pagination from '@components/layout/Pagination.vue';

const props = defineProps<{
  total: number,
  page: number,
  limit: number
}>();

defineEmits<{
  (e: 'pageChanged', page: number): void;
  (e: 'limitChanged', limit: number): void;
}>();

// Pagination Setup
    const perPage = ref({ value: props.limit, text: `${props.limit}` });

// Pagination Buttons
const isFirstPage = computed(() => props.page === 1);
const isLastPage = computed(() => props.page === Math.ceil(props.total / props.limit));
const hasPreviousPage = computed(() => props.page > 1);
const hasNextPage = computed(() => props.page < Math.ceil(props.total / props.limit));


// Define the options for the per page dropdown
const perPageOptions = [
  { value: 6, text: '6' },
  { value: 12, text: '12' }, // Default value
  { value: 24, text: '24' },
  { value: 48, text: '48' },
];

</script>

<template>
  <Pagination v-if="props.total > 1">
    <template #pagination-dropdown>
      <div class="d-flex align-center gap-10">
        <span>Results per page:</span>
        <v-select v-model="perPage"
                  input-id="per-page"
                  aria-label="Select number per page"
                  label="text"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  :reduce="(value: any) => value.value"
                  @option:selected="(e: any) => $emit('limitChanged', e.value)" />
      </div>
    </template>

    <template #pagination-info>
      <p v-if="props.total > 0">
        Showing
        {{ ((props.page - 1) * props.limit) + 1 }} -
        {{ Math.min(props.total, ((props.page - 1) * props.limit) + props.limit) }}
        of {{ props.total }}
      </p>
      <p v-else>
        No results found
      </p>
    </template>


    <template v-if="props.total > 0 && (!isFirstPage || !isLastPage)" #pagination-buttons>
      <!-- First Page Button -->
      <ButtonComponent :disabled="isFirstPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronDoubleLeftIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', 1)" />

      <!-- Previous Page Button -->
      <ButtonComponent :disabled="!hasPreviousPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronLeftIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', props.page - 1)" />

      <!-- Next Page Button -->
      <ButtonComponent :disabled="!hasNextPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.ChevronRightIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', props.page + 1)" />

      <!-- Last Page Button -->
      <ButtonComponent :disabled="isLastPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.ChevronDoubleRightIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', Math.ceil(props.total / props.limit))" />
    </template>
  </Pagination>
</template>


