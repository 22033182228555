<script lang="ts" setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useApplicationStore } from '@stores/application';
import * as client from '@gabrielcam/api-client';
import timezones, { TimeZone } from 'timezones-list';
import { AlertVariant, ButtonType, ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import AlertBanner from '@components/AlertBanner.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import ButtonComponent from '@components/ButtonComponent.vue';

/**
 * Camera Schedule Configuration
 *
 * This file handles the camera scheduling logic using a custom cron-like format
 * that supports seconds-based intervals.
 *
 * For a detailed explanation of the schedule format and structure, refer to:
 * src/components/camera/CameraScheduleSection.md
 */

type schedule = {
  "job": "capture",
  "cron": string,
  "timezone": string,
  "active": boolean
}

const props = defineProps<{ cameraId: string }>();
const applicationStore = useApplicationStore();
const shadow = ref<client.Shadow>();
const reported = ref<schedule>();
const desired = ref<schedule>();
const cronExpression = ref<string>();
const cronEndExpression = ref<string>();
const cronActive = ref<boolean>();
const isSubmitting = ref<boolean>(false);
const isFetching = ref<boolean>(false);
const hours = Array.from({ length: 25 }, (_, i) => String(i).padStart(2, '0'));
const isMultiHour = computed(() => captureRateValue.value?.cron.startsWith("0 0 */"));

const isPending = computed((): boolean => !!desired.value && JSON.stringify(reported.value) != JSON.stringify(desired.value));
// For debugging to override pending state so we can resubmit the form
// const isPending = false;

const captureRates = [
  // Sub-hour intervals
  { label: "5 seconds", cron: "*/5 *" },
  { label: "10 seconds", cron: "*/10 *" },
  { label: "15 seconds", cron: "*/15 *" },
  { label: "20 seconds", cron: "*/20 *" },
  { label: "30 seconds", cron: "*/30 *" },
  { label: "1 minute", cron: "0 */1" },
  { label: "2 minutes", cron: "0 */2" },
  { label: "3 minutes", cron: "0 */3" },
  { label: "4 minutes", cron: "0 */4" },
  { label: "5 minutes", cron: "0 */5" },
  { label: "6 minutes", cron: "0 */6" },
  { label: "10 minutes", cron: "0 */10" },
  { label: "12 minutes", cron: "0 */12" },
  { label: "15 minutes", cron: "0 */15" },
  { label: "20 minutes", cron: "0 */20" },
  { label: "30 minutes", cron: "0 */30" },
  { label: "1 hour", cron: "0 0" },
  // 2+ hour intervals (fixed format, no start/end time)
  { label: "2 hours", cron: "0 0 */2 * *" },
  { label: "3 hours", cron: "0 0 */3 * *" },
  { label: "4 hours", cron: "0 0 */4 * *" },
  { label: "6 hours", cron: "0 0 */6 * *" },
];

const daysOfWeek = [
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" }
];

/**
 * Handles the change in capture rate.
 *
 * Updates the capture rate field and resets the start and end hour fields
 * if the new capture rate is a sub-hourly selection.
 *
 * @param {Object} newRate - The new capture rate object with label and cron properties.
 */
const handleCaptureRateChange = (newRate: { label: string; cron: string }): void => {
  // Update the capture rate field with the new rate
  setFieldValue("captureRate", newRate);

  // Do not update start and end hour fields for multi-hour selections
  if (isMultiHour.value) {
    return;
  }

  // Reset start and end hour fields for sub-hourly selections
  setFieldValue("startHour", "07");
  setFieldValue("endHour", "19");
};

// Form initialisation and schema
const { handleSubmit, defineField, setFieldValue } = useForm({
  initialValues: {
    startHour: '07',
    endHour: '19',
    captureRate: { label: "10 minutes", cron: "0 */10" },
    selectedDays: [],
    timezone: 'Europe/London',
  },
  validationSchema: yup.object({
    startHour: yup.number().required('Please specify an Start Hour.')
      .test(
        'is-less-than-end',
        'Start Hour must be earlier than the End Hour.',
        function (value) {
          const { endHour } = this.parent;
          return Number(value) < Number(endHour);
        }
      ),
    endHour: yup.number().required('Please specify an End Hour.')
      .test(
        'is-greater-than-start',
        'End Hour must be later than the Start Hour.',
        function (value) {
          const { startHour } = this.parent;
          return Number(value) > Number(startHour);
        }
      ),
  }),
});

// Define input fields
const [startHourValue] = defineField('startHour');
const [endHourValue] = defineField('endHour');
const [timezoneValue] = defineField('timezone');
const [captureRateValue] = defineField('captureRate');
const [selectedDaysValue] = defineField('selectedDays');

/**
 * Handles the form submission event.
 *
 * @param {object} values - The form values.
 * @returns {Promise<void>}
 */
const onSubmit = handleSubmit(async (values): Promise<void> => {
  isSubmitting.value = true;

  try {
    // Initialise an empty array to store the schedules
    let schedules: schedule[] = [];

    // Create a new schedule object with the provided values
    schedules.push({
      job: 'capture',
      cron: cronExpression.value!,
      timezone: values.timezone,
      active: cronActive.value!,
    });

    // If an end cron expression is provided, create another schedule object
    if (cronEndExpression.value) {
      schedules.push(
        {
          job: 'capture',
          cron: cronEndExpression.value,
          timezone: values.timezone,
          active: cronActive.value!,
        },
      )
    }

    // Update the camera's shadow with the new schedules
    shadow.value = await client.updateCameraByIdShadow({
      cameraId: props.cameraId,
      requestBody: {
        state: {
          desired: {
            schedule: schedules,
          },
        },
      },
    })
    isSubmitting.value = false;

    applicationStore.publishSuccessNotification({
      text: 'Update request sent.',
      autoCloseMs: 3000,
    });
  } catch {
    applicationStore.publishErrorNotification({
      text: 'Error while updating.'
    });
    isSubmitting.value = false;
    return;
  }
});

/**
 * Retrieves the capture schedule state from a list of schedules.
 *
 * @param {schedule[]} schedules - The list of schedules to search through.
 * @returns {schedule | undefined} The capture schedule state, or undefined if not found.
 */
const captureScheduleState = (schedules: schedule[]): schedule | undefined => schedules.find((schedule: schedule) => schedule.job === 'capture')

/**
 * Fetches the camera's shadow data from the API.
 *
 * @async
 * @returns {Promise<void>}
 */
const fetchShadow = async (): Promise<void> => {
  isFetching.value = true;
  shadow.value = await client.getCameraByIdShadow({ cameraId: props.cameraId })
  isFetching.value = false;
}

/**
 * Watch effect to update form fields based on the desired or reported schedule.
 */
watchEffect(() => {
  // Check if the desired schedule is available
  if (shadow.value?.state.desired && "schedule" in shadow.value?.state.desired) {
    // Update the desired schedule state
    desired.value = captureScheduleState(shadow.value.state.desired["schedule"] as schedule[]);
  }
  // Check if the reported schedule is available
  if (shadow.value?.state.reported && "schedule" in shadow.value?.state.reported) {
    // Update the reported schedule state
    reported.value = captureScheduleState(shadow.value.state.reported["schedule"] as schedule[]);
  }

  // Get the cron value from the desired or reported schedule
  const cronValue = desired.value || reported.value;
  // Exit if no cron value is available
  if (!cronValue) return;

  // Split the cron value into its fields
  const [secondField = "0", minuteField = "*", hourField = "*", , , dayOfWeekField = "*"] = cronValue.cron.split(" ");

  // Detect if the capture rate is a multi-hour rate (e.g. */2, */3, */4)
  const multiHourMatch = hourField.match(/\*\/(\d+)/);
  const matchedRate = multiHourMatch
    ? captureRates.find(rate => rate.cron === `0 0 */${multiHourMatch[1]} * *`)
    : captureRates.find(rate => rate.cron === `${secondField} ${minuteField}`);

  // Update the capture rate form field if a match is found
  if (matchedRate) {
    setFieldValue("captureRate", matchedRate);
  } else {
    console.warn("No matching capture rate found for:", cronValue.cron);
  }

  /**
   * Update the start and end hour form fields based on the capture rate.
   */
  if (multiHourMatch) {
    setFieldValue("startHour", "00");
    setFieldValue("endHour", "24");
  } else if (hourField.includes("-")) {
    // Preserve user's previously selected hours
    const [startHour, endHour] = hourField.split("-").map(h => h.padStart(2, "0"));
    setFieldValue("startHour", startHour ?? startHourValue.value);
    setFieldValue("endHour", endHour ? (Number(endHour) + 1).toString().padStart(2, "0") : endHourValue.value);
  }

  /**
   * Update the selected days form field based on the day of week field.
   */
  const daysArray = dayOfWeekField === "*" ? [] : dayOfWeekField.split(",");
  setFieldValue("selectedDays", daysArray as never);
});


/**
 * Watch effect to update cron expressions based on capture rate and selected days.
 */
watchEffect(() => {
  // Check if capture rate and selected days are available
  if (!(captureRateValue.value && selectedDaysValue.value)) {
    return;
  }

  // Determine if the capture rate is a multi-hour rate (e.g., */2, */3, */4)
  const isMultiHour = captureRateValue.value.cron.startsWith("0 0 */");

  // Initialise the hour field based on the capture rate
  let hourField;
  if (isMultiHour) {
    // For multi-hour rates, keep the `*/X` format as is
    hourField = captureRateValue.value.cron.split(" ")[2];
  } else {
    // For sub-hourly rates, use the start and end hour range
    const adjustedEndHour = Number(endHourValue.value) - 1;
    hourField = `${startHourValue.value}-${adjustedEndHour}`;
  }

  // Set the day of month and month fields to wildcard values
  const dayOfMonthField = "*";
  const monthField = "*";

  // Set the day of week field based on the selected days
  const dayOfWeekField = selectedDaysValue.value?.length
    ? selectedDaysValue.value.toString()
    : "*";

  // Update the cron active flag based on the selected days
  cronActive.value = !!selectedDaysValue.value?.length;

  // Update the cron expression based on the capture rate and selected days
  // Prevent modification of `*/X` schedules
  cronExpression.value = isMultiHour
    ? `0 0 ${hourField} ${dayOfMonthField} ${monthField} ${dayOfWeekField}`
    : `${captureRateValue.value.cron} ${hourField} ${dayOfMonthField} ${monthField} ${dayOfWeekField}`;

  // Update the end cron expression for non-multi-hour rates
  cronEndExpression.value = isMultiHour
    ? undefined
    : `0 0 ${Number(endHourValue.value)} ${dayOfMonthField} ${monthField} ${dayOfWeekField}`;

  console.group("cron info");
  console.log(`cron: ${cronExpression.value}`);
  console.log(`end cron: ${cronEndExpression.value ?? "No end cron"}`);
  console.log(`active: ${cronActive.value}`);
  console.groupEnd();
});


onMounted(async () => await fetchShadow())
</script>

<template>
  <div class="container-card">
    <AlertBanner v-if="isPending"
                 :variant="AlertVariant.Warning"
                 :has-bottom-margin="true"
                 :icon-name="IconName.InformationCircleIcon"
                 :icon-style="IconStyle.Outline">
      <template #mainContent>
        <span class="text--size-xs">
          <Heading level="5">
            Changes are pending
          </Heading>
          The settings below have been sent to the device and are awaiting confirmation that they have been accepted.
          Click refresh to check if the update has been applied.
        </span>
      </template>
    </AlertBanner>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Camera Schedule
          </Heading>
          <p>
            Choose the days, times & rate that you'd like the camera to take photos.
          </p>
        </div>

        <Loading v-if="isFetching" />

        <div v-else class="fields">
          <div class="row-half">
            <div class="field">
              <label for="timezone">Timezone</label>
              <v-select id="timezone"
                        v-model="timezoneValue"
                        :clearable="false"
                        :disabled="isPending"
                        :reduce="(timezone: TimeZone) => timezone.tzCode"
                        :options="timezones" />
              <ErrorMessage name="timezone" class="message-error message" as="p" />
            </div>
          </div>

          <div class="row-quarter">
            <div class="field">
              <label for="start-hour">Start Hour</label>
              <!-- Start Hour -->
              <v-select id="start-hour"
                        v-model="startHourValue"
                        :clearable="false"
                        :disabled="isPending || isMultiHour"
                        :options="hours" />
              <ErrorMessage name="startHour" class="message-error message" as="p" />
            </div>

            <div class="field">
              <!-- End Hour -->
              <label for="end-hour">End Hour</label>
              <v-select id="end-hour"
                        v-model="endHourValue"
                        :clearable="false"
                        :disabled="isPending || isMultiHour"
                        :options="hours" />
              <ErrorMessage name="endHour" class="message-error message" as="p" />
            </div>

            <div class="field">
              <!-- Capture Rate -->
              <label for="capture-rate">Capture Rate</label>
              <v-select id="end-hour"
                        v-model="captureRateValue"
                        :clearable="false"
                        :disabled="isPending"
                        :options="captureRates"
                        @update:model-value="handleCaptureRateChange" />
              <ErrorMessage name="captureRate" class="message-error message" as="p" />
            </div>
          </div>

          <div class="row">
            <div v-for="(day, index) in daysOfWeek" :key="index" class="checkbox-field">
              <label :for="day.value">{{ day.label }}</label>
              <Field :id="day.value"
                     v-model="selectedDaysValue"
                     :disabled="isPending"
                     name="days"
                     type="checkbox"
                     :value="day.value" />
            </div>
          </div>
        </div>
      </div>

      <AlertBanner v-if="isMultiHour"
                   :variant="AlertVariant.Warning"
                   :has-bottom-margin="true"
                   :icon-name="IconName.InformationCircleIcon"
                   :icon-style="IconStyle.Outline">
        <template #mainContent>
          <span class="text--size-xs">
            Start and End times are disabled for capture rates of 2 hours or more. The full day (00:00 - 24:00) will be used, capturing an image every {{ captureRateValue.label }}.
          </span>
        </template>
      </AlertBanner>

      <ButtonContainer>
        <ButtonComponent v-if="isPending"
                         :variant="ButtonVariant.Dark"
                         :is-block-btn="true"
                         :type="ButtonType.Button"
                         :loading="isFetching"
                         :disabled="isFetching"
                         @click="fetchShadow">
          Refresh
        </ButtonComponent>

        <ButtonComponent v-if="!(isPending || isFetching)"
                         :variant="ButtonVariant.Dark"
                         :type="ButtonType.Submit"
                         :loading="isSubmitting"
                         :disabled="isSubmitting || isFetching">
          Update
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </div>
</template>
