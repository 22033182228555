<script lang="ts" setup>
import { reactive, Ref, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import {
  BadgeVariant,
  BreadcrumbTitles,
  ButtonSize,
  ButtonVariant,
  CameraStatus,
  NotificationCountVariant,
  PageNames,
} from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import BadgeComponent from '@components/BadgeComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import TableComponent from '@components/table/TableComponent.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import { storeToRefs } from 'pinia';
import { extractErrorMessage } from '@utils/errorUtils';
import { dateTimeFormat } from '@utils/date';

enum TableHeaders {
  DeviceId = 'Camera Name',
  CreatedAt = 'Created At',
  SystemType = 'System Type',
  Status = 'Status',
  CameraOverview = 'Camera Overview',
  Delete = 'Delete',
}

const tableKey = ref(0);  // Reactive key for forcing table reloads because of <Suspense>

const showDeleteCameraModel = ref(false);
const deleting = ref(false);
const cameraToDelete = ref('') as Ref<string>;
const deleteCameraModelFunc = ref<Function>(() => deleteCamera);
const registeredCameras = ref<client.Camera[]>([]);
const unregisteredCameras = ref<client.Camera[]>([]);
const isLoading = ref<boolean>(false);

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const { activeUser } = applicationStore;

const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CAMERA, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CAMERA, applicationStore.activeOrganisation!);
const showRegister = applicationStore.canUser(client.Entitlements.REGISTER_CAMERA, applicationStore.activeOrganisation!);

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteCameraModel.value = false;
  deleting.value = false;
}

async function showDeleteConfirmModal(row: client.Camera, redraw: Function): Promise<void> {
  cameraToDelete.value = row.serialNumber;
  showDeleteCameraModel.value = true;
  deleteCameraModelFunc.value = async (): Promise<void> => {
    await deleteCamera(row);
    await redraw();
  };
}



/**
 * Fetches the list of registered and unregistered cameras.
 *
 * @returns {Promise<client.Camera[]>} A promise that resolves to an array of registered cameras.
 */
async function fetchCameraLists(): Promise<client.Camera[]> {
  isLoading.value = true;

  try {
    // Fetch registered cameras specific to the active organisation
    const registeredRes = await client.listCameras({ organisation: applicationStore.activeOrganisation!.id });
    // Filter and sort the registered cameras to only include those with a status of REGISTERED and sort by creation date
    registeredCameras.value = registeredRes.data
      .filter((camera: client.Camera) => camera.status === client.CameraStatus.REGISTERED)
      // There is also the sorting option on the table that is probably better suited if more cameras are added
      .sort((a: client.Camera, b: client.Camera) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA; // Show newest first
      });

  } catch (error) {
    // Log any errors that occur while fetching registered cameras
    console.error("Error fetching registered cameras:", error);
    // Ensure registeredCameras is an empty array on error
    registeredCameras.value = [];
  }

  // Only fetch unregistered cameras if the user has adminMode permission
  if (adminMode.value) {
    try {
      // Attempt to fetch all unregistered cameras regardless of organisation
      const unregisteredRes = await client.listUnregisteredCameras();
      // Filter the unregistered cameras to only include those with a status of UNREGISTERED
      unregisteredCameras.value = unregisteredRes.data.filter(
        (camera: client.Camera) => camera.status === client.CameraStatus.UNREGISTERED
      );
    } catch (error) {
      // Extract and handle the error message using extractErrorMessage
      const errorMessage = extractErrorMessage(error);
      // Log any errors that occur while fetching unregistered cameras
      console.error("Error fetching unregistered cameras:", errorMessage);
      // Ensure unregisteredCameras is an empty array on any error
      unregisteredCameras.value = [];
    }
  } else {
    // Clear unregisteredCameras if adminMode is disabled
    unregisteredCameras.value = [];
  }

  // Set the loading state to false after fetching the camera lists
  isLoading.value = false;

  // Return the registered cameras for the TableComponent
  return registeredCameras.value;
}


async function deleteCamera(row: client.Camera): Promise<void> {
  deleting.value = true;
  try {
    await client.deleteCameraById({ cameraId: row.id });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
      return;
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    return;
  } finally {
    deleting.value = false;
  }

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted camera.',
    autoCloseMs: 3000,
  });

  // Refresh the camera list
  await reloadTable();
}

const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.DeviceId,
    dataField: 'serialNumber',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '97%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.CreatedAt,
    dataField: 'createdAt',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
    isSortable: true,
    sortableType: 'date',
    // Format the date only on the  UI to enable sorting within the table component
    formatFunction: (value: string) => dateTimeFormat(activeUser?.timezone).format(new Date(value)),
  },
  {
    labelText: TableHeaders.Status,
    dataField: 'status',
    headerClassName: 'status',
    columnWidth: '1%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.CameraOverview,
    dataField: 'registered',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
  },
  {
    labelText: TableHeaders.Delete,
    dataField: 'delete',
    headerClassName: 'status',
    columnWidth: '1%',
  },
];

// Insert SystemType after the DeviceId column if isAdmin
if (adminMode) {
  columns.splice(2, 0, {
    labelText: TableHeaders.SystemType,
    dataField: 'systemType',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
    isSortable: true,
  });
}

const table = reactive({
  columns: columns,
  sortable: {
    order: 'createdAt',
    sort: 'desc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllCameras, active: true },
];

// Hack to reload the table because we have to use <Suspense>
async function reloadTable(): Promise<void> {
    tableKey.value++;
}
</script>

<template>
  <SubHeader heading="All Cameras"
             level="2">
    <template #buttons>
      <template v-if="isLoading" />
      <template v-else>
        <ButtonComponent v-if="adminMode"
                         :is-block-btn="true"
                         :to="{ name: PageNames.CameraNew }"
                         :variant="ButtonVariant.Primary"
                         :icon-position="IconPosition.Left"
                         :icon-name="IconName.PlusIcon"
                         :icon-style="IconStyle.Solid">
          Create Camera
        </ButtonComponent>
        <ButtonComponent v-if="showRegister"
                         :is-block-btn="true"
                         :to="{ name: PageNames.CameraRegister }"
                         :variant="ButtonVariant.Primary"
                         :icon-position="IconPosition.Left"
                         :icon-name="IconName.PlusIcon"
                         :icon-style="IconStyle.Solid">
          Register Camera
        </ButtonComponent>
        <ButtonComponent v-if="adminMode"
                         :is-block-btn="true"
                         :variant="ButtonVariant.Primary"
                         :to="{ name: PageNames.CameraUnregistered }"
                         :notification-count="unregisteredCameras.length"
                         :notification-count-variant="NotificationCountVariant.Danger">
          Unregistered Cameras
        </ButtonComponent>
      </template>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <TableComponent :key="tableKey"
                          :retrieve-data="fetchCameraLists"
                          :columns="table.columns"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No cameras found"
                          strap-line="Get started by registering your first camera"
                          :button-variant="ButtonVariant.Dark"
                          button-text="Register Camera"
                          :icon-name="IconName.CameraIcon"
                          :icon-style="IconStyle.Outline"
                          :to="{ name: PageNames.CameraRegister }" />
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.labelText === TableHeaders.DeviceId">
                <router-link :to="{ name: PageNames.CameraOverview, params: { id: row.id } }">
                  {{ row.serialNumber }}
                </router-link>
              </template>

              <template v-if="column.labelText === TableHeaders.CreatedAt">
                <div class="d-flex justify-center">
                  <span class="text--white-space-nowrap">
                    {{ column.formatFunction ? column.formatFunction(row[column.dataField]) : row[column.dataField] }}
                  </span>
                </div>
              </template>

              <template v-if="column.labelText === TableHeaders.SystemType">
                {{ row.systemType }}
              </template>

              <template v-if="column.labelText === TableHeaders.Status">
                <div class="d-flex justify-center gap-20">
                  <BadgeComponent v-if="row.status === CameraStatus.Registered"
                                  :is-pill="true"
                                  :variant="BadgeVariant.Success">
                    Registered
                  </BadgeComponent>
                  <BadgeComponent v-if="row.status === CameraStatus.Unregistered"
                                  :is-pill="true"
                                  :variant="BadgeVariant.Danger">
                    Unregistered
                  </BadgeComponent>
                </div>
              </template>

              <template v-if="column.labelText === TableHeaders.CameraOverview">
                <div class="d-flex justify-center gap-20">
                  <ButtonComponent :to="{ name: PageNames.CameraOverview, params: { id: row.id } }"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Dark">
                    Overview
                  </ButtonComponent>
                </div>
              </template>


              <template v-if="column.labelText === TableHeaders.Delete">
                <div class="d-flex justify-center gap-20">
                  <ButtonComponent v-if="showDelete || showUpdate"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Danger"
                                   @click="showDeleteConfirmModal(row, fetchCameraLists)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TableComponent>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete Camera Modal -->
  <ModalComponent :visible="showDeleteCameraModel"
                  :heading-title="`Delete Camera ${cameraToDelete}`"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the camera: <span class="text--semibold">{{ cameraToDelete }}</span>
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :loading="deleting"
                       :disabled="deleting"
                       :variant="ButtonVariant.Danger"
                       @click="deleteCameraModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
