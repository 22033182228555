import { datadogRum } from '@datadog/browser-rum';
import {version} from '../../package.json';
import { Environment, environment } from '@utils/environment';

if ([
    Environment.uat,
    Environment.production,
].includes(environment)) {
    datadogRum.init({
        applicationId: 'bc7c6854-584d-47ce-afd9-e1fd46191654',
        clientToken: 'pub60320fd26d59294884b0da31368b3776',
        site: 'datadoghq.eu',
        service: 'hub',
        env: environment,
        version: version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
    });

    datadogRum.startSessionReplayRecording();
}
