import { nextTick, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { RouteLocationNormalized, Router } from 'vue-router';

// Enums for themes
export enum ThemeNames {
  DEFAULT = 'default',
  ORGANISATION = 'organisation',
  CLIENT = 'client',
}

// Type for ThemeName
export type ThemeName = ThemeNames.DEFAULT | ThemeNames.ORGANISATION | ThemeNames.CLIENT;

interface ThemeSettings {
  logoBase64?: string;
  primaryColour?: string;
  secondaryColour?: string;
}

interface RGB {
  r: number;
  g: number;
  b: number;
}

export const useThemeStore = defineStore('useThemeStore', {
  state: () => ({
    currentTheme: ThemeNames.DEFAULT as ThemeName,
    isThemeReady: false,
    organisationTheme: ref<ThemeSettings | null>(null),
    clientTheme: ref<ThemeSettings | null>(null),
    unsavedPrimaryColor: ref<string | null>(null),
    unsavedSecondaryColor: ref<string | null>(null),
    unsavedLogoBase64: ref<string | null>(null),
  }),

  actions: {
    /**
     * Initialises the theme based on the current route and organisation settings.
     *
     * @param route The current route object.
     * @param router The router instance.
     */
    async initTheme(route: RouteLocationNormalized, router: Router) {
      this.isThemeReady = false;

      // Wait for the router, we get the theme to use from the route metadata
      await router.isReady();

      // Determine the theme
      if (route.meta["theme"] === ThemeNames.CLIENT) {
        this.setTheme(ThemeNames.CLIENT);
      } else {
        this.setTheme(ThemeNames.ORGANISATION);
        this.fetchOrganisationTheme();
      }

      // Watch for changes in the active organisation after login
      // This only applies for organisation routes
      watch(
        // Get the current active organisation
        () => useApplicationStore().activeOrganisation,
        // Handle changes to the active organisation
        (newOrg) => {
          // Check if the new organisation has settings and the current route is not a client route
          if (newOrg?.settings && route.meta["theme"] !== ThemeNames.CLIENT) {
            // Set the theme to organisation and fetch the organisation theme settings
            this.setTheme(ThemeNames.ORGANISATION);
            this.fetchOrganisationTheme();
          }
        },
        { immediate: true, deep: true }
      );

      // Wait for the next tick to ensure all theme updates are applied
      await nextTick();
      // Set the theme ready flag to true
      this.isThemeReady = true;
    },

    /**
     * Sets the current theme and updates the document element and local storage accordingly.
     *
     * @param theme The new theme to be set. Must be one of the ThemeNames enum values.
     */
    setTheme(theme: ThemeName) {
      this.currentTheme = theme;
      document.documentElement.setAttribute("data-theme", theme);
    },


    /**
     * Fetches the organisation theme (if it exists) and applies it to the application.
     * If no organisation theme is found, it falls back to the default theme.
     */
    fetchOrganisationTheme() {
      const applicationStore = useApplicationStore();
      const activeSettings = applicationStore.activeOrganisation?.settings ?? null;

      // Check if the active organisation has theme settings and extract them
      if (activeSettings) {
        this.organisationTheme = {
          logoBase64: activeSettings.logoBase64,
          primaryColour: activeSettings.primaryColour || "",
          secondaryColour: activeSettings.secondaryColour || "",
        };

        // Apply the organisation theme
        this.applyThemeStyles();
      } else {
        console.warn("⚠️ No Organisation Theme Found, falling back to default.");
        this.organisationTheme = null;
        this.setTheme(ThemeNames.DEFAULT);
        this.applyThemeStyles();
      }
    },

    /**
     * Fetches the client theme (if it exists) for the given view ID.
     *
     * @param viewId The ID of the view to fetch the client theme for.
     */
    async fetchClientTheme(viewId: string) {
      try {
        // Fetch the view details with the client includes
        const details = await client.getViewById({
          viewId,
          includes: [client.Resources_Client.CLIENT],
        });

        // Check if the client settings are available and extract them
        if (details.includes?.client?.settings) {
          this.clientTheme = {
            logoBase64: details.includes.client.settings.logoBase64 || "",
            primaryColour: details.includes.client.settings.primaryColour || "",
            secondaryColour: details.includes.client.settings.secondaryColour || "",
          };

          // Apply the client theme styles
          this.applyThemeStyles();
        } else {
          console.warn("Client theme settings not found, using defaults.");
          this.clientTheme = null;
          this.applyThemeStyles();
        }
      } catch (error) {
        console.error("Error fetching client theme:", error);
        this.clientTheme = null;
        this.applyThemeStyles();
      }
    },


    /**
     * Applies the theme styles to the application based on the current theme and organisation ID.
     *
     * @param organisationId The ID of the organisation to apply the theme for. If not provided, the active organisation will be used.
     */
    applyThemeStyles(organisationId?: string) {
      const applicationStore = useApplicationStore();

      // Prevent applying styles if editing a different organisation
      if (organisationId && organisationId !== applicationStore.activeOrganisation?.id) {
        console.warn("Skipping theme application: Not the active organisation.");
        return;
      }

      // Determine the theme type and get the corresponding theme
      const isOrganisationTheme = this.currentTheme === ThemeNames.ORGANISATION;
      const isClientTheme = this.currentTheme === ThemeNames.CLIENT;
      const theme = isOrganisationTheme ? this.organisationTheme : isClientTheme ? this.clientTheme : null;

      // Check if the theme data is complete before applying styles
      if (!theme?.primaryColour || !theme.secondaryColour) {
        console.warn("Theme data incomplete. Skipping update.");
        return;
      }

      // Apply primary and secondary colors
      const root = document.documentElement;
      root.style.setProperty("--tls-primary-color", theme.primaryColour);
      root.style.setProperty("--tls-secondary-color", theme.secondaryColour);
      root.style.setProperty("--tls-primary-text", this.getTextColor(theme.primaryColour));
      root.style.setProperty("--tls-secondary-text", this.getTextColor(theme.secondaryColour));

      // Apply the correct logo (Client or Organisation)
      root.style.setProperty("--tls-logo", `url('${theme.logoBase64 || "/src/assets/logos/gabrielcam-logo.png"}')`);

      // Set the active theme attribute
      root.setAttribute("data-theme", this.currentTheme);

      // Mark theme as ready
      this.isThemeReady = true;
    },


    /**
     * Returns an accessible text color based on the provided color and WCAG contrast requirements.
     *
     * @param color The color to determine the accessible text color for.
     * @returns The accessible text color as a CSS variable.
     */
    getTextColor(color: string | undefined): string {
      if (!color) return 'var(--tls-default-text-dark)';

      // Attempt to match the color in HSL format
      const hslMatch = color.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
      // If the color is not in HSL format, default to dark text
      if (!hslMatch) return 'var(--tls-default-text-dark)';

      // Extract the hue, saturation, and lightness values from the HSL match
      // Ensure h, s, l are always defined numbers
      const h = Number(hslMatch[1] ?? 0);
      const s = Number(hslMatch[2] ?? 0);
      const l = Number(hslMatch[3] ?? 0);

      // Convert the HSL color to RGB
      const { r, g, b } = this.hslToRgb(h, s, l);

      // Define fixed theme text colors
      const lightText: RGB = { r: 229, g: 229, b: 229 };
      const darkText: RGB = { r: 38, g: 38, b: 38 };

      // Calculate contrast ratios
      const contrastWithLight = this.getContrastRatio({ r, g, b }, lightText);
      const contrastWithDark = this.getContrastRatio({ r, g, b }, darkText);

      // Return the best accessible text color
      return contrastWithDark >= 4.5
        ? 'var(--tls-default-text-dark)'
        : contrastWithLight >= 4.5
          ? 'var(--tls-default-text-light)'
          : this.getAccessibleTextColor(l);
    },

    /** Converts HSL to RGB to check WCAG contrast ratio */
    hslToRgb(h: number, s: number, l: number): RGB {
      s /= 100;
      l /= 100;

      const k = (n: number): number => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n: number): number => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

      return {
        r: Math.round(f(0) * 255),
        g: Math.round(f(8) * 255),
        b: Math.round(f(4) * 255),
      };
    },

    /** WCAG Contrast Ratio Calculation */
    getContrastRatio(color1: RGB, color2: RGB): number {
      const luminance = (r: number, g: number, b: number): number => {
        const a: [number, number, number] = [r, g, b].map((v) => {
          v /= 255;
          return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        }) as [number, number, number]; // Ensures it's always a tuple

        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
      };

      // Explicitly define lum1 & lum2 to prevent undefined issues
      const lum1 = luminance(color1.r, color1.g, color1.b) + 0.05;
      const lum2 = luminance(color2.r, color2.g, color2.b) + 0.05;

      return lum1 > lum2 ? lum1 / lum2 : lum2 / lum1;
    },

    /** Generate an accessible HSL text color if needed */
    getAccessibleTextColor(l: number): string {
      return l < 50 ? 'var(--tls-default-text-light)' : 'var(--tls-default-text-dark)';
    },



    /**
     * Sets the temporary primary color for instant UI feedback.
     *
     * @param color The new primary color to be set.
     */
    setTemporaryPrimaryColor(color: string) {
      // Update the unsaved primary color state
      this.unsavedPrimaryColor = color;
      // Update the CSS variables
      document.documentElement.style.setProperty('--tls-primary-color', color);
      document.documentElement.style.setProperty('--tls-primary-text', this.getTextColor(color));
    },

    /** Sets the temporary secondary color for instant UI feedback
     *
     * @param color The new secondary color to be set
     */
    setTemporarySecondaryColor(color: string) {
      // Update the unsaved secondary color state
      this.unsavedSecondaryColor = color;
      // Update the CSS variables
      document.documentElement.style.setProperty('--tls-secondary-color', color);
      document.documentElement.style.setProperty('--tls-secondary-text', this.getTextColor(color));
    },

    /** Sets the temporary logo for instant UI feedback
     *
     * @param logoBase64 The new logo to be set
     */
    setTemporaryLogo(logoBase64: string) {
      // Update the unsaved logo state
      this.unsavedLogoBase64 = logoBase64;
      // Update the CSS variables
      document.documentElement.style.setProperty('--tls-logo', `url('${logoBase64}')`);
    },

    /**
     * Resets any unsaved changes to the theme settings, reverting to the last saved values.
     */
    resetUnsavedChanges() {
      const applicationStore = useApplicationStore();
      const activeOrganisation = applicationStore.activeOrganisation;

      // Only reset if the active organisation exists
      if (!activeOrganisation) return;

      // Retrieve the last saved primary and secondary colors from the API
      const savedPrimary = activeOrganisation.settings?.primaryColour || "";
      const savedSecondary = activeOrganisation.settings?.secondaryColour || "";
      const savedLogo = activeOrganisation.settings?.logoBase64 || "/src/assets/logos/gabrielcam-logo.png";

      // Reset the unsaved color and logo states
      this.unsavedPrimaryColor = null;
      this.unsavedSecondaryColor = null;
      this.unsavedLogoBase64 = null;

      // Ensure the UI uses the specific color variables
      // Remove any previously set primary and secondary color properties
      document.documentElement.style.removeProperty('--tls-primary-color');
      document.documentElement.style.removeProperty('--tls-secondary-color');
      document.documentElement.style.removeProperty('--tls-logo');

      // Set the specific color properties to the saved values
      document.documentElement.style.setProperty('--tls-primary-color', savedPrimary);
      document.documentElement.style.setProperty('--tls-secondary-color', savedSecondary);
      document.documentElement.style.setProperty('--tls-logo', `url('${savedLogo}')`);

      // Update the text colors based on the saved primary and secondary colors
      document.documentElement.style.setProperty('--tls-primary-text', this.getTextColor(savedPrimary));
      document.documentElement.style.setProperty('--tls-secondary-text', this.getTextColor(savedSecondary));
    },
  },
});
