export enum Environment {
    production = 'production',
    uat = 'uat',
    development = 'development',
}

const hostname = window.location.hostname
export const isProduction = ['hub.gabrielcam.com', 'live.gabrielcam.com', 'admin.gabrielcam.com'].includes(hostname);
export const isUat = ['hub.uat.gabrielcam.com'].includes(hostname);
export const environment = isProduction ? Environment.production : isUat ? Environment.uat : Environment.development;
