<template>
  <hr class="horizontal-rule">

  <section class="pagination">
    <div class="pagination__dropdown">
      <slot name="pagination-dropdown" />
    </div>

    <div class="pagination__info">
      <slot name="pagination-info" />
    </div>

    <div v-if="$slots['pagination-buttons']" class="pagination__buttons">
      <slot name="pagination-buttons" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

hr.horizontal-rule {
  border: none;
  border-top: 1px solid var(--tls-gray-300);
  width: calc(100% + (clamp($gap-mobile, 2vw, $gap-desktop) * 2));
  margin-inline: calc(-1 * clamp($gap-mobile, 2vw, $gap-desktop));
  margin-block: $margin-bottom;
}

.pagination {
  display: flex;
  flex-direction: column;
  gap: $gap-default;
  align-items: center;
  justify-content: center;
  font-size: var(--tls-font-size-xs);

  @media screen and (width >= $breakpoint-lg) {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__dropdown {
    order: 3;

    @media screen and (width >= $breakpoint-lg) {
      order: 1;
      width: auto;
    }

    :deep(.v-select) {
      width: 8ch;
      margin: 0 auto;
    }
  }

  &__info {
    order: 2;

    @media screen and (width >= $breakpoint-lg) {
      order: 2;
    }

    :deep(p) {
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    gap: $gap-mobile;
    order: 1;

    @media screen and (width >= $breakpoint-lg) {
      order: 3;
    }
  }
}
</style>