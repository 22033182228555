<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { Line } from 'vue-chartjs';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import * as client from '@gabrielcam/api-client';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import EmptyState from '@layouts/EmptyState.vue';
import dayjs from 'dayjs';
import { SkeletonVariant } from '@viewModels/enums';
import SkeletonLoading from '@components/SkeletonLoading.vue';

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const stats = ref<client.ViewStat[]>();
const data = ref();
const isLoading = ref<boolean>(false);

// Get the value of a CSS variable from the DOM
const getCSSVariable = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

// Theme Colors
const themeColors: string[] = [
  getCSSVariable('--tls-primary-color'),
  getCSSVariable('--tls-secondary-color'),
  getCSSVariable('--tls-primary-100'),
  getCSSVariable('--tls-gray-800'),
  getCSSVariable('--tls-gray-300'),
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
);

const chartOptions: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      title: {
        display: true,
        text: 'Date / Time',
        color: themeColors[3],
      },
      time: {
        unit: 'day',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
        color: themeColors[3],
      },
      grid: {
        display: true,
        color: themeColors[4],
      },
    },
    storageImageCount: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Image Count',
        color: themeColors[3],
      },
      ticks: {
        padding: 10,
        color: themeColors[3],
      }
    },
    storageImageSizeBytes: {
      type: 'linear',
      position: 'right',
      title: {
        display: true,
        text: 'Storage Size (MB)',
        color: themeColors[3],
      },
      ticks: {
        padding: 10,
        color: themeColors[3],
      },
      grid: {
        drawOnChartArea: false, // Prevents grid lines from overlapping
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        useBorderRadius: true,
        borderRadius: 2,
        padding: 20,
        color: themeColors[3],
        font: {
          size: 14,
          family: 'Open Sans, sans-serif',
        },
      },
    },
  },
};

watch(() => stats.value, () => {
  if (!stats.value?.length) return;

  const imageCounts = stats.value.map((stat) => {
    return {
      x: new Date(stat.timestamp),
      y: stat.storageImageCount
    }
  });

  const imageSizes = stats.value.map((stat) => {
    return {
      x: new Date(stat.timestamp),
      y: Math.floor(stat.storageImageSizeBytes / 1e6) // Removes decimals
    };
  });

  data.value = {
    datasets: [
      {
        label: 'Image Count',
        data: imageCounts,
        backgroundColor: themeColors[0],
        borderColor: themeColors[0],
        borderWidth: 2,
        yAxisID: 'storageImageCount',
        xAxisID: 'x',
        cubicInterpolationMode: 'monotone',
      },
      {
        label: 'Storage Size (MB)',
        data: imageSizes,
        backgroundColor: themeColors[1],
        borderColor: themeColors[1],
        borderWidth: 2,
        yAxisID: 'storageImageSizeBytes',
        xAxisID: 'x',
        cubicInterpolationMode: 'monotone',
      }
    ],
  }
});

onMounted(async() => {
  isLoading.value = true;
  const today = dayjs().endOf('day').toISOString();
  const oneMonthAgo = dayjs().subtract(1, 'month').startOf('day').toISOString();
  const response = await client.listViewByIdStats({
    viewId: props.viewId,
    startDate: oneMonthAgo,
    endDate: today,
  });
  stats.value = response.data;
  isLoading.value = false;
})
</script>

<template>
  <SkeletonLoading v-if="isLoading" :variant="SkeletonVariant.Table" :line-count="8" />
  <div v-else>
    <Line v-if="data"
          id="chart-battery"
          :data="data"
          :options="chartOptions" />
    <EmptyState v-else
                heading-text="Not available"
                :icon-name="IconName.PresentationChartLineIcon"
                :icon-style="IconStyle.Outline" />
  </div>
</template>
