<script lang="ts" setup>
import { computed } from 'vue';
import { HeadingVariant } from '@viewModels/enums';

interface HeadingProps {
  variant?: HeadingVariant;
  level: '1' | '2' | '3' | '4' | '5' | '6';
  hasBottomMargin?: boolean;
  anchorId?: string | null;
}

const props = withDefaults(defineProps<HeadingProps>(), {
  variant: HeadingVariant.Inherit,
  hasBottomMargin: false,
  anchorId: null,
});

const tag = computed(() => `h${props.level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6');
</script>

<template>
  <component :is="tag"
             :id="props.anchorId ? `#${props.anchorId}` : null"
             :class="[variant, { 'mb-20': props.hasBottomMargin }]">
    <slot />
  </component>
</template>


<style lang="scss">
// Global styles
h1, .h1 {
  font-size: clamp(var(--tls-font-size-lg), 2vw, var(--tls-font-size-xl)); /* 22px - 28px */
}

h2, .h2 {
  font-size: clamp(var(--tls-font-size-md), 2vw, var(--tls-font-size-lg)); /* 20px - 24px */
}

h3, .h3 {
  font-size: clamp(var(--tls-font-size), 2vw, var(--tls-font-size-md)); /* 16px - 22px */
}

h4, .h4 {
  font-size: clamp(var(--tls-font-size), 2vw, var(--tls-font-size)); /* 16px */
}

h5, .h5 {
  font-size: clamp(var(--tls-font-size-xs), 2vw, var(--tls-font-size)); /* 14px - 16px */
}

h6, .h6 {
  font-size: clamp(var(--tls-font-size-xs), 2vw, var(--tls-font-size)); /* 14px - 16px */
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 600;
  text-wrap: balance;
}
</style>

<style lang="scss" scoped>
//Scoped Styles
.inherit {
  color: inherit;
}
.primary {
  color: var(--tls-primary-color);
}
.secondary {
  color: var(--tls-secondary-color);
}
.success {
  color: var(--tls-success-color);
}
.danger {
  color: var(--tls-error-color);
}
.warning {
  color: var(--tls-warning-color);
}
.info {
  color: var(--tls-info-color);
}
.dark {
  color: var(--tls-gray-800);
}
.light {
  color: var(--tls-gray-100);
}
</style>
