<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { BreadcrumbTitles, ButtonSize, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import TableComponent from '@components/table/TableComponent.vue';
import { extractErrorMessage } from '@utils/errorUtils';

interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

const enum TableHeaders {
  Name = 'Name',
  Edit = 'Edit',
  Test = 'Test',
  Delete = 'Delete',
}

const applicationStore = useApplicationStore();
const showNew = applicationStore.canUser(client.Entitlements.CREATE_TRANSFER_SETTING, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_TRANSFER_SETTING, applicationStore.activeOrganisation!);
const showTest = applicationStore.canUser(client.Entitlements.UPDATE_TRANSFER_SETTING, applicationStore.activeOrganisation!);
const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_TRANSFER_SETTING, applicationStore.activeOrganisation!);

const transferSettingName = ref('');
const showDeleteTransferSettingModel = ref(false);
const deleteClientModelFunc = ref<Function>(() => deleteSetting);
const deleting = ref(false);
const isLoading = ref<boolean>(true);
const isTestingRow = ref<string>('');

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllTransfers, active: true },
];


// Table
const tableRef = ref<DirectoryTableType | null>(null);
const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.Name,
    dataField: 'name',
    headerClassName: 'text--white-space-nowrap',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Test,
    dataField: 'test',
    headerClassName: 'status',
    columnWidth: '1%',
  },
  {
    labelText: TableHeaders.Edit,
    dataField: 'edit',
    headerClassName: 'status',
    columnWidth: '1%',
  },
  {
    labelText: TableHeaders.Delete,
    dataField: 'delete',
    headerClassName: 'status',
    columnWidth: '1%',}
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'name',
    sort: 'asc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

async function getTransferSettings(): Promise<client.ListTransferSettingsResponse> {
  isLoading.value = true;

  try {
    const res = await client.listTransferSettings({ organisation: applicationStore.activeOrganisation!.id });

    if (!res || !res.data) {
      throw new Error("Invalid response structure from listTransferSettings.");
    }

    return res;
  } catch (error) {
    applicationStore.publishErrorNotification({ text: 'Error fetching transfer list' });
    console.error("Error fetching project list:", error);
    throw error;
  } finally {
    isLoading.value = false;
  }
}

// Get the transfer list for the table
async function getTransferListForTable(): Promise<client.TransferSetting[]> {
  const res = await getTransferSettings();
  return res.data || []; // Extract and return the data array
}

const closeDeleteConfirmModal = async (): Promise<void> =>  {
  showDeleteTransferSettingModel.value = false;
  deleting.value = false;
}

const showDeleteConfirmModal = async (row: client.TransferSetting): Promise<void> => {
  showDeleteTransferSettingModel.value = true;
  transferSettingName.value = row.name;
  deleteClientModelFunc.value = async (): Promise<void> => {
    await deleteSetting(row);
  };
}

const deleteSetting = async (row: client.TransferSetting): Promise<void> => {
  deleting.value = true;
  try {
    await client.deleteTransferSettingById({ transferSettingId: row.id });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
      return;
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    return;
  } finally {
    deleting.value = false;
  }
  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted transfer setting.',
    autoCloseMs: 3000,
  });

  // Refresh the table
  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}

const testConnection = async (row: client.TransferSetting): Promise<void> => {
  isTestingRow.value = row.id;
  try {
    await client.testTransferSettingById({ transferSettingId: row.id })
    applicationStore.publishSuccessNotification({
      text: 'Successfully transferred to your connection.',
      autoCloseMs: 3000,
    });
  } catch (error: unknown) {
    const message = extractErrorMessage(error)
    applicationStore.publishErrorNotification({ text: message });
  } finally {
    isTestingRow.value = '';
  }
}

onMounted(async () => {
  await getTransferSettings();
});
</script>

<template>
  <SubHeader :heading="BreadcrumbTitles.AllTransfers"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="showNew"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Primary"
                       :to="{ name: PageNames.TransferSettingCreate }"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add Transfer
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <TableComponent ref="tableRef"
                          :columns="columns"
                          :retrieve-data="getTransferListForTable"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No transfers found"
                          strap-line="Add a new transfer"
                          :button-variant="ButtonVariant.Dark"
                          button-text="Add transfer setting"
                          :icon-name="IconName.UserGroupIcon"
                          :icon-style="IconStyle.Outline"
                          :to="{ name: PageNames.TransferSettingCreate }" />
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.labelText === TableHeaders.Test">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="showTest"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Dark"
                                   :loading="isTestingRow === row.id"
                                   :disabled="isTestingRow === row.id"
                                   @click="testConnection(row)">
                    Test connection
                  </ButtonComponent>
                </div>
              </template>

              <template v-if="column.labelText === TableHeaders.Edit">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="showUpdate"
                                   :to="{ name: PageNames.TransferSettings, params: { id: row.id } }"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Dark">
                    Edit
                  </ButtonComponent>
                </div>
              </template>

              <template v-if="column.labelText === TableHeaders.Delete">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="showDelete"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Danger"
                                   @click="showDeleteConfirmModal(row)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TableComponent>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete Client Modal -->
  <ModalComponent :visible="showDeleteTransferSettingModel"
                  heading-title="Delete Client"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--semibold">{{ transferSettingName }}</span> setting?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Button"
                       :is-block-btn="true"
                       :loading="deleting"
                       :disabled="deleting"
                       :variant="ButtonVariant.Danger"
                       @click="deleteClientModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
:deep(.table-wrapper) {
  margin-bottom: unset;
}
</style>
