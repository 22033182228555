import { getApp, initializeApp } from 'firebase/app';
import { isProduction } from '@utils/environment';

initializeApp({
  apiKey: isProduction ? 'AIzaSyByryC8B73wVADlDFvoCjpJE0GEhRJVC8E' : 'AIzaSyBidlPTHT-Iy8_rPgFl_Bw700GIQsDEVeg',
  authDomain: isProduction ? 'gc-prod-5574604.firebaseapp.com' : 'gc-uat-2191033.firebaseapp.com',
});

const firebaseApp = getApp();

export { firebaseApp };
