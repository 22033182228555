<script lang="ts" setup>
import { useRoute } from 'vue-router';
import CameraBatteryVoltageThresholdNotification
  from '@components/notification/cameraBatteryVoltageThresholdNotification.vue';
// import CameraUSBUnpluggedNotification from '@components/notification/cameraUSBUnpluggedNotification.vue';

const route = useRoute();
const cameraId = route.params['id'] as string;
</script>

<template>
  <CameraBatteryVoltageThresholdNotification :camera-id="cameraId" />
<!--  <CameraUSBUnpluggedNotification :camera-id="cameraId" />-->
</template>
