<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, ButtonSize, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import TableComponent from '@components/table/TableComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import EmptyState from '@layouts/EmptyState.vue';
import ModalComponent from '@components/ModalComponent.vue';

const enum TableHeaders {
  Name = 'Name',
  Edit = 'Edit',
  Delete = 'Delete',
}
interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

const route = useRoute();
const clientId = route.params['id'] as string;
const router = useRouter();
const applicationStore = useApplicationStore();
const showNew = applicationStore.canUser(client.Entitlements.CREATE_PROJECT, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_PROJECT, applicationStore.activeOrganisation!);
const showDeleteProjectModel = ref(false);
const deleting = ref(false);
const deleteProjectModelFunc = ref<Function>(() => deleteProject);
const projectName = ref('');

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteProjectModel.value = false;
  deleting.value = false;
}

async function showDeleteConfirmModal(row: client.Project): Promise<void> {
  showDeleteProjectModel.value = true;
  projectName.value = row.name;

  deleteProjectModelFunc.value = async (): Promise<void> => {
    await deleteProject(row);
  };
}

async function getProjectList(): Promise<client.Project[]> {
  // Filter in API not client side
  const res = await client.listProjects({ organisation: applicationStore.activeOrganisation!.id, client: clientId });
  res.data = res.data.filter((x) => x.client == clientId);
  return res.data;
}

const tableRef = ref<DirectoryTableType | null>(null);
const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.Name,
    dataField: 'name',
    headerClassName: 'text--white-space-nowrap',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Edit,
    dataField: 'id',
    columnWidth: '1%',
  },
  {
    labelText: TableHeaders.Delete,
    dataField: 'delete',
    columnWidth: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'name',
    sort: 'asc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

async function deleteProject(row: client.Project): Promise<void> {
  deleting.value = true;
  try {
    await client.deleteProjectById({ projectId: row.id });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
      return;
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    return;
  } finally {
    deleting.value = false;
  }

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted project.',
    autoCloseMs: 3000,
  });

  // Refresh the table
  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}

// Handle navigation
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /clients
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Clients as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form>
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Projects
        </Heading>
        <p>These are all the projects belonging to this client.</p>
      </div>
      <div class="fields">
        <TableComponent ref="tableRef"
                        :retrieve-data="getProjectList"
                        :columns="columns"
                        :sortable="table.sortable">
          <template #table-empty>
            <EmptyState heading-text="No projects found"
                        :strap-line="showNew ? 'Get started by creating your first project' : ''"
                        :button-variant="ButtonVariant.Dark"
                        button-text="New Project"
                        :icon-name="IconName.ClipboardDocumentListIcon"
                        :icon-style="IconStyle.Outline"
                        :to="{ name: PageNames.ClientProjectsCreate, params: { id: clientId } }" />
          </template>
          <template #cell="{ row, column }">
            <template v-if="column.labelText === TableHeaders.Edit">
              <div class="d-flex gap-20">
                <ButtonComponent :to="{ name: PageNames.ProjectEdit, params: { id: row.id } }"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Dark">
                  Edit
                </ButtonComponent>
              </div>
            </template>
            <template v-if="column.labelText === TableHeaders.Delete">
              <div class="d-flex gap-20">
                <ButtonComponent v-if="showDelete"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Danger"
                                 @click="showDeleteConfirmModal(row)">
                  Delete
                </ButtonComponent>
              </div>
            </template>
          </template>
        </TableComponent>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent v-if="showNew"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ClientProjectsCreate, params: { id: clientId } }"
                       :variant="ButtonVariant.Dark">
        New Project
      </ButtonComponent>
    </ButtonContainer>
  </form>
  <!-- Delete Project Modal -->
  <ModalComponent :visible="showDeleteProjectModel"
                  heading-title="Delete Project"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--semibold">{{ projectName }}</span> project?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Button"
                       :is-block-btn="true"
                       :loading="deleting"
                       :disabled="deleting"
                       :variant="ButtonVariant.Danger"
                       @click="deleteProjectModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
