<script lang="ts" setup>
import { useRoute } from 'vue-router';

import ViewFirstImageNotReceivedBy from '@components/notification/viewFirstImageNotReceivedBy.vue';

const route = useRoute();
const viewId = route.params['id'] as string;
</script>

<template>
  <ViewFirstImageNotReceivedBy :view-id="viewId" />
</template>
