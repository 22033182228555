<script setup lang="ts">
import { useRoute } from 'vue-router';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ViewTransferForm from '@components/view/ViewTransferForm.vue';
import { onMounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';

// Stores
const route = useRoute();

// Views
const viewData = ref<client.View | null>(null);
const viewId = route.params['id'] as string;
const currentViewName = ref<string>();

// Loading
const isLoading = ref<boolean>(true);

const fetchViewData = async (): Promise<void> => {
  try {
    const data = await client.getViewById({ viewId });
    viewData.value = data;
    currentViewName.value = data.name;
  } catch (error) {
    console.error("Error fetching view data:", error);
  }
};

onMounted(async () => {
  isLoading.value = true;
  await fetchViewData();
  isLoading.value = false;
});
</script>

<template>
  <ContainerCard>
    <Suspense>
      <template #default>
        <ViewTransferForm :view-id="viewId" />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
