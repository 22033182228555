export const ina219BusVoltageToValue = (value: number): string => {
    let V = value;  // Replace with your initial value
    V >>= 3;
    V *= 4;
    return (V * 0.001).toString();
}

export const valueToIna219BusVoltage = (voltageStr: number): number => {
    let V = voltageStr / 0.001;
    V /= 4;
    V <<= 3;
    return V;
}
