<script setup lang="ts">
import Heading from '@components/Heading.vue';

const props = defineProps<{
  level: '1' | '2' | '3' | '4' | '5' | '6';
  heading: string;
}>();

const level = props.level || '2';
</script>

<template>
  <header class="sub-heading">
    <Heading class="sub-heading__title" :level="level">
      {{ heading }}
    </Heading>
    <div v-if="$slots['buttons']" class="sub-heading__actions">
      <slot name="buttons" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.sub-heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  min-height: 35px; // Ensures consistency when buttons are present

  @media (width >= $breakpoint-lg) {
    flex-direction: row;
    align-items: center;
  }

  &__title {
    flex-grow: 1;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media (width >= $breakpoint-lg) {
      flex-direction: row;
    }
  }
}
</style>

