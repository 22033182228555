<script setup lang="ts">
import { ref, watch } from 'vue';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

// Interface
interface SearchProps {
  onSearchFunction?: (value: string) => Promise<void>;
  onClearFunction?: () => Promise<void>;
  placeholder?: string | null;
}

const props = defineProps<SearchProps>();

// Emits
const push = defineEmits<{
  (e: 'onSearched', outcome: unknown): void;
}>();

// Constants
const MINIMUM_LOADING_TIME = 500;
const viewerName = ref<string>('');
const isSubmitting = ref<boolean>(false);

/**
 * Handles the search functionality when the search button is clicked.
 *
 * @async
 * @function onSearch
 * @returns {Promise<void>}
 */
async function onSearch(): Promise<void> {
  // Check if the viewer name is empty or if the onSearchFunction prop is not provided
  if (!viewerName.value || !props.onSearchFunction) return;

  isSubmitting.value = true;
  // Start the timer
  const startTime = Date.now();

  try {
    const outcome = await props.onSearchFunction(viewerName.value);

    // Ensure the delay is at least the minimum loading time
    const elapsedTime = Date.now() - startTime;
    if (elapsedTime < MINIMUM_LOADING_TIME) {
      await new Promise((resolve) => setTimeout(resolve, MINIMUM_LOADING_TIME - elapsedTime));
    }

    push('onSearched', outcome);
  } finally {
    isSubmitting.value = false;
  }
}

// Clear Input Logic
function clearSearch(): void {
  viewerName.value = '';
  if (props.onClearFunction) {
    props.onClearFunction();
  }
}

// Watch for changes in viewerName
watch(
  () => viewerName.value,
  async (newValue) => {
    if (newValue === '' && props.onClearFunction) {
      await props.onClearFunction();
    }
  }
);
</script>


<template>
  <div class="search">
    <div class="search__input-wrapper">
      <input id="search-input"
             ref="searchInput"
             v-model="viewerName"
             aria-label="Search"
             autocomplete="off"
             name="search"
             type="search"
             :placeholder="props.placeholder || 'Search...'"
             @keydown.enter="onSearch">
      <XMarkIcon v-if="viewerName"
                 class="search__clear-icon"
                 @click="clearSearch" />
    </div>
    <ButtonComponent :variant="ButtonVariant.Dark"
                     :disabled="!viewerName"
                     :loading="isSubmitting"
                     @click="onSearch">
      Search
    </ButtonComponent>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/variables' as *;

.search {
  display: flex;
  align-items: center;
  gap: $gap-default;

  .search__input-wrapper {
    position: relative;
    flex: 1;
  }

  .search__clear-icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    fill: var(--tls-gray-600);
    transition: fill 300ms ease;

    &:hover {
      fill: var(--tls-gray-800);
    }
  }

  button {
    flex-shrink: 0;
  }

  // Mobile styles
  @media screen and (max-width: $breakpoint-lg) {
    .search {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: $gap-default;
      align-items: center;

      .search__input-wrapper {
        flex: unset;
      }

      button {
        width: auto;
      }
    }
  }
}
</style>
