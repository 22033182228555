<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import LogLines from '@components/LogLines.vue';
import EmptyState from '@layouts/EmptyState.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { SkeletonVariant } from '@viewModels/enums';
import SkeletonLoading from '@components/SkeletonLoading.vue';

// Interface Camera Logs
interface CameraRecentLogProps {
  cameraId: client.Camera['id'];
  isLoading?: boolean;
}

// Define Props
const props = defineProps<CameraRecentLogProps>();

const isLoading = ref(props.isLoading ?? false);
const logs = ref<client.Log[]>();

onMounted(async() => {
  isLoading.value = true;
  const response = await client.listLogsByResourceAndId({
    id: props.cameraId,
    resource: client.Resources.CAMERA,
    limit: 3
  });
  logs.value = response.data;
  isLoading.value = false;
})
</script>

<template>
  <SkeletonLoading v-if="isLoading" :variant="SkeletonVariant.Table" :line-count="8" />
  <template v-else>
    <LogLines v-if="logs?.length" :logs="logs" />
    <EmptyState v-else
                heading-text="No logs available"
                :icon-name="IconName.BookOpenIcon"
                :icon-style="IconStyle.Outline" />
  </template>
</template>
