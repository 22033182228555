<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import LogTableComponent from '@components/LogTableComponent.vue';
const route = useRoute();
const cameraId = route.params['id'] as string;
</script>

<template>
  <LogTableComponent :resource="client.Resources.CAMERA"
                     :resource-id="cameraId" />
</template>
