<script setup lang="ts">
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { extractErrorMessage, extractErrorFields } from '@/utils/errorUtils';
import { AlertVariant, BreadcrumbPaths, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { ColorPicker } from 'vue3-colorpicker';
import { useThemeStore } from '@stores/useThemeStore';
import AlertBanner from '@components/AlertBanner.vue';
import Loading from '@components/Loading.vue';


// This form is shared between create client and edit client
// For create client as the API only accepts name, we submit the name, fetch the org id and then hit the update client to submit the theme settings

// Form Interface
interface ClientForm {
  name: string;
  primaryColour: string;
  secondaryColour: string;
  logo: string;
}

// Form Validation
const { handleSubmit, setErrors } = useForm<ClientForm>({
  validationSchema: yup.object({
    name: yup.string().required('Client Name is required'),
    primaryColour: yup.string().nullable(),
    secondaryColour: yup.string().nullable(),
    logo: yup.string().nullable(),
  }),
});


// Stores
const route = useRoute();
const router = useRouter();
const applicationStore = useApplicationStore();
const themeStore = useThemeStore();

// Setup
const clientId = route.params['id'] as string | undefined;
const isEditMode = !!clientId;
const currentClient = ref<client.Client | null>(null);
const showSaveAlert = ref(false);
const fileInputRef = ref<HTMLInputElement | null>(null);

// Loading
const isLoading = ref<boolean>(false);

// Form Fields
const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: primaryColourValue } = useField<string>('primaryColour', 'primaryColour');
const { value: secondaryColourValue } = useField<string>('secondaryColour', 'secondaryColour');
const { value: logoValue } = useField<string>('logo', 'logo');

onMounted(async () => {
  if (isEditMode) {
    isLoading.value = true;

    const fetchedClient = await client.getClientById({ clientId: clientId as string });
    currentClient.value = fetchedClient;

    nameValue.value = fetchedClient.name;
    primaryColourValue.value = fetchedClient.settings?.primaryColour ?? applicationStore.defaultTheme.primaryColour ?? '';
    secondaryColourValue.value = fetchedClient.settings?.secondaryColour ?? applicationStore.defaultTheme.secondaryColour ?? '';
    logoValue.value = fetchedClient.settings?.logoBase64 ?? applicationStore.defaultTheme.logoBase64 ?? '';

    isLoading.value = false;
  }
});

/**
 * Handles the form submission, either updating an existing client or creating a new one.
 *
 * @param {Object} values - The form values.
 */
const onSubmit = handleSubmit(async (values) => {
  isLoading.value = true;

  try {
    // Check if we're editing a client (if the page has the clientId param it's an edit)
    if (isEditMode) {
      // Update existing client
      await client.updateClientById({
        clientId: clientId as string,
        requestBody: {
          name: values.name,
          settings: {
            logoBase64: values.logo,
            primaryColour: values.primaryColour,
            secondaryColour: values.secondaryColour,
          },
        },
      });
      applicationStore.publishSuccessNotification({
        text: 'Successfully updated client.',
        autoCloseMs: 3000,
      });
    } else {
      // Check if an active organisation is selected.
      if (!applicationStore.activeOrganisation) return;

      // Step 1: Create a new client.
      const newClient = await client.createClient({
        requestBody: {
          name: values.name,
          organisation: applicationStore.activeOrganisation.id,
        },
      });

      // Step 2: Fetch the newly created client to confirm ID exists
      const verifiedClient = await client.getClientById({ clientId: newClient.id });

      // Check if the client was successfully created
      if (!verifiedClient) {
        throw new Error("Failed to verify newly created client.");
      }

      // Step 3: Update the new client with settings.
      await client.updateClientById({
        clientId: verifiedClient.id,
        requestBody: {
          settings: {
            logoBase64: values.logo,
            primaryColour: values.primaryColour,
            secondaryColour: values.secondaryColour,
          },
        },
      });

      applicationStore.publishSuccessNotification({
        text: 'Client created successfully.',
        autoCloseMs: 3000,
      });
    }

    // Redirect to the clients page.
    router.push({ name: PageNames.Clients });
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error);

    // Set the error fields if available.
    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error ${isEditMode ? 'updating' : 'creating'} client: ${errorMessage}`,
    });
  } finally {
    isLoading.value = false;
  }
});


async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => resolve((reader.result as string) || '');
    reader.onerror = (error): void => reject(error);
  });
}

async function handleFileChange(event: Event): Promise<void> {
  const inputElement = event.target as HTMLInputElement;
  if (!inputElement.files) return;

  const file = inputElement.files[0];
  if (file) {
    logoValue.value = await fileToBase64(file);
  }
}

/** Reset the Logo to the default */
// /logos/gabrielcam-logo.png
const resetLogo = (): void => {
  logoValue.value = "/gabrielcam-logo.png";

  // Clear the file input field
  if (fileInputRef.value) {
    fileInputRef.value.value = "";
  }
};

const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /admin/clients
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Clients as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <Loading v-if="isLoading" />
  <form v-else @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          {{ isEditMode ? 'Edit Client' : 'Create Client' }}
        </Heading>
        <p>
          {{ isEditMode ? 'Update a client and customise their experience.' : 'Create a new client with a unique name and theme.' }}
        </p>
      </div>

      <div class="fields">
        <div class="field">
          <label for="client-name">Client Name</label>
          <input id="client-name" 
                 v-model="nameValue" 
                 type="text">
          <p class="message message-error">
            {{ nameError }}
          </p>
        </div>

        <div class="row-half">
          <div class="field">
            <span class="label">Client Logo</span>
            <div class="file-input-wrapper">
              <input id="client-logo"
                     ref="fileInputRef"
                     aria-label="client-logo"
                     type="file"
                     @change="handleFileChange">

              <label for="client-logo" class="file-upload-button">
                Choose File
              </label>

              <span class="file-name">{{ logoValue?.slice(0, 20) || 'No Logo Selected' }}</span>

              <ButtonComponent :variant="ButtonVariant.Dark"
                               :is-outline-btn="true"
                               :is-block-btn="true"
                               :disabled="logoValue === '/gabrielcam-logo.png'"
                               :type="ButtonType.Button"
                               aria-label="Reset Logo"
                               class="ml-auto"
                               :icon-name="IconName.XMarkIcon"
                               :is-icon-btn="true"
                               @click="resetLogo" />
            </div>
          </div>
          <div class="field" />
        </div>

        <div class="row-half">
          <div class="field">
            <span class="label">Client Primary Colour</span>
            <div class="color-picker-wrapper">
              <ColorPicker id="client-primaryColour"
                           v-model:pure-color="primaryColourValue"
                           name="client-primaryColour"
                           :is-widget="true"
                           :disable-alpha="true"
                           :debounce="500"
                           use-type="pure"
                           format="hsl"
                           :z-index="1" />
            </div>

            <div class="client-theme client-theme--primary" :style="{ backgroundColor: primaryColourValue, color: themeStore.getTextColor(primaryColourValue) }">
              <Transition name="fade">
                <div v-if="logoValue" class="client-theme__logo">
                  <img :src="logoValue"
                       alt="Current logo"
                       aria-hidden="true">
                </div>
              </Transition>
              <Heading level="3" :has-bottom-margin="true">
                Primary Theme Example
              </Heading>
              <p>Paragraph Example</p>
            </div>
          </div>

          <div class="field">
            <span class="label">Client Secondary Colour</span>
            <div class="color-picker-wrapper">
              <ColorPicker id="client-secondaryColour"
                           v-model:pure-color="secondaryColourValue"
                           name="client-secondaryColour"
                           :is-widget="true"
                           :disable-alpha="true"
                           :debounce="500"
                           use-type="pure"
                           format="hsl"
                           :z-index="1" />
            </div>

            <div class="client-theme client-theme--secondary" :style="{ backgroundColor: secondaryColourValue, color: themeStore.getTextColor(secondaryColourValue) }">
              <Heading level="3" :has-bottom-margin="true">
                Secondary Theme Example
              </Heading>
              <p>Paragraph Example</p>
            </div>
          </div>
        </div>

        <Transition name="fade">
          <AlertBanner v-if="showSaveAlert"
                       :has-bottom-margin="true"
                       :icon-name="IconName.ExclamationTriangleIcon"
                       :icon-style="IconStyle.Outline"
                       :variant="AlertVariant.Danger">
            <template #mainContent>
              You have unsaved theme changes! Click <strong>Save</strong> the confirm the changes.
            </template>
          </AlertBanner>
        </Transition>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :loading="isLoading">
        {{ isEditMode ? 'Save Changes' : 'Create Client' }}
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>


<style lang="scss" scoped>
@use '@scss/variables' as *;

// Color Picker Overrides
:deep(.vc-colorpicker) {
   width: 100%;
}
:deep(.vc-colorpicker--container) {
  margin-bottom: $margin-bottom;
}

// File Input Layout
.file-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.client-theme {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: $border-radius;
  padding: $gap-mobile;

  &__logo {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius;
    margin-block: clamp($gap-mobile, 3vw, $gap-desktop);

    & img {
      height: 50px;
      object-fit: contain;

      @media screen and (min-width: $breakpoint-lg) {
        width: 200px;
        height: 60px;
        object-fit: contain;
      }
    }
  }
}

// Transition Fade
.fade-enter-active {
  animation: fadeIn 0.3s ease-out forwards;
}

.fade-leave-active {
  animation: fadeOut 0.3s ease-out forwards;
}
</style>
