<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useVideoStore } from '@stores/video';
import Pagination from '@components/layout/Pagination.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ButtonVariant } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

const videoStore = useVideoStore();
const { videos, paginationOptions } = storeToRefs(videoStore);

const emit = defineEmits<{
  (e: 'onLoading'): void;
  (e: 'onLoaded'): void;
}>();

const props = defineProps({
  viewId: {
    type: String,
    required: false,
    default: '',
  },
});

// Computed Properties for Pagination State
const isFirstPage = computed(() => paginationOptions.value.pageNumber === 1);
const isLastPage = computed(() => videos.value.offset + paginationOptions.value.pageSize >= videos.value.total_count);
const hasPreviousPage = computed(() => paginationOptions.value.pageNumber > 1);
const hasNextPage = computed(() => videos.value.offset + paginationOptions.value.pageSize < videos.value.total_count);

// Async Function for Changing Pages
async function goToPage(pageNumber: number): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber = pageNumber;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

// Async Function for Changing Page Size
async function pageSizeChanged(): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber = 1;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

// Define the options for the per-page dropdown
const perPageOptions = [
  { value: 6, text: '6' },
  { value: 12, text: '12' }, // Default value
  { value: 24, text: '24' },
  { value: 48, text: '48' },
];

</script>

<template>
  <Pagination v-if="videos.total_count > 1">
    <!-- Per Page Dropdown -->
    <template #pagination-dropdown>
      <div class="d-flex align-center gap-10">
        <span>Results per page:</span>
        <v-select v-model="paginationOptions.pageSize"
                  input-id="per-page"
                  aria-label="Select number per page"
                  label="text"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  :reduce="(value: any) => value.value"
                  @option:selected="pageSizeChanged" />
      </div>
    </template>

    <!-- Pagination Info -->
    <template #pagination-info>
      <p>
        Showing
        {{ videos.offset + 1 }} -
        {{ Math.min(videos.total_count, videos.offset + paginationOptions.pageSize) }}
        of {{ videos.total_count }}
      </p>
    </template>

    <!-- Pagination Buttons -->
    <template v-if="videos.total_count > 0 && (!isFirstPage || !isLastPage)" #pagination-buttons>
      <!-- First Page Button -->
      <ButtonComponent :disabled="isFirstPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronDoubleLeftIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="goToPage(1)" />

      <!-- Previous Page Button -->
      <ButtonComponent :disabled="!hasPreviousPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronLeftIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="goToPage(paginationOptions.pageNumber - 1)" />

      <!-- Next Page Button -->
      <ButtonComponent :disabled="!hasNextPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.ChevronRightIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="goToPage(paginationOptions.pageNumber + 1)" />

      <!-- Last Page Button -->
      <ButtonComponent :disabled="isLastPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.ChevronDoubleRightIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="goToPage(Math.ceil(videos.total_count / paginationOptions.pageSize))" />
    </template>
  </Pagination>
</template>
