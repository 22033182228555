<script lang="ts" setup>
import { ref } from 'vue';
import { useClipboard } from '@vueuse/core';
import { ButtonSize, ButtonVariant, CardVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';

// Define props
interface CameraDetail {
  label: string;
  value?: string;
  copyable?: boolean | true;
  format?: (value: string) => string;
  link?: string;
  isLoading?: boolean;
}

const { cameraDetails } = defineProps<{
  cameraDetails: CameraDetail[];
  isLoading: boolean;
}>();

const { copy, isSupported } = useClipboard();
const copiedStates = ref(new Map());

const copyToClipboard = (key: string, value: string): void => {
  if (isSupported) {
    copy(value).then(() => {
      copiedStates.value.set(key, true);
      setTimeout(() => {
        copiedStates.value.set(key, false);
      }, 2000);
    });
  }
};
</script>

<template>
  <ContainerCard v-if="cameraDetails" :variant="CardVariant.Dark" :has-border="true">
    <Heading :has-bottom-margin="true" level="3">
      Camera Admin Panel
    </Heading>

    <!-- Show Loading State -->
    <Loading v-if="isLoading" style="min-height: 400px;" />

    <div v-else class="details-grid-container">
      <div class="details-grid">
        <!-- Render the details inside the grid -->
        <div v-for="details in cameraDetails" :key="details.label" class="details-grid__item">
          <div class="details-grid__details-container">
            <span class="details-grid__label">{{ details.label }}:</span>
            <span class="details-grid__value">{{ details.format ? details.format(details.value ?? 'Not set') : details.value ?? 'Not set' }}</span>
          </div>

          <a v-if="details.link"
             :href="details.link"
             target="_blank">
            <ButtonComponent :is-icon-btn="true"
                             :is-outline-btn="true"
                             :icon-style="IconStyle.Outline"
                             :icon-name="IconName.ArrowTopRightOnSquareIcon"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Light" />
          </a>

          <ButtonComponent v-if="details.copyable && details.value"
                           :is-icon-btn="true"
                           :is-outline-btn="!copiedStates.get(details.label)"
                           :icon-style="IconStyle.Outline"
                           :icon-name="copiedStates.get(details.label) ? IconName.CheckIcon : IconName.ClipboardIcon"
                           :size="ButtonSize.Small"
                           :variant="copiedStates.get(details.label) ? ButtonVariant.Success : ButtonVariant.Light"
                           @click="details.value ? copyToClipboard(details.label, details.value) : null" />
        </div>
      </div>
    </div>
  </ContainerCard>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.details-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: clamp($gap-mobile, 3vw, $gap-desktop);
  font-size: var(--tls-font-size-xs);

  &-container {
    container-type: inline-size;
    container-name: details;
  }

  // Using a @container to limit the width because of the sidenav
  @container details (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px;
    border-radius: $border-radius;
    box-shadow: 0 0 0 1px var(--tls-gray-500);

    // Hide the top border on the second item when we have two columns
    &__item {
      &:nth-child(2) {
        border-top: 0;
      }
    }
  }

  &__item {
    display: flex;
    gap: 5px 20px;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid var(--tls-gray-600);

    // Hide the top border on the first item when we have one column
    &:nth-child(1) {
      border-top: 0;
    }
  }

  &__details-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  &__label {
    font-weight: bold;
    white-space: nowrap;
  }

  &__value {
    word-break: break-all;
    overflow-wrap: break-word;
  }
}
</style>
