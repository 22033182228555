<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { ThemeNames, useThemeStore } from '@stores/useThemeStore';
import Footer from '@components/layout/Footer.vue';
import Navbar from '@components/layout/Navbar.vue';
import MainContentLayout from '@layouts/MainContentLayout.vue';
import SidebarLayout from '@layouts/SidebarLayout.vue';
import PublicViewerHeader from '@components/layout/PublicViewerHeader.vue';
import PublicViewerLayout from '@layouts/PublicViewerLayout.vue';
import FullscreenLayout from '@layouts/FullscreenLayout.vue';
import Loading from '@components/Loading.vue';

// Stores
const route = useRoute();
const themeStore = useThemeStore();
const { currentTheme } = storeToRefs(themeStore);

// Page
const viewId = route.params['id'] as string;
const pageName = computed(() => route.name as string);

// Determines if the current route should display the client theme defined on the meta
const isClientTheme = computed(() => route.meta["theme"] === ThemeNames.CLIENT);

// If the metadata specifics a client theme, fetch the client theme and set isThemeReady to true
onMounted(async () => {
  if (isClientTheme.value && viewId) {
    await themeStore.fetchClientTheme(viewId);
    themeStore.isThemeReady = true;
  }
});
</script>

<template>
  <Loading v-if="!themeStore.isThemeReady" />

  <div v-else
       id="appContainer"
       :data-theme="currentTheme"
       class="app-container">
    <template v-if="isClientTheme">
      <PublicViewerHeader />
      <PublicViewerLayout>
        <template #router-view>
          <router-view />
        </template>
      </PublicViewerLayout>
    </template>

    <template v-else>
      <template v-if="route?.meta['layout'] === 'fullscreen'">
        <FullscreenLayout :meta-title="route?.meta['title'] as string">
          <template #router-view>
            <router-view />
          </template>
          <template #footer>
            <Footer />
          </template>
        </FullscreenLayout>
      </template>

      <template v-else>
        <SidebarLayout :page-name="pageName">
          <template #navbar>
            <Navbar />
          </template>
        </SidebarLayout>
        <MainContentLayout :page-name="pageName">
          <template #router-view>
            <router-view />
          </template>
          <template #footer>
            <Footer />
          </template>
        </MainContentLayout>
      </template>
    </template>
  </div>
</template>


<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
}
</style>
