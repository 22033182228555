<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { ButtonVariant } from '@viewModels/enums';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import ButtonComponent from '@components/ButtonComponent.vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ViewVisibility } from '@gabrielcam/api-client';
import { FunnelIcon } from '@heroicons/vue/24/outline';

export interface ViewDirectoryFilterQueryParameters {
  client?: string,
  project?: string,
  status?: client.ViewStatus,
  visibility?: client.ViewVisibility,
}

const emit = defineEmits<{
  (e: 'filterSelected', { client, project, status, visibility }: ViewDirectoryFilterQueryParameters): void,
}>();

defineExpose({
    hideDropdown: () => { showDropdown.value = false },
})

const applicationStore = useApplicationStore();
const clients = ref<client.Client[]>([]);
const projects = ref<client.Project[]>([]);
const showDropdown = ref<boolean>(false);
const selectedClient = ref<string>();
const selectedProject = ref<string>();
const selectedVisibility = ref<client.ViewVisibility>();
const selectedStatus = ref<client.ViewStatus>();
const statusOptions = [
  { value: client.ViewStatus.ACTIVE, name: 'Active' },
  { value: client.ViewStatus.ARCHIVE, name: 'Archive' },
  { value: client.ViewStatus.INACTIVE, name: 'Inactive' },
]
const visibilityOptions = [
  {value: ViewVisibility.PUBLIC, name: 'Public'},
  {value: ViewVisibility.PRIVATE, name: 'Private'},
]

const toggleDropdown = (): void => {
  showDropdown.value = !showDropdown.value;
}

function updateFilter(): void {
  emit('filterSelected', {
    client: selectedClient.value,
    project: selectedProject.value,
    status: selectedStatus.value,
    visibility: selectedVisibility.value
  });
}

onMounted(async () => {
  const clientResponse = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
  clients.value = clientResponse.data;

  const projectResponse = await client.listProjects({ organisation: applicationStore.activeOrganisation!.id });
  projects.value = projectResponse.data;
})
</script>

<template>
  <div class="view-filter-dropdown">
    <ButtonComponent :icon-style="IconStyle.Outline"
                     :icon-position="IconPosition.Left"
                     :icon-name="IconName.ChevronDownIcon"
                     :variant="ButtonVariant.Dark"
                     class="view-filter-dropdown--btn"
                     :class="{ 'view-filter-dropdown--open': showDropdown }"
                     @click="toggleDropdown">
      <span class="view-filter-dropdown--text">Filter</span>
      <FunnelIcon class="view-filter-dropdown--icon" />
    </ButtonComponent>

    <div class="view-filter-dropdown--content">
      <span>Client</span>
      <v-select v-model="selectedClient"
                input-id="client"
                aria-label="Select a Client"
                placeholder="All clients"
                label="name"
                :reduce="(value: client.Client) => value.id"
                :options="clients"
                :clearable="true"
                @update:model-value="updateFilter" />

      <span>Project</span>
      <v-select v-model="selectedProject"
                input-id="project"
                aria-label="Select a Project"
                placeholder="All projects"
                label="name"
                :reduce="(value: client.Project) => value.id"
                :options="projects"
                :clearable="true"
                @update:model-value="updateFilter" />

      <span>Status</span>
      <v-select v-model="selectedStatus"
                input-id="status"
                aria-label="Select a Status"
                placeholder="All statuses"
                label="name"
                :reduce="(value: any) => value.value"
                :options="statusOptions"
                :clearable="true"
                @update:model-value="updateFilter" />

      <span>Visibility</span>
      <v-select v-model="selectedVisibility"
                input-id="visibility"
                aria-label="Select a Visibility"
                placeholder="All visibility"
                label="name"
                :reduce="(value: any) => value.value"
                :options="visibilityOptions"
                :clearable="true"
                @update:model-value="updateFilter" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.view-filter-dropdown {
  @media screen and (width >= $breakpoint-lg) {
    position: relative;
  }

  &--btn {
    @media screen and (width < $breakpoint-lg) {
      padding: 10px 6px !important;
    }

    /* Use :deep() for the .btn__icon inside the scoped styles */
    :deep(.btn__icon) {
      transition: transform 0.3s ease-in-out;
    }

    &.view-filter-dropdown--open :deep(.btn__icon) {
      transform: rotate(180deg);
    }
  }

  @media screen and (width < $breakpoint-lg) {
    &--text {
      display: none;
    }
  }

  &--icon {
    width: 1.5rem;
    height: 1.5rem;

    @media screen and (width > $breakpoint-lg) {
      display: none;
    }
  }

  &--content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: $gap-default;
    align-items: center;
    position: absolute;
    left: 0;
    z-index: 2;
    width: calc(100% - 30px);
    padding: $gap-default;
    margin: 10px 0 0 15px;
    visibility: hidden;
    background: var(--tls-gray-100);
    border: 1px solid var(--tls-gray-400);
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    opacity: 0;
    transition: opacity 0.15s ease, transform 0.15s ease, visibility 0.15s;
    transform: translateY(-10px);

    @media screen and (width >= $breakpoint-lg) {
      left: -15px;
      min-width: 400px;
      padding: 30px;
    }
  }

  &--open {
    + .view-filter-dropdown--content {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
