<script setup lang="ts">
import { ref } from 'vue';
import { ButtonVariant } from '@viewModels/enums';
import * as client from '@gabrielcam/api-client';
import ButtonComponent from '@components/ButtonComponent.vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ArrowsUpDownIcon } from '@heroicons/vue/24/solid';

export interface ViewDirectorySortQueryParameters {
  orderBy?: client.ViewSortOption,
  sort?: client.SortOrder,
}

const emit = defineEmits<{
  (e: 'sortSelected', { orderBy, sort }: ViewDirectorySortQueryParameters): void
}>();

defineExpose({
  hideDropdown: () => { showDropdown.value = false },
})

const showDropdown = ref<boolean>(false);
const selectedSortField = ref<client.ViewSortOption>(client.ViewSortOption.LAST_CAPTURED_UTC);
const selectedSortOrder = ref<client.SortOrder>(client.SortOrder.DESC);
const sortFieldOptions = [
  { value: client.ViewSortOption.NAME, name: 'Name' },
  { value: client.ViewSortOption.LAST_CAPTURED_UTC, name: 'Latest Image' },
];
const sortOrderOptions = [
  { value: client.SortOrder.ASC, name: 'A-Z or Low to High' },
  { value: client.SortOrder.DESC, name: 'Z-A or High to Low' },
];

const toggleDropdown = (): void => {
  showDropdown.value = !showDropdown.value;
}

const updateSort = (): void => {
  emit('sortSelected', { orderBy: selectedSortField.value, sort: selectedSortOrder.value });
}
</script>

<template>
  <div class="view-sort-dropdown">
    <ButtonComponent :icon-style="IconStyle.Outline"
                     :icon-position="IconPosition.Left"
                     :icon-name="IconName.ChevronDownIcon"
                     :variant="ButtonVariant.Dark"
                     class="view-sort-dropdown--btn"
                     :class="{ 'view-sort-dropdown--open': showDropdown }"
                     @click="toggleDropdown">
      <span class="view-sort-dropdown--text">Sort</span>
      <ArrowsUpDownIcon class="view-sort-dropdown--icon" />
    </ButtonComponent>

    <div class="view-sort-dropdown--content">
      <span>Sort on</span>
      <v-select v-model="selectedSortField"
                input-id="order-on"
                name="Order On"
                aria-label="Order On"
                label="name"
                :reduce="(value: any) => value.value"
                :options="sortFieldOptions"
                :clearable="false"
                @update:model-value="updateSort" />

      <span>Sort by</span>
      <v-select v-model="selectedSortOrder"
                input-id="order-by"
                name="Order By"
                aria-label="Order By"
                label="name"
                :reduce="(value: any) => value.value"
                :options="sortOrderOptions"
                :clearable="false"
                @update:model-value="updateSort" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.view-sort-dropdown {
  @media screen and (width >= $breakpoint-lg) {
    position: relative;
  }

  &--btn {
    @media screen and (width < $breakpoint-lg) {
      padding: 10px 6px !important;
    }

    /* Use :deep() to target the .btn__icon inside the scoped styles */
    :deep(.btn__icon) {
      transition: transform 0.3s ease-in-out;
    }

    &.view-sort-dropdown--open :deep(.btn__icon) {
      transform: rotate(180deg);
    }
  }

  @media screen and (width < $breakpoint-lg) {
    &--text {
      display: none;
    }
  }

  &--icon {
    width: 1.5rem;
    height: 1.5rem;

    @media screen and (width > $breakpoint-lg) {
      display: none;
    }
  }

  &--content {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: $gap-default;
    align-items: center;
    position: absolute;
    left: 0;
    z-index: 2;
    width: calc(100% - 30px);
    padding: $gap-default;
    margin: 10px 0 0 15px;
    visibility: hidden;
    background: var(--tls-gray-100);
    border: 1px solid var(--tls-gray-400);
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    opacity: 0;
    transition: opacity 0.15s ease, transform 0.15s ease, visibility 0.15s;
    transform: translateY(-10px);

    @media screen and (width >= $breakpoint-lg) {
      left: -15px;
      min-width: 400px;
      padding: 30px;
    }
  }

  &--open {
    + .view-sort-dropdown--content {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>

