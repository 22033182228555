
import * as Ably from 'ably';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { GetAblyTokenResponse } from '@gabrielcam/api-client';

type EventTypes = 'video.complete' | 'batch-download.complete';

export async function initAblySubscribeUser(userId: string): Promise<void> {
    const applicationStore = useApplicationStore();
    const ablyToken = await client.getAblyToken();
    const ably = new Ably.Realtime({
        token: ablyToken,
        authCallback: async (): Promise<GetAblyTokenResponse> => {
            return client.getAblyToken();
        },
    });
    ably.connection.on('connected', () => console.log('✅ Ably connected'));
    ably.connection.on('failed', () => console.log('❌ Ably connection error'));
    const channel = ably.channels.get(`user:${userId}`);
    await channel.subscribe(function (message) {
        const messages = {
            ['video.complete']: 'Your video is ready',
            ['batch-download.complete']: 'Your download is ready',
        };
        const text = messages[message.data.type as EventTypes];
        if (text) applicationStore.publishSuccessNotification({
            text,
            autoCloseMs: 5000
        });
    });
}
