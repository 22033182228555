<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import {
  BellIcon,
  BuildingOfficeIcon,
  CameraIcon,
  ChartBarIcon,
  EyeIcon,
  EyeSlashIcon,
  ServerStackIcon,
} from '@heroicons/vue/24/outline';
import prettyBytes from 'pretty-bytes';
import { BadgeVariant, ButtonSize, ButtonVariant, SkeletonVariant } from '@viewModels/enums';
import BadgeComponent from '@components/BadgeComponent.vue';
import ViewStorageUsageChart from '@components/view/ViewStorageUsageChart.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SkeletonLoading from '@components/SkeletonLoading.vue';
import ViewRecentLogActivity from '@components/view/ViewRecentLogActivity.vue';
import ButtonComponent from '@components/ButtonComponent.vue';

const route = useRoute();
const viewId = route.params['id'] as string;
const isLoadingView = ref<boolean>(true);
const isLoadingViewStatsLatest = ref<boolean>(true);
const view = ref<client.View>();
const camera = ref<client.Camera>();
const latestStat = ref<client.ViewStat>();
const statusVariantMap = {
  [client.ViewStatus.ACTIVE]: { variant: BadgeVariant.Success, label: client.ViewStatus.ACTIVE },
  [client.ViewStatus.INACTIVE]: { variant: BadgeVariant.Danger, label: client.ViewStatus.INACTIVE },
  [client.ViewStatus.ARCHIVE]: { variant: BadgeVariant.Warning, label: client.ViewStatus.ARCHIVE },
};

const fetchViewData = async (): Promise<void> => {
  isLoadingView.value = true;
  view.value = await client.getViewById({
    viewId: viewId,
    expand: [
      client.Resources_TransferSettings.TRANSFER_SETTINGS,
    ],
    includes: [
      client.Resources_Client.CLIENT,
      client.Resources_Project.PROJECT,
    ],
  });

  if (view.value.camera) {
    camera.value = await client.getCameraById({ cameraId: view.value.camera });
  }
  isLoadingView.value = false;
};

const fetchViewStatsData = async (): Promise<void> => {
  isLoadingViewStatsLatest.value = true;
  try {
    latestStat.value = await client.getViewByIdStatLatest({ viewId: viewId });
  } catch {
  }
  isLoadingViewStatsLatest.value = false;
};

/**
 * Computed values
 */
const isAwsStorage = computed(() => view?.value?.source?.provider === 'AWS');

const totalImages = computed(() => {
  // Get the value from the stats if available
  // AWS sources won't have stats, so use the count from the view as a fallback
  const number = latestStat?.value?.storageImageCount ?? view?.value?.imageCount;

  if (number !== undefined) {
    return number.toLocaleString();
  }

  return 'No images found';
});

const usedStorageBytes = computed(() => {
  if (isAwsStorage.value) return 'No Storage Used';
  if (latestStat?.value?.storageImageSizeBytes) return prettyBytes(latestStat.value.storageImageSizeBytes);
  return 'No Storage Used';
});

const clientProject = computed(() => {
  let returnValue = 'No Client / Project found';
  if (view?.value?.includes?.client?.name) {
    returnValue = view.value.includes.client?.name;

    if (view?.value?.includes?.project?.name) {
      returnValue = `${returnValue} / ${view?.value?.includes?.project?.name}`;
    }
  }
  return returnValue;
});

const cameraName = computed(() => {
  if (isAwsStorage.value) return 'Generic InSite';
  if (camera?.value?.serialNumber) return camera.value.serialNumber;
  return 'No camera found';
});

/**
 * Lifecycle
 */
onMounted(async () => {
  await fetchViewData();
  await fetchViewStatsData();
});
</script>

<template>
  <div class="overview overview__small-tile">
    <SkeletonLoading v-if="isLoadingView || isLoadingViewStatsLatest" :variant="SkeletonVariant.InfoTile" :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <ChartBarIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Total images
          </Heading>
          <div class="info-tile__count">
            {{ totalImages }}
          </div>
        </div>
      </div>
    </ContainerCard>

    <SkeletonLoading v-if="isLoadingView || isLoadingViewStatsLatest" :variant="SkeletonVariant.InfoTile" :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <ServerStackIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Used storage
          </Heading>
          <div class="info-tile__count">
            {{ usedStorageBytes }}
          </div>
        </div>
      </div>
    </Containercard>

    <SkeletonLoading v-if="isLoadingView || isLoadingViewStatsLatest" :variant="SkeletonVariant.InfoTile" :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <CameraIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Camera
          </Heading>
          <div v-if="camera?.serialNumber" class="info-tile__button">
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :size="ButtonSize.Small"
                             :to="`/admin/cameras/${camera?.id}`">
              {{ cameraName }}
            </ButtonComponent>
          </div>
          <div v-else class="info-tile__count">
            {{ cameraName }}
          </div>
        </div>
      </div>
    </Containercard>

    <SkeletonLoading v-if="isLoadingView || isLoadingViewStatsLatest" :variant="SkeletonVariant.InfoTile" :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <BellIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            View status
          </Heading>
          <div>
            <BadgeComponent v-if="statusVariantMap[view!.status as client.ViewStatus]"
                            :is-pill="true"
                            :variant="statusVariantMap[view!.status as client.ViewStatus].variant">
              {{ statusVariantMap[view!.status as client.ViewStatus].label }}
            </BadgeComponent>
          </div>
        </div>
      </div>
    </Containercard>

    <SkeletonLoading v-if="isLoadingView || isLoadingViewStatsLatest" :variant="SkeletonVariant.InfoTile" :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <EyeIcon v-if="view?.isPublic" class="info-tile__icon" />
        <EyeSlashIcon v-else class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Publicly Viewable
          </Heading>
          <div class="info-tile__count">
            {{ view?.isPublic ? 'Yes' : 'No' }}
          </div>
        </div>
      </div>
    </ContainerCard>

    <SkeletonLoading v-if="isLoadingView || isLoadingViewStatsLatest" :variant="SkeletonVariant.InfoTile" :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <BuildingOfficeIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Client / Project
          </Heading>
          <div class="info-tile__count">
            {{ clientProject }}
          </div>
        </div>
      </div>
    </Containercard>
  </div>

  <div class="overview overview__large-tile">
    <ContainerCard :margin-bottom="false">
      <Heading level="4" :has-bottom-margin="true">
        Storage history
      </Heading>
      <ViewStorageUsageChart :view-id="viewId" style="height: 400px;" />
    </Containercard>

    <ContainerCard :margin-bottom="false">
      <Heading level="4" :has-bottom-margin="true">
        Recent logs
      </Heading>
      <ViewRecentLogActivity :view-id="viewId" />
    </Containercard>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

$container-width: 240px;

.overview {
  display: grid;
  gap: $gap-desktop;
  margin-bottom: $margin-bottom;

  &__small-tile {
    grid-template-columns: repeat(auto-fit, minmax($container-width, 1fr));
  }

  &__large-tile {
    grid-template-columns: repeat(auto-fit, minmax(clamp($container-width, 50vw, $container-width * 2), 1fr));
  }
}

.info-tile {
  &__content {
    display: flex;
    gap: $gap-default;
  }

  &__icon {
    width: 1.5rem;
    min-width: 1.5rem;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: $gap-mobile;

    @media (min-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__count {
    font-size: var(--tls-font-size-xs);
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    align-items: center;
  }
}
</style>
