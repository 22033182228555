<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useApplicationStore } from '@stores/application';
import { dateTimeFormat } from '@utils/date';
import dayjs from 'dayjs';
import { Video } from '@stores/video';
import * as client from '@gabrielcam/api-client';
import { BadgeVariant, ButtonVariant } from '@viewModels/enums';
import BadgeComponent from '@components/BadgeComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { Entitlements } from '@gabrielcam/api-client';
import { PlayIcon } from '@heroicons/vue/24/solid';

// Video Card Interface
const props = defineProps<{
  resource: Video | null; // Accept null initially to indicate loading state
  selected?: boolean;
}>();

const emit = defineEmits<{
  (e: 'playVideo'): void;
  (e: 'deleteVideo'): void;
}>();

// Loading States
const isLoading = ref(true);

// Stores
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

// Permissions
const showDelete = applicationStore.canUser(Entitlements.DELETE_SEQUENCE, applicationStore.activeOrganisation!);

// View References
const viewId = ref(props.resource?.view);
const viewName = ref(props.resource?.name);
const viewNameShort = ref(props.resource?.viewName);
const viewClientName = ref('No client');
const viewProjectName = ref('No project');

// Video References
const videoStatus = ref<client.SequenceStatus>(props.resource?.status || client.SequenceStatus.INITIATED);
const videoLength = ref<number>(props.resource?.length ?? 0);
const videoImageThumb = ref(props.resource?.thumbnail ?? '');
const videoStartDate = ref<string | undefined>(props.resource?.options?.startDate);
const videoEndDate = ref<string | undefined>(props.resource?.options?.endDate);
const videoStartTime = ref<string | undefined>(props.resource?.options?.startTime);
const videoEndTime = ref<string | undefined>(props.resource?.options?.endTime);
const videoFrameRate = ref<number | undefined>(props.resource?.options?.framerate);
const videoWidthResolution = ref<number | undefined>(props.resource?.options?.width);
const videoHeightResolution = ref<number | undefined>(props.resource?.options?.height);
// Computed Properties
const videoResolution = computed(() => {
  if (videoWidthResolution.value && videoHeightResolution.value) {
    return `${videoWidthResolution.value}x${videoHeightResolution.value}`;
  }
  return '';
});

const videoCreatedAt = computed(() =>
  props.resource?.createdAt
    ? dateTimeFormat(activeUser?.timezone).format(new Date(props.resource?.createdAt))
    : 'Unknown',
);

/**
 * Mapping of sequence statuses to badge variants.
 * This object is used to determine the color of the status badge based on the sequence status.
 */
const statusColorMap: Record<client.SequenceStatus, BadgeVariant> = {
  [client.SequenceStatus.COMPLETE]: BadgeVariant.Success,
  [client.SequenceStatus.INITIATED]: BadgeVariant.Warning,
  [client.SequenceStatus.PROCESSED]: BadgeVariant.Warning,
  [client.SequenceStatus.PROCESSING]: BadgeVariant.Warning,
  [client.SequenceStatus.UPLOADING]: BadgeVariant.Warning,
};

/**
 * Computed property that returns the status color of the sequence.
 * If the resource is not available, it returns a danger badge variant.
 *
 * @returns {BadgeVariant} The status color of the sequence.
 */
const statusColour = computed((): BadgeVariant => {
  if (!props.resource) {
    return BadgeVariant.Danger; // Fallback if resource is not available
  }
  return statusColorMap[props.resource.status as client.SequenceStatus] || BadgeVariant.Danger;
});

/**
 * Downloads a file from the provided URL.
 *
 * This function creates a temporary anchor element, sets its href attribute to the provided URL,
 * and simulates a click event to initiate the download. If the URL is not found, it publishes an error notification.
 *
 * @returns {void} This function does not return anything.
 */
const downloadFile = (): void => {
  const url = props.resource?.downloadURL;
  if (url) {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    applicationStore.publishSuccessNotification({
      text: 'Video downloading, please check your browsers downloads folder',
      autoCloseMs: 5000,
    });
  } else {
    applicationStore.publishErrorNotification({
      text: 'Video URL not found',
      autoCloseMs: 3000,
    });
    console.error('Video URL not found');
  }
};

/**
 * Fetches view details, including client and project information, for the given view ID.
 *
 * @async
 * @function fetchViewDetails
 * @returns {Promise<void>}
 */
async function fetchViewDetails(): Promise<void> {
  // Return early if the view ID is not available
  if (!viewId.value) {
    console.warn('View ID is not available, aborting fetch.');
    return;
  }

  try {
    // Fetch view details, including client and project information
    const viewDetails = await client.getViewById({
      viewId: viewId.value,
      includes: [client.Resources_Client.CLIENT, client.Resources_Project.PROJECT],
    });

    // Check if viewDetails or includes is missing, return early
    if (!viewDetails || !viewDetails.includes) {
      console.warn(`No client or project data found for view ${viewId.value}.`);
      return;
    }

    // Update client and project names based on the fetched details
    viewClientName.value = viewDetails.includes.client?.name || 'No client';
    viewProjectName.value = viewDetails.includes.project?.name || 'No project';

  } catch (error) {
    console.error(`Error fetching client/project data for view ${viewId.value}:`, error);
  }
}

/**
 * Formats the video length in seconds to a human-readable string.
 *
 * @param {number} seconds - The video length in seconds.
 * @returns {string} A string representing the video length in hours, minutes, and seconds.
 */
function formatVideoLength(seconds: number): string {
  if (seconds === 0) return "0 seconds";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const hourPart = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
  const minutePart = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';
  const secondPart = remainingSeconds > 0 ? `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}` : '';

  const timeArray = [hourPart, minutePart, secondPart].filter(Boolean); // Filter out empty strings

  return timeArray.join(', ');
}

// Fetch the view details when the component is mounted
onMounted(async () => {
  isLoading.value = true;
  await fetchViewDetails();
  isLoading.value = false;
});
</script>

<template>
  <!-- Show The Skeleton Card -->
  <div v-show="isLoading" class="video-card__container">
    <div class="video-card">
      <div class="video-card__status">
        <div class="skeleton-card skeleton-card__badge" />
      </div>

      <div class="video-card__header">
        <div class="skeleton-card skeleton-card__title" />
        <div class="skeleton-card skeleton-card__title" />
        <div class="skeleton-card skeleton-card__detail" />
        <div class="skeleton-card skeleton-card__detail" />
      </div>

      <div class="video-card__image">
        <div class="skeleton-card skeleton-card__image" />
      </div>

      <div class="video-card__details">
        <div class="skeleton-card skeleton-card__image-detail" />
        <div class="skeleton-card skeleton-card__image-detail" />
        <div class="skeleton-card skeleton-card__image-detail" />
        <div class="skeleton-card skeleton-card__image-detail" />
        <div class="skeleton-card skeleton-card__image-detail" />
        <div class="skeleton-card skeleton-card__image-detail" />
      </div>

      <div class="video-card__actions">
        <div class="skeleton-card skeleton-card__button" />
        <div class="skeleton-card skeleton-card__button" />
        <div class="skeleton-card skeleton-card__button" />
        <div class="skeleton-card skeleton-card__button" />
      </div>
    </div>
  </div>

  <!-- Show The Real Card When Loaded -->
  <div v-show="!isLoading"
       :class="['video-card', `video-card--${videoStatus.toLowerCase()}`]">
    <div v-if="videoStatus" class="video-card__status">
      <BadgeComponent :variant="statusColour as BadgeVariant">
        {{ videoStatus }}
      </BadgeComponent>
    </div>

    <div class="video-card__header">
      <Heading level="4" class="video-card__header--title">
        {{ viewName }}
      </Heading>
      <div class="video-card__header--details">
        <div class="text--truncate">
          <span class="text--semibold">Project:</span> {{ viewClientName }}
        </div>
        <div class="text--truncate">
          <span class="text--semibold">Client:</span> {{ viewProjectName }}
        </div>
      </div>
    </div>

    <div class="video-card__image">
      <!-- Show a fallback image/logo if viewImageUrl fails -->
      <img v-if="videoImageThumb"
           :src="videoImageThumb"
           aria-hidden="true"
           :class="`video-card__image--${videoStatus?.toLowerCase()}`"
           :alt="`${viewNameShort} view`"
           @click="emit('playVideo')">
      <PlayIcon v-show="videoStatus === client.SequenceStatus.COMPLETE" class="play-icon" />
      <template v-if="!videoImageThumb">
        <p class="video-card__image--fallback">
          Video {{ videoStatus.toLowerCase() }}, please check back later
        </p>
      </template>
    </div>

    <div class="video-card__content">
      <div class="d-flex justify-between">
        <span>Created At: </span>
        <span>{{ videoCreatedAt }}</span>
      </div>
      <div class="d-flex justify-between">
        <span>Date Range: </span>
        <span>{{ dayjs.utc(videoStartDate).format('YYYY-MM-DD') }} - {{ dayjs.utc(videoEndDate).format('YYYY-MM-DD') }}</span>
      </div>
      <div class="d-flex justify-between">
        <span>Time Range: </span>
        <span>{{ videoStartTime || 'Unknown' }} - {{ videoEndTime || 'Unknown' }}</span>
      </div>
      <div class="d-flex justify-between">
        <span>Resolution:</span>
        <span>{{ videoResolution || 'Unknown' }}</span>
      </div>
      <div class="d-flex justify-between">
        <span>Frame Rate:</span>
        <span>{{ videoFrameRate || 'Unknown' }}</span>
      </div>
      <div v-if="videoLength" class="d-flex justify-between text--truncate">
        <span>Duration:</span>
        <span>{{ formatVideoLength(videoLength) }} </span>
      </div>
    </div>

    <div class="video-card__actions">
      <ButtonComponent v-if="videoLength"
                       :variant="ButtonVariant.Primary"
                       :is-icon-btn="true"
                       :icon-name="IconName.PlayIcon"
                       :icon-style="IconStyle.Outline"
                       @click="emit('playVideo')" />
      <ButtonComponent v-if="videoLength"
                       :variant="ButtonVariant.Primary"
                       :is-icon-btn="true"
                       :icon-name="IconName.ArrowDownTrayIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Download"
                       @click="downloadFile" />
      <ButtonComponent v-if="showDelete && videoStatus === client.SequenceStatus.COMPLETE"
                       :style="!videoLength ? 'margin-left: auto' : ''"
                       :variant="ButtonVariant.Danger"
                       :is-icon-btn="true"
                       :icon-name="IconName.TrashIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Delete"
                       @click="emit('deleteVideo')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.video-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: clamp($gap-mobile, 3vw, $gap-desktop);
  background-color: var(--tls-gray-200);
  border-radius: $border-radius;
  box-shadow: inset 0 0 0 1px var(--tls-gray-300);
  transition: background-color 300ms ease, box-shadow 300ms ease;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%
  }

  &--complete {
    background-color: var(--tls-success-text);
    box-shadow: inset 0 0 0 1px var(--tls-success-color);
  }

  &--processed,
  &--processing,
  &--uploading,
  &--initiated{
    background-color: var(--tls-warning-text);
    box-shadow: inset 0 0 0 1px var(--tls-warning-color);
  }

  &__status {
    align-self: flex-end;
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-block: 5px;
    margin-bottom: $margin-bottom;

    &--title {
      display: -webkit-box;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint-lg) {
        flex-basis: 2.4em;
      }
    }

    &--details {
      display: flex;
      flex-direction: column;
      font-size: var(--tls-font-size-xs);
      color: var(--tls-gray-600);
    }
  }

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    // Use aspect ratio to stop CLS (Content Layout Shift)
    aspect-ratio: 16 / 9;
    margin-bottom: 5px;
    overflow: hidden;
    background: var(--tls-gray-200) url('/src/assets/background-light.png') repeat center center;
    background-size: 25%;
    border-radius: $border-radius;
    box-shadow: 0 0 0 1px var(--tls-gray-400);

    & img {
      max-width: 100%;
      height: auto;
      overflow: hidden;
      object-fit: cover;
      border-radius: $border-radius;
      transition: transform 200ms ease-in;
    }

    &:hover .play-icon {
      opacity: 1;
      fill: var(--tls-gray-800);
    }

    & .play-icon {
      position: absolute;
      inset: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.7;
      fill: var(--tls-gray-50);
      width: 48px;
      height: 48px;
      transition: opacity 200ms ease-in, fill 200ms ease-in;
      pointer-events: none;
    }

    &--complete {
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        opacity: 0.7;
      }
    }

    &--fallback {
      padding-inline: $gap-desktop;
      font-size: var(--tls-font-size-xs);
      color: var(--tls-gray-600);
      text-align: center;
    }
  }

  &__content {
    margin-bottom: $margin-bottom;
    font-size: var(--tls-font-size-xs);
    color: var(--tls-gray-600);
  }

  &__actions {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: space-between;
  }
}

/* Skeleton Loading Effect */
.skeleton-card {
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  border-radius: $border-radius;
  animation: pulse 3000ms ease-in-out infinite;

  &__badge {
    width: 80px;
    height: 24px;
  }

  &__title {
    width: 100%;
    height: 24px;
  }

  &__detail {
    width: 100%;
    height: 16px;
  }

  &__image {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  &__image-detail {
    width: 100%;
    height: 16px;
    margin-bottom: 5px;
  }

  &__button {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}
</style>