<script setup lang="ts">
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';
import ClientForm from '@components/client/ClientForm.vue';
import Loading from '@components/Loading.vue';

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllClients, to: BreadcrumbPaths.Clients },
  { title: BreadcrumbTitles.CreateClient, active: true },
];
</script>

<template>
  <SubHeader heading="Create Client"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <ClientForm />
      </template>
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
