<template>
  <footer>
    <p class="text--center mb-0">
      &copy; {{ new Date().getFullYear() }} GabrielCam Ltd. All rights reserved.
    </p>
  </footer>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

footer {
  margin-block-start: auto;
  padding: 25px 20px 20px;
  font-size: var(--tls-font-size-xs);
  color: var(--tls-secondary-text);
}
</style>
