<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';
import { SupportedSystems } from '@gabrielcam/api-client';

import { useCurrentRoute } from '@utils/getCurrentRoute';

import { IconName, IconPosition } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import SubNav, { SubNavItem } from '@components/SubNav.vue';
import SubHeader from '@components/SubHeader.vue';
import CameraConfiguratorLauncher from '@components/camera/CameraConfiguratorLauncher.vue';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonVariant, PageNames, SubNavItemPosition } from '@viewModels/enums';
import { useApplicationStore } from '@stores/application';
import { storeToRefs } from 'pinia';

interface NavItem {
  to?: string;
  active?: boolean;
}

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);

const { currentRouteMetaTitle } = useCurrentRoute();
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);
const openCameraConfiguratorModal = ref<boolean>(false);
const route = useRoute();
const cameraId = route.params['id'] as string;
const breadcrumbs = ref<BreadCrumbItem[]>([]);
const breadcrumbKey = ref(0);
const subNavKey = ref(0);
const subNavItems = ref<SubNavItem[]>([]);

// Set the page heading on all pages except PageNames.CameraOverview
const pageHeading = computed(() => {
  return route.name === PageNames.CameraOverview
    ? `Camera ${currentCamera.value?.serialNumber}` || ''
    : `Camera ${currentCamera.value?.serialNumber} ${currentRouteMetaTitle.value}`;
});

// Watch for route changes and update breadcrumbs, except for PageNames.CameraOverview
function updateBreadcrumbs(): void {
  if (currentCamera.value?.serialNumber) {
    breadcrumbs.value = [
      { title: BreadcrumbTitles.AllCameras, to: BreadcrumbPaths.AllCameras },
      { title: `${currentCamera.value.serialNumber}`, to: `/admin/cameras/${cameraId}` },
    ];

    // Only add the last breadcrumb if it's NOT PageNames.CameraOverview
    if (route.name !== PageNames.CameraOverview) {
      breadcrumbs.value.push({ title: `${currentRouteMetaTitle.value}`, active: true });
    }

    breadcrumbKey.value += 1; // HACK: Increment key to force re-render
  } else {
    breadcrumbs.value = [];
  }
}

function updateSubNavItems(): void {
  subNavItems.value = setActiveState<SubNavItem>(
    [
      {
        title: 'Overview',
        to: `/admin/cameras/${cameraId}`,
        viewPermission: true,
        iconName: IconName.AdjustmentsHorizontalIcon,
      },
      {
        title: 'Edit',
        to: `/admin/cameras/${cameraId}/edit`,
        viewPermission: true,
        variant: ButtonVariant.Danger,
        isOutlineBtn: false,
        iconName: IconName.Cog8ToothIcon,
        iconPosition: IconPosition.Left,
        itemPosition: SubNavItemPosition.Right,
        hidden: !adminMode.value,
      },
      {
        title: 'Configure',
        onclick: (): void => { openCameraConfiguratorModal.value = true },
        viewPermission: true,
        iconName: IconName.ArrowTopRightOnSquareIcon,
        iconPosition: IconPosition.Right,
        hidden: currentCamera.value?.systemType !== SupportedSystems.GABRIELCAMV1,
      },
      {
        title: 'Schedule',
        to: `/admin/cameras/${cameraId}/schedule`,
        viewPermission: true,
        iconName: IconName.ClockIcon,
        // Show if systemType is SOLOV1
        hidden: currentCamera.value?.systemType !== SupportedSystems.SOLOV1,
      },
      {
        title: 'Settings',
        to: `/admin/cameras/${cameraId}/settings`,
        viewPermission: true,
        iconName: IconName.WrenchScrewdriverIcon,
        hidden: true,
      },
      {
        title: 'Power',
        to: `/admin/cameras/${cameraId}/power`,
        viewPermission: true,
        iconName: IconName.BoltIcon,
        // Show if systemType is GabrielCamV1
        hidden: currentCamera.value?.systemType !== SupportedSystems.GABRIELCAMV1,
      },
      {
        title: 'Logs',
        to: `/admin/cameras/${cameraId}/logs`,
        viewPermission: true,
        iconName: IconName.BookOpenIcon,
      },
      {
        title: 'Monitor',
        to: `/admin/cameras/${cameraId}/monitor`,
        viewPermission: true,
        iconName: IconName.EyeIcon,
        hidden: currentCamera.value?.systemType !== SupportedSystems.GABRIELCAMV1,
      },
    ],
    route.path
  );
  subNavKey.value += 1; // HACK: Increment key to force re-render
}

// Set active state for nav items based on the current route
function setActiveState<T extends NavItem>(items: T[], currentPath: string): T[] {
  return items.map((item) => ({
    ...item,
    active: item.to === currentPath,
  }));
}

// Get the current camera data
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
    updateBreadcrumbs();
    updateSubNavItems();
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false;
  }
});

// Watch for route changes and update breadcrumbs and subnav accordingly
watch(
  [(): unknown => route.name, (): string => route.meta['title'] as string | ''],
  () => {
    updateBreadcrumbs();
    updateSubNavItems();
  },
  { immediate: true }
);
</script>

<template>
  <CameraConfiguratorLauncher v-if="openCameraConfiguratorModal && currentCamera"
                              :camera="currentCamera"
                              :on-close="() => openCameraConfiguratorModal = false" />

  <SubHeader :heading="isLoading ? 'Loading...' : pageHeading"
             level="2" />

  <Breadcrumb :key="breadcrumbKey"
              :is-sticky="true"
              :items="breadcrumbs" />

  <SubNav :key="subNavKey"
          :is-loading="isLoading"
          :sub-nav-items="subNavItems"
          heading-level="3" />

  <router-view />
</template>
