<script setup lang="ts">
import { computed } from 'vue';

// Interface
interface LoadingProps {
  absolutePositioned?: boolean;
  text?: string;
  showText?: boolean;
}

// Props
const props = withDefaults(defineProps<LoadingProps>(), {
  showText: true,
  absolutePositioned: false,
  text: 'Loading',
});

// Computed
const textToDisplay = computed(() => props.text);
const containerClass = computed(() => (props.absolutePositioned ? 'loading__wrapper' : 'loading'));
</script>

<template>
  <div :class="containerClass">
    <div class="loading__content">
      <p v-if="props.showText" class="loading__content--text">
        {{ textToDisplay }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.loading {
  display: flex;
  width: 100%;

  &__content {
    position: relative;
    padding: 60px;
    width: 100%;
    height: auto;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      margin-block-start: -20px;
      margin-inline-start: -20px;
      content: '';
      background-image: url('/src/assets/heroIcons/loading.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      animation: rotate-360 2s linear infinite;
    }

    &--text {
      position: absolute;
      top: calc(50% + 40px);
      left: 50%;
      user-select: none;
      transform: translate(-50%, -50%);
      color: currentColor;
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__content {
      padding: 60px 60px 120px;
      background: var(--tls-black-opacity-25);
      backdrop-filter: blur(3px);
      border-radius: $border-radius;

      &--text {
        color: currentColor;
      }
    }
  }
}
</style>
