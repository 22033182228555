<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useApplicationStore } from '@stores/application';

import ViewSourceForm from '@components/view/ViewSourceForm.vue';
import ViewOriginForm from '@components/view/ViewOriginForm.vue';
import ViewSourceImportForm from '@components/view/ViewSourceImportForm.vue';
import { onMounted, ref, watchEffect } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import ContainerCard from '@components/cards/ContainerCard.vue';

enum wizardStates {
  SOURCE = 'SOURCE',
  IMPORT = 'IMPORT',
  ORIGIN = 'ORIGIN',
  FINISHED = 'FINISHED',
}


const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const route = useRoute();
const viewId = route.params['id'] as string;
const isLoadingView = ref<boolean>(true);
const importedSkipped = ref<boolean>(false);
const view = ref<client.View>();
const currentState = ref<wizardStates>(wizardStates.SOURCE);

// Figure out which state we are in
const fetchViewData = async (): Promise<void> => {
  isLoadingView.value = true;
  view.value = await client.getViewById({ viewId });
  isLoadingView.value = false;
};

watchEffect(() => {
  if (!view.value?.source?.prefix) {
    currentState.value = wizardStates.SOURCE;
    return;
  }

  if (!view?.value?.origins?.length && !importedSkipped.value && view?.value?.imageCount === 0) {
    console.log(view?.value?.imageCount);
    currentState.value = wizardStates.IMPORT;
    return;
  }

  if (view?.value?.origins?.length) {
    currentState.value = wizardStates.FINISHED;
    return;
  }

  currentState.value = wizardStates.ORIGIN;
})

onMounted(async () => {
  await fetchViewData();
});
</script>

<template>
  <template v-if="adminMode && !isLoadingView">
    <ViewSourceForm v-if="currentState === wizardStates.SOURCE"
                    @on-submitted="() => currentState = wizardStates.IMPORT" />

    <ViewSourceImportForm v-if="currentState === wizardStates.IMPORT"
                          @on-imported="() => currentState = wizardStates.ORIGIN"
                          @on-skipped="() => currentState = wizardStates.ORIGIN" />

    <ViewOriginForm v-if="currentState === wizardStates.ORIGIN"
                    @on-submitted="() => {
                      currentState = wizardStates.FINISHED
                      fetchViewData()
                    }" />

    <template v-if="currentState === wizardStates.FINISHED">
      <ContainerCard>
        <p>
          This View is configured for AWS images.
        </p>

        <p>
          If the Source or Origin is incorrect, delete the View and start again.
        </p>
        
        <li v-if="view?.source?.bucket">
          Source: {{ view.source.bucket }}/{{ view.source.prefix }}
        </li>
        <li v-if="view?.origins?.length && view?.origins[0]">
          Origin: {{ view.origins[0].bucket }}/{{ view.origins[0].prefix! }}
        </li>
      </ContainerCard>
    </template>
  </template>
</template>

<style lang="scss" scoped>
.checkbox-field {
  & .checkbox-label {
    margin-left: 10px;
    font-weight: normal;

    & .word-wrap {
      display: inline-block;
      width: 100%;
    }

    & .break-word {
      word-break: break-all;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  & input[type='checkbox'] {
    flex-shrink: 0;
  }
}
</style>
