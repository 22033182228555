<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useViewStore } from '@stores/view';
import { BadgeVariant, ProgressBarSize, ProgressBarVariant } from '@viewModels/enums';
import ProgressBar from '@components/ProgressBar.vue';
import Heading from '@components/Heading.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import { calculateBatteryPercentage } from '@utils/batteryUtils';
import Loading from '@components/Loading.vue';

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const isLoading = ref<boolean>(true);
let percent = ref<number>(0);
let charging = ref<boolean>(false);
let powerTypeMessage = ref<string>();
const viewStore = useViewStore();

const MAINS_MAX_VOLTAGE = 25;
const MAINS_MIN_VOLTAGE = 24;
const SOLAR_MAX_VOLTAGE = 24;
const SOLAR_MIN_VOLTAGE = 13;
const MAINS_MAX_VOLTAGE_V2 = 12.3;
const MAINS_MIN_VOLTAGE_V2 = 12.1;

interface BatteryInfo {
  percent: number;
  type: string;
  state: number;
  status: number;
  charging: boolean;
}

enum BatteryType {
  Mains = 'MAINS',
  Solar = 'SOLAR',
  Battery = 'BATTERY',
}

const defaultBatteryInfo: BatteryInfo = { percent: 0, type: 'unknown', state: -1, status: -1, charging: false };
const batteryInfo = ref<BatteryInfo>(defaultBatteryInfo);


/**
 * Retrieves the current battery information for the given view ID.
 *
 * This function fetches the latest camera statistics, extracts the relevant battery information,
 * and updates the batteryInfo object accordingly.
 *
 * @async
 * @returns {Promise<void>}
 */
async function GetBatteryInfo(): Promise<void> {
  // Retrieve the current view based on the provided view ID
  const currentView = await viewStore.getViewById(props.viewId);

  // If the current view does not have a camera associated with it, exit the function
  if (!currentView?.camera) return;

  // Fetch the latest camera statistics for the current view's camera
  const stats = await client.getCameraByIdStatLatest({ cameraId: currentView.camera });

  // Extract the bus voltage and input voltage from the camera statistics
  const busVoltage = ((stats.ina219BusVoltage ?? 0) >> 1) * 0.001;
  const inputVoltage = (stats.lt4015InputVoltage ?? 0) * 0.001 * 1.648;

  // Extract the charge state and charge status from the camera statistics
  const chargeState = stats.lt4015ChargerState ?? -1;
  const chargeStatus = stats.lt4015ChargerStatus ?? -1;

  // Update the batteryInfo object with the extracted charge state and charge status
  batteryInfo.value.state = chargeState;
  batteryInfo.value.status = chargeStatus;

  // Determine if the battery is currently charging based on the charge state and charge status
  if (batteryInfo.value.state > 1 && batteryInfo.value.status > 0) {
    batteryInfo.value.charging = true;
  }

  // Classify the power source based on the input voltage
  if (inputVoltage >= MAINS_MIN_VOLTAGE && inputVoltage <= MAINS_MAX_VOLTAGE) {
    batteryInfo.value.type = BatteryType.Mains; // 24V - 25V
  } else if (inputVoltage >= MAINS_MIN_VOLTAGE_V2 && inputVoltage <= MAINS_MAX_VOLTAGE_V2) {
    batteryInfo.value.type = BatteryType.Mains; // 12.1V - 12.3V
  } else if (inputVoltage > SOLAR_MIN_VOLTAGE && inputVoltage < SOLAR_MAX_VOLTAGE) {
    batteryInfo.value.type = BatteryType.Solar; // 13V - 24V
  } else if (inputVoltage < SOLAR_MIN_VOLTAGE) {
    batteryInfo.value.type = BatteryType.Battery; // < 13V
  }

  // Calculate the battery percentage based on the bus voltage
  batteryInfo.value.percent = calculateBatteryPercentage(busVoltage);
}

async function updateData(): Promise<void> {
  await GetBatteryInfo();
  percent.value = batteryInfo.value.percent;
  if (batteryInfo.value.state > 1 && batteryInfo.value.status > 0) charging.value = true;

  if (batteryInfo.value.type === BatteryType.Mains) powerTypeMessage.value = 'On AC power';
  else if (batteryInfo.value.type === BatteryType.Solar) powerTypeMessage.value = 'On Solar power';
  else if (batteryInfo.value.type === BatteryType.Battery) powerTypeMessage.value = 'On Battery power';
  else powerTypeMessage.value = 'Unknown power source';
}

function getBatteryVariant(percent: number): ProgressBarVariant {
  if (percent >= 75) return ProgressBarVariant.Success;
  if (percent >= 50) return ProgressBarVariant.Warning;
  return ProgressBarVariant.Danger;
}

onMounted(async () => {
  isLoading.value = true;
  try {
    await updateData();
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
});
</script>

<template>
  <Loading v-if="isLoading" />

  <template v-else>
    <div class="battery-panel__title">
      <Heading level="4">
        Battery
      </Heading>
      <BadgeComponent v-if="charging"
                      :variant="BadgeVariant.Success">
        CHARGING
      </BadgeComponent>
    </div>

    <div class="battery-panel">
      <p class="battery-panel__subtitle">
        <span>Source: {{ powerTypeMessage }}</span>
      </p>
      <ProgressBar :has-text="true"
                   :percent="percent"
                   :height="ProgressBarSize.Large"
                   :has-bottom-margin="true"
                   :variant="getBatteryVariant(percent)" />
    </div>
  </template>
</template>
<style lang="scss" scoped>
@use '@scss/variables' as *;

.battery-panel {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-bottom;
  }

  &__subtitle {
    margin-bottom: 10px;
  }
}
</style>
