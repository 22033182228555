<script setup lang="ts">
import { onMounted, onUnmounted, PropType, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import ModalComponent from '@components/ModalComponent.vue';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import { CheckIcon } from '@heroicons/vue/24/solid';
import { sleep } from '@utils/sleep';
import AlertBanner from '@components/AlertBanner.vue';
import { AlertVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

// NOTE: Console logs have been intentionally left in place to aid in debugging during development and testing.
// OBSERVATIONS: The UI will not be fully ready until the probe is successful, as the Pi is running on a 3G connection, which introduces significant delays during startup.
// This means the app cannot confirm the readiness of the Pi's user interface immediately after the probe succeeds.
// TODO: A more robust approach would involve implementing a mechanism for the Pi to send a definitive signal or response indicating that it is fully operational and the UI is fully loaded, rather than relying on fixed delays and polling.

// Timings
const PAUSE_UI = 1000; // Pause animations for smoother user experience and to visually separate state transitions.
const PROBE_INTERVAL = 10000; // Probe every 10 seconds to avoid overloading requests, preventing posts from getting stuck in a pending state.
const PROBE_DURATION = 180000; // Probe for a maximum of 3 minutes, accounting for potential delays; most Pis should come online within 1-2 minutes.
const WAIT_FOR_PI_UI = 30000; // Wait for 30 seconds after the Pi becomes responsive to ensure the configurator UI is fully loaded.
const TOKEN_RETRY_INTERVAL = 3000; // Retry sending the auth token every 3 seconds to ensure successful authentication with the Pi.

enum States {
  GETTING_CAMERA_ID = 'GETTING_CAMERA_ID',
  SENDING_BOOT = 'SENDING_BOOT',
  PROBING = 'PROBING',
  WAITING_FOR_UI_READY = 'WAITING_FOR_UI_READY',
  LAUNCHING = 'LAUNCHING',
  ERROR = 'ERROR',
}

enum StateMessage {
  GETTING_CAMERA_ID = 'Step 1: Retrieving camera details',
  SENDING_BOOT = 'Step 2: Sending wake up command to the module',
  PROBING = 'Step 3: Checking module availability',
  WAITING_FOR_UI_READY = 'Step 4: Preparing module user interface',
  LAUNCHING = 'Step 5: Launching configurator',
}

enum ProgressIcon {
  IN_PROGRESS = 'progress-icon progress-icon--in-progress',
  PENDING = 'progress-icon progress-icon--pending',
  COMPLETE = 'progress-icon progress-icon--complete',
  FAILED = 'progress-icon progress-icon--failed',
}

const completedStates = ref<States[]>([]);
const currentState = ref<States | null>();
const launchErrorMessage = ref<string>();
const countdownTimer = ref<number | null>(null);
const apiErrorMessage = ref<string>();
const tokenInformation = ref<client.CreateCameraTokenResponse>();
const pollingInterval = ref();

// Probe State
const probeAttempts = ref(0);
const probeMaxAttempts = ref(0);
const probeErrorMessage = ref<string | null>(null);
const probeFailedMessage = ref<string | null>(null);

const props = defineProps({
  camera: { type: Object as PropType<client.Camera>, required: true },
  onClose: { type: Function, required: true}
});

/**
 * Initialises the connection to a remote camera device and manages the steps of its boot sequence.
 *
 * This function performs the following tasks:
 * 1. Requests an authentication token for the camera device via the API.
 * 2. Sends a boot command to wake up the device and ensure it is powered on and stays awake.
 * 3. Probes the device URL at regular intervals (e.g., every 10 seconds) to check its availability.
 * 4. Waits an additional buffer period after the device responds to ensure the configurator UI is fully loaded.
 * 5. Launches the configurator UI by sending the authentication token to the device.
 * 6. Handles timeouts and errors, displaying appropriate feedback in the UI if the device fails to respond.
 *
 * State and UI Feedback:
 * - Updates the UI states (`completedStates`, `currentState`) to reflect the progress of each step.
 * - Displays progress messages, countdowns, and error notifications to keep the user informed.
 *
 * Probing Logic:
 * - Probes for a maximum of 3 minutes (with a 10-second interval) to determine if the device is online.
 * - If the device does not respond within this period, an error is shown to the user indicating that the device
 *   could not be reached.
 *
 * @returns {Promise<void>} Resolves when the device has been successfully launched and the configurator UI is displayed,
 *                          or handles errors if the boot sequence fails.
 */

const init = async (): Promise<void> => {
  try {
    // Step 1: Retrieve Camera Details
    setState(States.GETTING_CAMERA_ID);
    tokenInformation.value = await client.createCameraToken({ cameraId: props.camera.id });

    // For local testing, set device URL for localhost - the only downside to this is that It's hard to test if a Pi is offline, unless you start the Pi localhost after a delay manually
    // if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    //   tokenInformation.value.deviceUrl = 'http://localhost:5174/auth';
    // }

    await sleep(PAUSE_UI);
    completedStates.value.push(States.GETTING_CAMERA_ID); // Mark as completed
    console.log("✅ Camera details retrieved successfully.");

    // Step 2: Send Boot Command
    setState(States.SENDING_BOOT);
    await sendBootCommand();
    completedStates.value.push(States.SENDING_BOOT); // Mark as completed
    console.log("✅ Boot command sent to the Module.");

    // Step 3: Probe the Camera
    setState(States.PROBING);
    const isOnline = await probe(tokenInformation.value.deviceUrl, PROBE_DURATION); // Probe for a maximum of 3 minutes

    if (!isOnline) {
      probeFailedMessage.value = 'Unable to connect to the module. The camera may be in low power state and will not respond. Please try again later or contact support.';
      throw new Error(probeFailedMessage.value);
    }
    completedStates.value.push(States.PROBING); // Mark as completed
    console.log("✅ Module is now online.");

    // Step 4: Wait for the Module UI to Be Ready
    await waitForUIReady();

    // Step 5: Launch the Configurator
    setState(States.LAUNCHING);
    await launch(tokenInformation.value);
    completedStates.value.push(States.LAUNCHING); // Mark as completed
    console.log("✅ Configurator launched successfully!");

  } catch (error) {
    // Step 6: Handle Errors
    handleError(error);
  }
};


const setState = (state: States): void => {
  currentState.value = state;
  console.log(`🔄 Transitioning to state: ${state}`);
};

const probe = async (url: string, timeout: number): Promise<boolean> => {
  probeAttempts.value = 0;
  probeMaxAttempts.value = Math.ceil(timeout / PROBE_INTERVAL);

  console.log(`🔍 Starting probe for ${url}. Max attempts: ${probeMaxAttempts.value}.`);

  while (probeAttempts.value < probeMaxAttempts.value) {
    probeAttempts.value++;
    probeErrorMessage.value = `(Attempt ${probeAttempts.value}/${probeMaxAttempts.value})`;

    try {
      const response = await fetch(url, { method: 'GET' });
      const contentType = response.headers.get('Content-Type');

      if (response.ok && contentType?.includes('text/html')) {
        console.log(`✅ Connectivity check passed for ${url}. Content-Type: ${contentType}`);
        return true;
      } else {
        console.warn(`⚠️ Attempt ${probeAttempts.value}/${probeMaxAttempts.value}: Unexpected Content-Type: ${contentType}`);
      }
    } catch (error) {
      console.warn(`⏳ Attempt ${probeAttempts.value}/${probeMaxAttempts.value} probing failed - ${error}`);
    }

    if (probeAttempts.value < probeMaxAttempts.value) {
      await sleep(PROBE_INTERVAL); // Wait before retrying
    }
  }

  console.error('❌ Probe failed after max attempts.');
  probeFailedMessage.value = 'Unable to connect to the module. The camera may be in low power state and will not respond. Please try again later or contact support.';
  return false;
};


const handleError = (error: unknown): void => {
  console.error('❌ Error encountered:', error);
  currentState.value = States.ERROR;
  probeErrorMessage.value = error instanceof Error ? error.message : 'Unknown error occurred.';
};


const sendBootCommand = async (): Promise<void> => {
  currentState.value = States.SENDING_BOOT;
  console.log("🚀 Sending boot command to the device...");

  try {
    await client.createCameraByIdWakeupCommand({ cameraId: props.camera.id });
  } catch (error) {
    console.error("❌ Failed to send boot command:", error);
  }

  await sleep(PAUSE_UI);
  completedStates.value.push(States.SENDING_BOOT);
  currentState.value = null;
};


const sendToken = async (): Promise<void> => {
  let retries = 0;
  const maxRetries = 5;

  const waitForSuccess = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      const listener = (event: MessageEvent): void => {
        if (event.data?.status === 'success') {
          console.log("✅ Token successfully processed by Pi.");
          window.removeEventListener('message', listener);
          resolve(true);
        }
      };
      window.addEventListener('message', listener);

      // Timeout after 3 seconds if no response
      setTimeout(() => {
        window.removeEventListener('message', listener);
        resolve(false);
      }, TOKEN_RETRY_INTERVAL);
    });
  };

  while (retries < maxRetries) {
    retries++;
    if (!tokenInformation.value) {
      console.error("❌ Token information is undefined. Cannot send token.");
      throw new Error("Token information is not available. Please try again.");
    }

    console.log(`Attempting to send token. Attempt ${retries}/${maxRetries}`);
    configurator?.postMessage(
      JSON.parse(JSON.stringify(tokenInformation.value)),
      tokenInformation.value.deviceUrl
    );

    const success = await waitForSuccess();
    if (success) {
      console.log("✅ Token successfully sent and acknowledged.");
      return; // Exit if the token is successfully processed
    }

    console.warn(`⚠️ Token not acknowledged by Pi. Retrying (${retries}/${maxRetries})...`);
    await sleep(3000); // Wait before retrying
  }

  console.error("❌ Failed to send token after maximum retries.");
  throw new Error("Unable to authenticate with the module. Please try again or contact support.");
};


let configurator: Window | null;
const launch = async (tokenInformation: client.CreateCameraTokenResponse): Promise<void> => {
  currentState.value = States.LAUNCHING;
  await sleep(PAUSE_UI);

  if (!configurator) {
    configurator = window.open(tokenInformation.deviceUrl, 'configurator');
  }

  // Attempt to send the token with retry logic
  await sendToken();
}



/**
 * Wait for the Module UI to Be Ready with Countdown
 */
const waitForUIReady = async (): Promise<void> => {
  setState(States.WAITING_FOR_UI_READY);

  countdownTimer.value = WAIT_FOR_PI_UI / 1000; // Initialise countdown in seconds

  const timer = setInterval(() => {
    if (countdownTimer.value && countdownTimer.value > 0) {
      countdownTimer.value -= 1; // Decrement timer
    } else {
      clearInterval(timer); // Clear interval when done
    }
  }, 1000);

  console.log("⏳ Waiting for the Module UI to fully load...");
  await sleep(WAIT_FOR_PI_UI); // Wait for the duration

  clearInterval(timer); // Ensure timer is cleared
  countdownTimer.value = null; // Reset countdown
  completedStates.value.push(States.WAITING_FOR_UI_READY); // Mark as completed
  console.log("✅ Module UI is ready.");
};

const messageListener = (event: MessageEvent): void => {
  if (tokenInformation.value && !tokenInformation.value.deviceUrl.startsWith(event.origin)) return;

  if (event.data.status === 'success') {
    completedStates.value.push(States.LAUNCHING)
    currentState.value = undefined;
  } else if (event.data.status === 'error') {
    completedStates.value.push(States.LAUNCHING)
    currentState.value = States.ERROR;
    launchErrorMessage.value = event.data.message;
  }
}

async function closeModal(): Promise<void> {
  // Stop polling
  if (pollingInterval.value) {
    clearInterval(pollingInterval.value);
    pollingInterval.value = undefined;
    console.log("🧹 Polling interval cleared.");
  }

  // Reset state and variables
  currentState.value = null;
  completedStates.value = [];
  launchErrorMessage.value = undefined;
  apiErrorMessage.value = undefined;

  // Remove message listener
  window.removeEventListener("message", messageListener);
  console.log("🧹 Message listener removed.");

  // Call the provided onClose callback
  props.onClose();
}


onMounted(() => {
  init();
  window.addEventListener("message", messageListener);
})

onUnmounted(() => {
  if (pollingInterval.value) clearInterval(pollingInterval.value);
  window.removeEventListener("message", messageListener);
  console.log('🧹 Unmounting');
});


</script>

<template>
  <ModalComponent :visible="true"
                  heading-title="Launching Configurator"
                  @on-close="closeModal">
    <template v-if="apiErrorMessage" #modal-content>
      <span class="message-error">
        {{ apiErrorMessage }}
      </span>
    </template>
    
    <template v-else #modal-content>
      <section class="camera-configurator-launcher">
        <AlertBanner :variant="AlertVariant.Warning"
                     :icon-style="IconStyle.Outline"
                     :icon-name="IconName.ExclamationTriangleIcon">
          <template #mainContent>
            <span>Please be aware when the configurator launches at Step 5, you may be prompted in the address bar to allow popups from this website as we transfer you to the module.</span>
          </template>
        </AlertBanner>
        
        <!-- Step 1: Retrieving Camera Details -->
        <div class="camera-configurator-launcher__step">
          <ArrowPathIcon v-if="!completedStates.includes(States.GETTING_CAMERA_ID)"
                         :aria-label="StateMessage.GETTING_CAMERA_ID"
                         :class="currentState === States.GETTING_CAMERA_ID ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING" />
          <CheckIcon v-if="completedStates.includes(States.GETTING_CAMERA_ID)"
                     :aria-label="StateMessage.GETTING_CAMERA_ID"
                     :class="ProgressIcon.COMPLETE" />
          <div class="camera-configurator-launcher__content">
            <span class="camera-configurator-launcher__step-text">
              {{ StateMessage.GETTING_CAMERA_ID }}
            </span>
          </div>
        </div>

        <!-- Step 2: Sending Boot Command -->
        <div class="camera-configurator-launcher__step">
          <ArrowPathIcon v-if="!completedStates.includes(States.SENDING_BOOT)"
                         :aria-label="StateMessage.SENDING_BOOT"
                         :class="currentState === States.SENDING_BOOT ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING" />
          <CheckIcon v-if="completedStates.includes(States.SENDING_BOOT)"
                     :aria-label="StateMessage.SENDING_BOOT"
                     :class="ProgressIcon.COMPLETE" />
          <div class="camera-configurator-launcher__content">
            <span class="camera-configurator-launcher__step-text">
              {{ StateMessage.SENDING_BOOT }}
            </span>
          </div>
        </div>

        <!-- Step 3: Probing -->
        <div class="camera-configurator-launcher__step">
          <!-- Pending State -->
          <ArrowPathIcon v-if="!probeErrorMessage && !completedStates.includes(States.PROBING) && probeAttempts === 0"
                         :aria-label="StateMessage.PROBING"
                         :class="ProgressIcon.PENDING" />

          <!-- In Progress or Failed State -->
          <ArrowPathIcon v-else-if="!completedStates.includes(States.PROBING)"
                         :aria-label="StateMessage.PROBING"
                         :class="probeAttempts < probeMaxAttempts ? ProgressIcon.IN_PROGRESS : ProgressIcon.FAILED" />

          <!-- Complete State -->
          <CheckIcon v-if="completedStates.includes(States.PROBING)"
                     :aria-label="StateMessage.PROBING"
                     :class="ProgressIcon.COMPLETE" />

          <div class="camera-configurator-launcher__content" :style="probeFailedMessage ? 'flex-direction: column' : 'flex-direction: row'">
            <span class="camera-configurator-launcher__step-text">
              {{ StateMessage.PROBING }}
            </span>
            <!-- Attempt Message -->
            <div v-if="currentState === States.PROBING && probeErrorMessage && !probeFailedMessage"
                 class="camera-configurator-launcher__step-state">
              {{ probeErrorMessage }}
            </div>
            <!-- Failure Message -->
            <div v-if="probeFailedMessage" :class="probeAttempts === probeMaxAttempts ? 'camera-configurator-launcher__step-error' : ''">
              {{ probeFailedMessage }}
            </div>
          </div>
        </div>


        <!-- Step 4: Waiting for UI -->
        <div class="camera-configurator-launcher__step">
          <ArrowPathIcon v-if="!completedStates.includes(States.WAITING_FOR_UI_READY)"
                         :aria-label="StateMessage.WAITING_FOR_UI_READY"
                         :class="currentState === States.WAITING_FOR_UI_READY ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING" />
          <CheckIcon v-if="completedStates.includes(States.WAITING_FOR_UI_READY)"
                     :aria-label="StateMessage.WAITING_FOR_UI_READY"
                     :class="ProgressIcon.COMPLETE" />
          <div class="camera-configurator-launcher__content">
            <span class="camera-configurator-launcher__step-text">
              {{ StateMessage.WAITING_FOR_UI_READY }}
            </span>
            <!-- Countdown Timer -->
            <div v-if="currentState === States.WAITING_FOR_UI_READY && countdownTimer !== null"
                 class="camera-configurator-launcher__step-state">
              ({{ countdownTimer }} seconds remaining)
            </div>
          </div>
        </div>

        <!-- Step 5: Launching -->
        <div class="camera-configurator-launcher__step">
          <ArrowPathIcon v-if="!completedStates.includes(States.LAUNCHING)"
                         :aria-label="StateMessage.LAUNCHING"
                         :class="currentState === States.LAUNCHING ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING" />
          <CheckIcon v-if="completedStates.includes(States.LAUNCHING)"
                     :aria-label="StateMessage.LAUNCHING"
                     :class="ProgressIcon.COMPLETE" />
          <div class="camera-configurator-launcher__content">
            <span class="camera-configurator-launcher__step-text">
              {{ StateMessage.LAUNCHING }}
            </span>
            <div v-if="launchErrorMessage" class="camera-configurator-launcher__step-error">
              ({{ launchErrorMessage }})
            </div>
          </div>
        </div>
      </section>
    </template>
  </ModalComponent>
</template>


<style lang="scss" scoped>
@use '@scss/variables' as *;

.camera-configurator-launcher {
  display: grid;
  grid-template-columns: 1fr;
  gap: $gap-desktop;
  margin-bottom: $margin-bottom;

  &__step {
    display: grid;
    grid-template-columns: auto 1fr; // Align icon and text
    gap: $gap-mobile;
    align-items: center;

    & .progress-icon {
      width: 1.3rem;
      height: 1.3rem;

      &--pending {
        color: var(--tls-gray-500);
      }

      &--failed {
        color: var(--tls-error-color);
      }

      &--in-progress {
        color: var(--tls-warning-color);
        animation: rotate-360 0.75s linear infinite;
      }

      &--complete {
        color: var(--tls-success-color);
      }
    }

    &-text {
      font-weight: 500;
    }

    &-state {
      color: var(--tls-gray-500);
    }

    &-error {
      color: var(--tls-error-color);
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    gap: 5px;

    @media screen and (min-width: $breakpoint-lg) {
      // flex-direction: row;
    }
  }
}
</style>

