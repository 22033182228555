export const BATTERY_TOLERANCE = 0.25;
export const MAX_VOLTAGE = 8.2;
export const MIN_VOLTAGE = 6.2;

/**
 * Calculates battery percentage based on bus voltage.
 * @param busVoltage The measured bus voltage.
 * @returns The battery percentage, clamped between 0 and 100.
 */
export function calculateBatteryPercentage(busVoltage: number): number {
  let percent = Math.round(((busVoltage - MIN_VOLTAGE) * 100) / (MAX_VOLTAGE - MIN_VOLTAGE));

  // Clamp the percentage
  if (busVoltage <= MIN_VOLTAGE) percent = 0;
  if (busVoltage >= MAX_VOLTAGE - BATTERY_TOLERANCE && percent == 99) percent = 100;
  if (percent > 100) percent = 100;

  return percent;
}
