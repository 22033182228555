<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, BreadcrumbTitles, PageNames } from '@viewModels/enums';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import SubHeader from '@components/SubHeader.vue';
import TransferSettingForm, { TransferSettingFormInterface } from '@components/transfer/TransferSettingForm.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';

const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const errors = ref<Record<string, string[]>>()
const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllTransfers, to: BreadcrumbPaths.AllTransfers },
  { title: BreadcrumbTitles.CreateTransfer, active: true },
];

const onSubmit = async (values: TransferSettingFormInterface): Promise<void> => {
  if (!applicationStore.activeOrganisation) return;

  isSubmitting.value = true;
  try {
    await client.createTransferSetting({
      requestBody: {
        organisation: applicationStore.activeOrganisation.id,
        name: values.name,
        options: {
          server: values.server,
          username: values.username,
          password: values.password,
          type: values.type,
          port: values.port,
        }
      }
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully created client.',
      autoCloseMs: 3000,
    });
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      errors.value = errorFields;
    }

    applicationStore.publishErrorNotification({
      text: `Error creating Transfer: ${errorMessage}`,
    });

    return;
  } finally {
    isSubmitting.value = false;
  }

  await router.push({ name: PageNames.AdminTransfers });
};

const onCancel = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.ViewTransfers as string);
  } else if (routerHistory.state['back']) {
    router.go(-1);
  }
};
</script>

<template>
  <SubHeader heading="Create Transfer"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <TransferSettingForm :is-submitting="isSubmitting"
                       :errors="errors"
                       @on-submit="(values) => onSubmit(values)"
                       @on-cancel="onCancel" />
</template>
