<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue';
import dayjs from 'dayjs';
import { DatePicker } from 'v-calendar';
import { DatePickerTheme } from '@viewModels/enums';
import { generateMissingDateRanges } from '@utils/generateMissingDates';
import { useIrisViewerStore } from '@stores/irisViewerStore';

interface DatePickerProps {
  variant?: DatePickerTheme;
}

// Date Picker Interface
interface HighlightedDates {
  key: string;
  highlight?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink' | {
    color?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink';
    fillMode?: 'solid' | 'light' | 'outline';
  };
  dot?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink';
  order?: number;
  dates: string[];
}

const props = defineProps<DatePickerProps>();
const irisStore = useIrisViewerStore();
const height = ref(601);
const calendar = ref<InstanceType<typeof DatePicker>>();
const imagesCapturedOn: Ref<HighlightedDates[]> = ref([]);

const calendarStyles = computed(() => {
  if (props.variant === DatePickerTheme.Dark) {
    return {
      '--vc-text-lg': 'var(--tls-font-size-xs)',
      '--vc-day-content-disabled-color': 'var(--tls-primary-300)',
      '--vc-weekday-color': 'var(--tls-primary-text)',
      '--vc-rounded-full': '5px',
    };
  }
  return {
    '--vc-text-lg': 'var(--tls-font-size-xs)',
    '--vc-day-content-disabled-color': 'var(--vc-gray-600)',
    '--vc-rounded-full': '5px',
  };
});

/**
 * Watches the `selectedDate` property in the `irisStore` and updates the calendar view
 * whenever the date changes. If the selected date is `null`, the function exits early.
 *
 * @param {Function} getter - A reactive getter function to track changes to `irisStore.selectedDate`.
 * @param {Function} callback - An asynchronous function that handles the calendar movement when the date changes.
 */
watch(
  () => irisStore.selectedDate,
  async (newValue) => {
    // Exit early if the selected date is null (picks the same date)
    if (!newValue) {
      return;
    }

    // Move the calendar to the selected date
    await calendar.value?.move(newValue);
  },
);

const disabledDates = computed(() => {
  return generateMissingDateRanges(irisStore.capturedDates);
});

const updateImagesCapturedOn = (): void => {
  const validDates = irisStore.capturedDates.filter((date) => dayjs(date).isValid());

  imagesCapturedOn.value = validDates.map((date) => ({
    key: 'availableDates',
    highlight: {
      color: 'green',
      fillMode: 'light',
    },
    order: -1,
    dates: [dayjs(date).format('YYYY-MM-DD')],
  }));
};

/**
 * Handles the date update event from the DatePicker. Updates the `selectedDate`
 * in the `irisStore` if the provided date is valid. Exits early if the date is `null`.
 *
 * @param {string | null} selectedDate - The new date selected by the user, or `null` if the same date is selected again.
 * @returns {void} This function does not return anything.
 */
const onUpdateDate = (selectedDate: string): void => {
  // Exit early if the selected date is null (user picks the same date)
  if (selectedDate === null) {
    return;
  } else {
    irisStore.setSelectedDate(selectedDate);
  }
};

onMounted(() => {
  updateImagesCapturedOn();
});
</script>

<template>
  <div class="view__date-picker primary-date-picker">
    <DatePicker ref="calendar"
                v-model="irisStore.selectedDate"
                :input-debounce="500"
                :style="calendarStyles"
                color="green"
                :expanded="true"
                :borderless="true"
                :transparent="true"
                title-position="left"
                :first-day-of-week="2"
                :is-inline="height > 600"
                :disabled-dates="disabledDates"
                mode="date"
                :attributes="imagesCapturedOn"
                :min-date="irisStore.capturedDates[0]"
                :max-date="irisStore.capturedDates.at(-1)"
                @update:model-value="onUpdateDate" />
  </div>
</template>
<style lang="scss" scoped>
@use '@scss/variables' as *;

.view__date-picker {
  display: flex;
  justify-content: center;
  margin-bottom: unset;
}
</style>
