<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import { BreadcrumbTitles, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Directory from '@components/directory/Directory.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import ViewCard from '@components/cards/ViewCard.vue';
import ViewDirectoryToolbar, { ViewDirectoryQueryParameters } from '@components/view/ViewDirectoryToolbar.vue';
import ResourcePagination from '@components/ResourcePagination.vue';

// Stores
const applicationStore = useApplicationStore();
const viewStore = useViewStore();
const { viewCollectionRef } = storeToRefs(viewStore);
const isLoading = ref<boolean>(true);
const criteria = ref<ViewDirectoryQueryParameters>({ });
const canCreateView = applicationStore.canUser(client.Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);
const breadcrumbs: BreadCrumbItem[] = [{ title: BreadcrumbTitles.Views, active: true }];

async function getViewsList(search?: ViewDirectoryQueryParameters): Promise<void> {
  isLoading.value = true;
  try {
    // Fetch the views list based on the search and sort parameters
    await viewStore.obtainViewList(search);

    // Fetch the list of registered cameras
    const cameraResponse = await client.listCameras({ organisation: applicationStore.activeOrganisation!.id });

    // Create a map of live cameras
    const liveCameras = new Map(
      cameraResponse.data
        .filter((camera: client.Camera) => camera.status === client.CameraStatus.REGISTERED)
        .map((camera: client.Camera) => [camera.id, true]),
    );

    // Annotate each view with whether it has a live camera
    viewCollectionRef.value.data = viewCollectionRef.value.data.map((view) => ({
      ...view,
      hasCamera: liveCameras.has(view.camera!), // Add a `hasCamera` property
    }));

  } catch (error) {
    console.error('Error fetching views or cameras:', error);
  } finally {
    isLoading.value = false;
  }
}

async function setPage(value: number): Promise<void> {
  viewStore.paginationOptions.pageNumber = value;
  await getViewsList(criteria.value);
}

async function setLimit(value: number): Promise<void> {
  viewStore.paginationOptions.pageNumber = 1;
  viewStore.paginationOptions.pageSize = value;
  await getViewsList(criteria.value);
}

watch(criteria, () => {
  viewStore.paginationOptions.pageNumber = 1;
  getViewsList(criteria.value);
}, { deep: true });

onMounted(async () => {
  viewStore.enablePagination(true);
  await getViewsList(criteria.value);
});

const updateCriteria = (value: ViewDirectoryQueryParameters): void => {
  const currentCriteria = criteria.value;
  criteria.value = {
    ...currentCriteria,
    ...value,
  };
}
</script>

<template>
  <SubHeader heading="Views"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="canCreateView"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ViewNew }"
                       :variant="ButtonVariant.Primary"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add View
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <ViewDirectoryToolbar @criteria-selected="(value: ViewDirectoryQueryParameters) => updateCriteria(value)" />

    <Directory :object-collection-reference="viewCollectionRef.data"
               :loading="isLoading">
      <template #default="scope">
        <ViewCard :resource="scope.resource" :loading="isLoading" />
      </template>

      <template #table-empty>
        <template v-if="canCreateView">
          <EmptyState heading-text="No views found"
                      strap-line="Get started by creating a new view"
                      :button-variant="ButtonVariant.Dark"
                      button-text="New View"
                      :icon-name="IconName.PhotoIcon"
                      :icon-style="IconStyle.Outline"
                      :to="{ name: PageNames.ViewNew }" />
        </template>
        <template v-else>
          <EmptyState heading-text="No views found"
                      :icon-name="IconName.PhotoIcon"
                      :icon-style="IconStyle.Outline" />
        </template>
      </template>
    </Directory>

    <ResourcePagination :total="viewCollectionRef.total_count"
                        :page="viewStore.paginationOptions.pageNumber"
                        :limit="viewStore.paginationOptions.pageSize"
                        @limit-changed="(e: number) => setLimit(e)"
                        @page-changed="(e: number) => setPage(e)" />
  </ContainerCard>
</template>
