<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import { BadgeVariant } from '@viewModels/enums';
import BadgeComponent from '@components/BadgeComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import { dateTimeFormat } from '@utils/date';
import { useApplicationStore } from '@stores/application';
import ResourcePagination from '@components/ResourcePagination.vue';
import Heading from '@components/Heading.vue';

const props = defineProps({
  resource: {
    type: Object as PropType<client.Resources>,
    required: true,
  },
  resourceId: {
    type: String,
    required: true
  }
});

interface Logs {
  data: client.Log[];
  total_count: number;
  has_more: boolean;
}

const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;
const page = ref(1);
const limit = ref(24);
const includeDebug = ref(false);
const isLoading = ref<boolean>(false);
const logs = ref<Logs | null>(null);
const fetch = async (): Promise<void> => {
  try {
    isLoading.value = true;
    const { data, total_count, has_more } = await client.listLogsByResourceAndId({
      resource: props.resource,
      id: props.resourceId,
      page: page.value,
      limit: limit.value,
      sort: 'desc',
      orderBy: client.LogOrderByFields.TIMESTAMP,
      level: levelFilter.value,
    });
    logs.value = { data, total_count, has_more };
  } catch (error) {
    console.error('Error fetching camera logs:', error);
  } finally {
    isLoading.value = false;
  }
};

function getBadgeVariant(status: client.LogLevel): BadgeVariant {
  const variantMap: { [key in client.LogLevel]: BadgeVariant } = {
    INFO: BadgeVariant.Info,
    WARN: BadgeVariant.Warning,
    ERROR: BadgeVariant.Danger,
    DEBUG: BadgeVariant.Outline,
  };
  return variantMap[status];
}

const levelFilter = computed(() => {
  return Object.values(client.LogLevel).filter((v) => !(!includeDebug.value && v == client.LogLevel.DEBUG));
});

onMounted( () => fetch());
watch([limit, page], () => fetch())
watch([includeDebug], () => {
  page.value = 1;
  fetch();
});
</script>

<template>
  <ContainerCard>
    <div class="log-header">
      <Heading level="3" :has-bottom-margin="true">
        Logs
      </Heading>

      <span>
        <label for="includeDebug">
          Include debug
          <input id="includeDebug" v-model="includeDebug" type="checkbox">
        </label>
      </span>
    </div>

    <Loading v-if="isLoading" />
    <template v-else>
      <template v-if="logs?.total_count">
        <div class="log-grid-container">
          <div class="log-grid">
            <div v-for="(item, index) in logs?.data" :key="index" class="log-grid__item">
              <div class="log-grid__index">
                <span class="log-grid__value">
                  {{ item.message }}
                </span>
              </div>

              <div class="log-grid__info">
                <BadgeComponent class="log-grid__timestamp" :variant="BadgeVariant.Light">
                  {{ dateTimeFormat(activeUser?.timezone).format(new Date(item.timestamp)) }}
                </BadgeComponent>

                <BadgeComponent class="log-grid__badge"
                                :is-pill="true"
                                :variant="getBadgeVariant(item.level) as BadgeVariant">
                  {{ item.level }}
                </BadgeComponent>
              </div>
            </div>
          </div>
        </div>

        <ResourcePagination :total="logs.total_count"
                            :page="page"
                            :limit="limit"
                            @limit-changed="(e: number) => {page = 1; limit = e}"
                            @page-changed="(e: number) => page = e" />
      </template>
      <template v-else>
        No log data available.
      </template>
    </template>
  </ContainerCard>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.log-header {
  display: flex;
  justify-content: space-between;
}

.log-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: clamp($gap-mobile, 3vw, $gap-desktop);
  font-size: var(--tls-font-size-xs);

  &-container {
    container-type: inline-size;
    container-name: log;
    margin-bottom: $margin-bottom;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px 20px;
    padding: 10px 0;
    border-top: 1px solid var(--tls-gray-300);

    &:nth-child(1) {
      border-top: 0;
    }
  }

  &__index {
    display: flex;
    gap: 10px;
  }

  &__info {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    &:deep(.badge) {
      font-size: var(--tls-font-size-xxs);
    }
  }

  &__badge {
    min-width: 60px;
    margin-left: auto;
  }

  &__timestamp {
    min-width: 150px;
    white-space: nowrap;
  }

  &__value {
    word-break: break-all;
    overflow-wrap: break-word;
  }

  // Using a @container to limit the width because of the sidenav
  @container log (min-width: 800px) {
    padding: 10px 20px;
    border: 1px solid var(--tls-gray-400);
    border-radius: $border-radius;

    &__item {
      flex-direction: row;
    }

    &__index {
      flex-grow: 1;
      display: flex;
      gap: 10px;
    }

    &__info {
      justify-content: flex-start;
    }
  }
}
</style>
