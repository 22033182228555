<script lang="ts" setup>
import { computed, ref } from 'vue';
import { getIconComponent } from '@utils/heroIconsMap';
import { AlertVariant, ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';

// AlertBanner Interface
interface AlertBannerProps {
  iconName?: IconName;
  iconStyle?: IconStyle;
  variant?: AlertVariant;
  hasShadow?: boolean;
  hasCloseButton?: boolean;
  hasBottomMargin?: boolean;
}

// Use the interface directly with defineProps
const props = defineProps<AlertBannerProps>();

// Define Defaults
const variant = computed(() => props.variant || 'info');
const hasShadow = computed(() => props.hasShadow || false);
const hasBottomMargin = computed(() => props.hasBottomMargin ?? true);

// Emit close event
const emit = defineEmits(['close']);

// Show the notification bar
const showNotificationBar = ref(true);

// Method to emit the close event
const emitClose = (): void => {
  emit('close');
  showNotificationBar.value = false;
};

// Expose a method to programmatically close the notification
const closeNotificationBar = (): void => {
  showNotificationBar.value = false;
};

defineExpose({
  closeNotificationBar,
});
</script>

<template>
  <Transition name="fade">
    <div v-if="showNotificationBar"
         :aria-hidden="!showNotificationBar"
         class="notification-bar">
      <div :class="['notification-bar--holder', variant, { 'margin-bottom': hasBottomMargin, 'notification-bar--shadow': hasShadow }]">
        <component :is="getIconComponent(iconName, iconStyle)"
                   v-if="iconName"
                   aria-hidden="true"
                   class="notification-bar--icon" />

        <span :class="['notification-bar--content', variant]">
          <slot name="mainContent" />
        </span>

        <ButtonComponent v-if="hasCloseButton"
                         :icon-name="IconName.XMarkIcon"
                         :icon-style="IconStyle.Outline"
                         :is-icon-btn="true"
                         :is-outline-btn="true"
                         aria-label="Close Banner"
                         class="notification-bar--close"
                         :variant="ButtonVariant.Link"
                         @click="emitClose" />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

@mixin notification-theme($bg-color, $text-color, $border-color) {
  color: $text-color;
  background-color: color-mix(in oklab, $bg-color 90%, black);
  border: 1px solid $border-color;
  border-left: 10px solid $border-color;
}

.notification-bar {
  position: relative;

  &--holder {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 10px;
    pointer-events: all;
    border-radius: $border-radius;
    font-size: var(--tls-font-size-xs);

    &.margin-bottom {
      margin-bottom: $margin-bottom;
    }

    // Themes using mixin
    &.primary {
      @include notification-theme(var(--tls-primary-color), var(--tls-primary-text), var(--tls-primary-900));
    }

    &.secondary {
      @include notification-theme(var(--tls-secondary-color), var(--tls-secondary-text), var(--tls-secondary-900));
    }

    &.success {
      @include notification-theme(var(--tls-success-color), var(--tls-success-text), var(--tls-success-hover));
    }

    &.danger {
      @include notification-theme(var(--tls-error-color), var(--tls-error-text), var(--tls-error-hover));
    }

    &.info {
      @include notification-theme(var(--tls-info-color), var(--tls-info-text), var(--tls-info-hover));
    }

    &.warning {
      @include notification-theme(var(--tls-warning-color), var(--tls-warning-text), var(--tls-warning-hover));
    }

    &.light {
      @include notification-theme(var(--tls-gray-100), var(--tls-gray-600), var(--tls-gray-400));
    }

    &.dark {
      @include notification-theme(var(--tls-gray-600), var(--tls-gray-100), var(--tls-gray-700));
    }
  }

  &--shadow {
    box-shadow: $box-shadow;
  }

  &--icon {
    min-width: 30px;
    height: 30px;
    color: inherit;
  }

  &--content {
    color: inherit;
    width: 100%;
  }

  &--close {
    align-self: flex-start;
    margin-left: auto;
    color: inherit;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: inherit;
      cursor: pointer;
      user-select: none;
    }
  }
}

// Transitions
.fade-enter-active {
  animation: fadeIn 0.3s ease-out forwards;
}

.fade-leave-active {
  animation: fadeOut 0.3s ease-out forwards;
}
</style>
