<script lang="ts" setup>
import router from '@/router';
import { ButtonVariant, SubNavItemPosition } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';


// Define Interface
export interface SubNavItem {
  to?: string;
  title: string;
  active?: boolean;
  viewPermission?: boolean;
  hidden?: boolean;
  iconName?: IconName;
  iconStyle?: IconStyle;
  iconPosition?: IconPosition,
  onclick?: Function;
  itemPosition?: SubNavItemPosition;
  variant?: ButtonVariant;
  isOutlineBtn?: boolean;
}

// Define Props
defineProps<{
  heading?: string;
  headingLevel: '1' | '2' | '3' | '4' | '5' | '6';
  subNavItems: SubNavItem[];
  isLoading: boolean;
}>();


/**
 * Handles the click event for a sub navigation item.
 *
 * @param item The sub navigation item that was clicked.
 */
function handleClick(item: SubNavItem): void {
  // Check if the item has a route to navigate to and is not currently active
  if (item.to && !item.active) {
    // Navigate to the specified route
    router.push(item.to);
  }
  // Check if the item has a custom onclick function
  if (item.onclick) {
    // Call the custom onclick function
    item.onclick();
  }
}
</script>

<template>
  <ContainerCard class="subnav">
    <Loading v-if="isLoading" />

    <template v-else>
      <template v-if="heading">
        <Heading :has-bottom-margin="true" :level="headingLevel">
          {{ heading }}
        </Heading>
      </template>

      <nav class="subnav__list">
        <div class="subnav__group">
          <ButtonComponent v-for="item in subNavItems.filter(i => (i.itemPosition ?? SubNavItemPosition.Left) !== SubNavItemPosition.Right)"
                           :key="item.title + item.active"
                           :disabled="!item.viewPermission"
                           :hidden="item.hidden"
                           :is-active-btn="item.active"
                           :is-outline-btn="item.isOutlineBtn ?? true"
                           :icon-name="item.iconName"
                           :is-icon-btn="item.title === ''"
                           :icon-style="item.iconStyle ?? IconStyle.Outline"
                           :icon-position="item.iconPosition ?? IconPosition.Left"
                           class="subnav__item"
                           :variant="item.variant ?? ButtonVariant.Dark"
                           @click="handleClick(item)">
            {{ item.title }}
          </ButtonComponent>
        </div>

        <div class="subnav__group subnav__group--right">
          <ButtonComponent v-for="item in subNavItems.filter(i => (i.itemPosition ?? SubNavItemPosition.Left) === SubNavItemPosition.Right)"
                           :key="item.title + item.active"
                           :disabled="!item.viewPermission"
                           :hidden="item.hidden"
                           :is-active-btn="item.active"
                           :is-outline-btn="item.isOutlineBtn ?? true"
                           :icon-name="item.iconName"
                           :is-icon-btn="item.title === ''"
                           :icon-style="item.iconStyle ?? IconStyle.Outline"
                           :icon-position="item.iconPosition ?? IconPosition.Left"
                           class="subnav__item"
                           :variant="item.variant ?? ButtonVariant.Dark"
                           @click="handleClick(item)">
            {{ item.title }}
          </ButtonComponent>
        </div>
      </nav>
    </template>
  </ContainerCard>
</template>


<style lang="scss" scoped>
@use '@scss/variables' as *;

.subnav {
  &__list {
    display: flex;
    align-items: center;
    gap: $gap-default;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 0;

    @media (min-width: $breakpoint-lg) {
      overflow: hidden;
      scroll-snap-type: none;
    }

    & button[disabled] {
      position: unset;
      pointer-events: none;
      cursor: not-allowed;

      &::before,
      &::after {
        all: unset;
      }
    }
  }

  &__group {
    display: flex;
    gap: $gap-default;

    &--right {
      margin-left: auto;
    }
  }

  &__item {
    width: min-content;
    white-space: nowrap;
    scroll-snap-align: start;
  }
}
</style>
