<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { BreadcrumbTitles, ButtonSize, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import TableComponent from '@components/table/TableComponent.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';

const enum TableHeaders {
  Name = 'Name',
  Edit = 'Edit',
  Delete = 'Delete',
}

interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

// Stores
const applicationStore = useApplicationStore();

const clientList = ref<client.ListClientsResponse | null>(null);
const clientName = ref('');
const showDeleteClientModel = ref(false);
const deleteClientModelFunc = ref<Function>(() => deleteClient);
const deleting = ref(false);

const isLoading = ref<boolean>(true);

const showNew = applicationStore.canUser(client.Entitlements.CREATE_CLIENT, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CLIENT, applicationStore.activeOrganisation!);
const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CLIENT, applicationStore.activeOrganisation!);

/**
 * Retrieves a list of projects for the active organisation.
 *
 * @returns {Promise<client.ListProjectsResponse | null>} A promise that resolves to the project list response object or null on error.
 */
async function getClientList(): Promise<client.ListClientsResponse> {
  isLoading.value = true;

  try {
    const res = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });

    if (!res || !res.data) {
      throw new Error("Invalid response structure from listClients.");
    }

    return res;
  } catch (error) {
    console.error("Error fetching client list:", error);
    throw error; // Propagate the error to the caller
  } finally {
    isLoading.value = false;
  }
}


// Get the client list for the table
async function getClientListForTable(): Promise<client.Client[]> {
  const res = await getClientList();
  return res.data || []; // Extract and return the data array
}


onMounted(async () => {
  clientList.value = await getClientList();
});


async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteClientModel.value = false;
  deleting.value = false;
}

async function showDeleteConfirmModal(row: client.Client): Promise<void> {
  showDeleteClientModel.value = true;
  clientName.value = row.name;

  deleteClientModelFunc.value = async (): Promise<void> => {
    await deleteClient(row);
  };
}

async function deleteClient(row: client.Client): Promise<void> {
  deleting.value = true;
  try {
    await client.deleteClientById({ clientId: row.id });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
      return;
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    return;
  } finally {
    deleting.value = false;
  }

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted client.',
    autoCloseMs: 3000,
  });

  // Refresh the table
  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}


// Table
const tableRef = ref<DirectoryTableType | null>(null);
const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.Name,
    dataField: 'name',
    headerClassName: 'text--white-space-nowrap',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Edit,
    dataField: 'edit',
    columnWidth: '1%',
  },
  {
    labelText: TableHeaders.Delete,
    dataField: 'delete',
    columnWidth: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'name',
    sort: 'asc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllClients, active: true },
];
</script>

<template>
  <SubHeader :heading="BreadcrumbTitles.AllClients"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="showNew"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Primary"
                       :to="{ name: PageNames.ClientCreate }"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add Client
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <TableComponent ref="tableRef"
                          :columns="table.columns"
                          :retrieve-data="getClientListForTable"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No clients found"
                          strap-line="Add a new client"
                          :button-variant="ButtonVariant.Dark"
                          button-text="Add Client"
                          :icon-name="IconName.UserGroupIcon"
                          :icon-style="IconStyle.Outline"
                          :to="{ name: PageNames.ClientCreate }" />
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.labelText === TableHeaders.Edit">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="showUpdate"
                                   :to="{ name: PageNames.ClientSettings, params: { id: row.id } }"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Dark">
                    Edit
                  </ButtonComponent>
                </div>
              </template>
              <template v-if="column.labelText === TableHeaders.Delete">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="showDelete"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Danger"
                                   @click="showDeleteConfirmModal(row)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TableComponent>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete Client Modal -->
  <ModalComponent :visible="showDeleteClientModel"
                  heading-title="Delete Client"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--semibold">{{ clientName }}</span> client?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Button"
                       :is-block-btn="true"
                       :loading="deleting"
                       :disabled="deleting"
                       :variant="ButtonVariant.Danger"
                       @click="deleteClientModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
