<template>
  <div class="loading-bar__container">
    <div class="loading-bar" />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.loading-bar {
  height: 100%;
  background-color: var(--tls-gray-400);
  position: absolute;
  animation: indeterminate 2s linear infinite;
  border-radius: 2px;

  &__container {
    width: 100%;
    height: 6px;
    background-color: var(--tls-gray-200);
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
  }
}

@keyframes indeterminate {
  0% {
    inset-inline-start: -70%;
    inset-inline-end: 100%;
  }
  50% {
    inset-inline-start: 30%;
    inset-inline-end: -30%;
  }
  100% {
    inset-inline-start: 100%;
    inset-inline-end: -30%;
  }
}
</style>
