<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { NotificationChannelType, NotificationFrequency, Resources_View, ViewCriteria } from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';
import { channelOptions, daysOfTheWeek, hoursOfTheDay, statusOptions } from '@components/notification/options';

const { viewId } = defineProps<{ viewId: string }>()
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const isLoading = ref<boolean>(true);
const criteria = ref<ViewCriteria>({
  hour: 0,
  days: { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false }
});
type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';


const { handleSubmit, setErrors, resetForm } = useForm({
  validationSchema: yup.object({
    channel: yup.object({
      value: yup
        .string()
        .required('Destination is required')
    }),
  }),
});
const { value: statusValue } = useField<boolean>('status');
const { value: notificationChannelTypeValue } = useField<NotificationChannelType>('channel.type');
const { value: notificationChannelDestinationValue } = useField<string>('channel.value');

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  try {
    await client.createOrUpdateNotification({ requestBody: {
        resource: client.Resources_View.VIEW,
        resourceId: viewId,
        type: client.NotificationType_VIEW_NO_IMAGE_BY_TIME.VIEW_NO_IMAGE_BY_TIME,
        channel: values['channel'],
        frequency: NotificationFrequency.ONCE_PER_DAY,
        active: values['status'],
        criteria: criteria.value
      }
    });
    isSubmitting.value = false;
    applicationStore.publishSuccessNotification({
      text: 'Saved',
      autoCloseMs: 3000,
    });
  } catch (error: unknown) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)
    if (errorFields) {
      setErrors(errorFields);
    }
    applicationStore.publishErrorNotification({
      text: `Error saving: ${errorMessage}`,
    });
    return;
  } finally {
    isSubmitting.value = false;
  }
});

const onTest = handleSubmit(async (values) => {
  isSubmitting.value = true;
  try {
    await client.createOrUpdateNotification({ requestBody: {
        resource: client.Resources_View.VIEW,
        resourceId: viewId,
        type: client.NotificationType_VIEW_NO_IMAGE_BY_TIME.VIEW_NO_IMAGE_BY_TIME,
        channel: values['channel'],
        frequency: NotificationFrequency.ONCE_PER_DAY,
        active: values['status'],
        criteria: criteria.value
      }
    });

    await client.testNotificationByResourceAndType({
      notificationType: client.NotificationType.VIEW_NO_IMAGE_BY_TIME,
      resource: Resources_View.VIEW,
      resourceId: viewId,
    });

    isSubmitting.value = false;
    applicationStore.publishSuccessNotification({
      text: 'Saved - A test notification has been sent to your Channel & Destination',
      autoCloseMs: 3000,
    });
  } catch (error: unknown) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)
    if (errorFields) {
      setErrors(errorFields);
    }
    applicationStore.publishErrorNotification({
      text: `Error saving: ${errorMessage}`,
    });
    return;
  } finally {
    isSubmitting.value = false;
  }
});

async function fetchNotifications(): Promise<void> {
  try {
    let response;
    try {
      response = await client.getNotificationByResourceAndType({
        resource: client.Resources_View.VIEW,
        resourceId: viewId,
        notificationType: client.NotificationType.VIEW_NO_IMAGE_BY_TIME,
      });
    } catch (error) {
      if (error instanceof client.ApiError) {
        if (error.status !== 404) {
          throw error;
        }
      }
    }

    resetForm({
      values: {
        status: response?.active ?? false,
        channel: {
          type: response?.channel.type ?? NotificationChannelType.EMAIL,
          value: response?.channel.value ?? '',
        },
      }
    })

    if (response && response.criteria) criteria.value = response.criteria as client.ViewCriteria;

  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  await fetchNotifications();
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />
    <section v-else>
      <form @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              First Image Not Received
            </Heading>
            <p>
              Receive a notification when your View hasn't received an image before a cutoff time.
            </p>
          </div>
          <div class="fields">
            <div class="row-half">
              <div class="field">
                <label for="status">Status
                </label>
                <v-select id="status"
                          v-model="statusValue"
                          :clearable="false"
                          :searchable="false"
                          :reduce="(value: any) => value.value"
                          :options="statusOptions"
                          autocomplete="off" />
                <ErrorMessage name="status" class="message message-error" as="p" />
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="status">No image received before
                </label>
                <v-select id="status"
                          v-model="criteria.hour"
                          :clearable="false"
                          :searchable="false"
                          :reduce="(value: any) => value.value"
                          :options="hoursOfTheDay"
                          autocomplete="off" />
                <ErrorMessage name="status" class="message message-error" as="p" />
              </div>

              <div class="field">
                <label for="status">On</label>
                <br>
                <div v-for="days in daysOfTheWeek" :key="days.value">
                  <input :id="`day_${days.value}`" v-model="criteria.days[days.value as Day]" type="checkbox">
                  &nbsp;{{ days.label }}
                </div>
                <ErrorMessage name="status" class="message message-error" as="p" />
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="channel">Notification Channel
                </label>
                <v-select id="channel"
                          v-model="notificationChannelTypeValue"
                          :clearable="false"
                          :searchable="false"
                          :reduce="(value: any) => value.value"
                          :options="channelOptions"
                          autocomplete="off" />
                <ErrorMessage name="channel.type" class="message message-error" as="p" />
              </div>

              <div class="field">
                <label for="destination">
                  Destination
                </label>
                <input id="destination"
                       v-model="notificationChannelDestinationValue"
                       type="text">
                <ErrorMessage name="channel.value" class="message message-error" as="p" />
              </div>
            </div>
          </div>
        </div>

        <ButtonContainer>
          <ButtonComponent :variant="ButtonVariant.Info"
                           :loading="isSubmitting"
                           :is-block-btn="true"
                           :type="ButtonType.Button"
                           @click="() => onTest()">
            Save & Test
          </ButtonComponent>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :loading="isSubmitting"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Save
          </ButtonComponent>
        </ButtonContainer>
      </form>
    </section>
  </ContainerCard>
</template>
