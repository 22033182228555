<script lang="ts" setup>
import { reactive, Ref, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { storeToRefs } from 'pinia';
import {
  BadgeVariant, BreadcrumbPaths,
  BreadcrumbTitles,
  ButtonSize,
  ButtonVariant,
  CameraStatus,
  PageNames,
} from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import EmptyState from '@layouts/EmptyState.vue';
import Forbidden from '@layouts/Forbidden.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import TableComponent from '@components/table/TableComponent.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';


enum TableHeaders {
  DeviceId = 'Device ID',
  SystemType = 'System Type',
  Status = 'Status',
  Edit = 'Edit',
  Delete = 'Delete',
}

const tableKey = ref(0);  // Reactive key for forcing table reloads because of <Suspense>

const showDeleteCameraModel = ref(false);
const cameraToDelete = ref('') as Ref<string>;
const deleteCameraModelFunc = ref<Function>(() => deleteCamera);
const isLoading = ref<boolean>(false);

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);

const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CAMERA, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CAMERA, applicationStore.activeOrganisation!);
const showRegister = applicationStore.canUser(client.Entitlements.REGISTER_CAMERA, applicationStore.activeOrganisation!);

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteCameraModel.value = false;
}

async function showDeleteConfirmModal(row: client.Camera, redraw: Function): Promise<void> {
  cameraToDelete.value = row.serialNumber;
  showDeleteCameraModel.value = true;
  deleteCameraModelFunc.value = async (): Promise<void> => {
    await deleteCamera(row);
    await redraw();
  };
}

async function fetchCameraLists(): Promise<client.Camera[]> {
  isLoading.value = true;

  try {
    // fetch all unregistered cameras
    const res = await client.listUnregisteredCameras();
    return res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
  finally {
    isLoading.value = false;
  }
}

async function deleteCamera(row: client.Camera): Promise<void> {
  await client.deleteCameraById({ cameraId: row.id });

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted camera.',
    autoCloseMs: 3000,
  });

  // Refresh the camera list
  await reloadTable();
}

const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.DeviceId,
    dataField: 'serialNumber',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '97%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Status,
    dataField: 'status',
    headerClassName: 'status',
    columnWidth: '1%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Edit,
    dataField: 'edit',
    headerClassName: 'status',
    columnWidth: '1%',
  }
];

// Insert SystemType and Delete if isAdmin or showDelete
if (adminMode || showDelete) {
  columns.splice(1, 0, {
    labelText: TableHeaders.SystemType,
    dataField: 'systemType',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
  });
  columns.push({
    labelText: TableHeaders.Delete,
    dataField: 'delete',
    headerClassName: 'status',
    columnWidth: '1%',
  });
}

const table = reactive({
  columns: columns,
  sortable: {
    order: 'serialNumber',
    sort: 'asc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllCameras, to: BreadcrumbPaths.AllCameras },
  { title: BreadcrumbTitles.UnregisteredCameras, active: true,}
];

// Hack to reload the table because we have to use <Suspense>
async function reloadTable(): Promise<void> {
  tableKey.value++;
}
</script>

<template>
  <template v-if="adminMode">
    <SubHeader heading="Unregistered Cameras"
               level="2">
      <template #buttons>
        <template v-if="isLoading" />
        <template v-else>
          <ButtonComponent v-if="adminMode"
                           :is-block-btn="true"
                           :to="{ name: PageNames.CameraNew }"
                           :variant="ButtonVariant.Primary"
                           :icon-position="IconPosition.Left"
                           :icon-name="IconName.PlusIcon"
                           :icon-style="IconStyle.Solid">
            Create Camera
          </ButtonComponent>
          <ButtonComponent v-if="showRegister"
                           :is-block-btn="true"
                           :to="{ name: PageNames.CameraRegister }"
                           :variant="ButtonVariant.Primary"
                           :icon-position="IconPosition.Left"
                           :icon-name="IconName.PlusIcon"
                           :icon-style="IconStyle.Solid">
            Register Camera
          </ButtonComponent>
        </template>
      </template>
    </SubHeader>

    <Breadcrumb :is-sticky="true"
                :items="breadcrumbs" />

    <ContainerCard>
      <Suspense>
        <template #default>
          <section>
            <TableComponent :key="tableKey"
                            :retrieve-data="fetchCameraLists"
                            :columns="table.columns"
                            :sortable="table.sortable">
              <template #table-empty>
                <EmptyState heading-text="No cameras found"
                            strap-line="Get started by registering your first camera"
                            :button-variant="ButtonVariant.Dark"
                            button-text="Register Camera"
                            :icon-name="IconName.CameraIcon"
                            :icon-style="IconStyle.Outline"
                            :to="{ name: PageNames.CameraRegister }" />
              </template>

              <template #cell="{ row, column }">
                <template v-if="column.labelText === TableHeaders.Status">
                  <div class="d-flex justify-center">
                    <BadgeComponent v-if="row.status === CameraStatus.Registered"
                                    :is-pill="true"
                                    :variant="BadgeVariant.Success">
                      Registered
                    </BadgeComponent>
                    <BadgeComponent v-if="row.status === CameraStatus.Unregistered"
                                    :is-pill="true"
                                    :variant="BadgeVariant.Danger">
                      Unregistered
                    </BadgeComponent>
                  </div>
                </template>

                <template v-if="column.labelText === TableHeaders.Edit">
                  <div class="d-flex gap-20">
                    <ButtonComponent :to="{ name: PageNames.CameraUnregisteredEdit, params: { id: row.id } }"
                                     :size="ButtonSize.Small"
                                     :variant="ButtonVariant.Dark">
                      Edit
                    </ButtonComponent>
                  </div>
                </template>

                <template v-if="column.labelText === TableHeaders.Delete && (showDelete || showUpdate)">
                  <div class="d-flex gap-20">
                    <ButtonComponent :size="ButtonSize.Small"
                                     :variant="ButtonVariant.Danger"
                                     @click="showDeleteConfirmModal(row, fetchCameraLists)">
                      Delete
                    </ButtonComponent>
                  </div>
                </template>
              </template>
            </TableComponent>
          </section>
        </template>

        <template #fallback>
          <Loading />
        </template>
      </Suspense>
    </ContainerCard>

    <!-- Delete Camera Modal -->
    <ModalComponent :visible="showDeleteCameraModel"
                    :heading-title="`Delete Camera ${cameraToDelete}`"
                    @on-close="closeDeleteConfirmModal">
      <template #modal-content>
        <p>
          Are you sure you want to delete the camera: <span class="text--semibold">{{ cameraToDelete }}</span>
        </p>
      </template>
      <template #modal-footer>
        <ButtonComponent :is-outline-btn="true"
                         :is-block-btn="true"
                         :variant="ButtonVariant.Dark"
                         @click="closeDeleteConfirmModal">
          Cancel
        </ButtonComponent>
        <ButtonComponent :is-block-btn="true"
                         :variant="ButtonVariant.Danger"
                         @click="deleteCameraModelFunc()">
          Delete
        </ButtonComponent>
      </template>
    </ModalComponent>
  </template>

  <template v-else>
    <ContainerCard>
      <Forbidden heading-text="Unauthorised Access">
        <p>You do not have permission to access this page</p>
      </Forbidden>
    </ContainerCard>
  </template>
</template>
