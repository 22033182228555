<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { ButtonSize, ButtonVariant, SkeletonVariant } from '@viewModels/enums';
import { dateTimeFormat } from '@utils/date';
import CameraDebugItem from '@components/camera/CameraDebugItem.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SkeletonLoading from '@components/SkeletonLoading.vue';
import { ChartBarIcon, ClockIcon, BuildingOfficeIcon, PhotoIcon } from '@heroicons/vue/24/outline';
import CameraRecentLogActivity from '@components/camera/CameraRecentLogActivity.vue';
import BatteryPanel from '@components/view_dashboard/BatteryPanel.vue';
import ButtonComponent from '@components/ButtonComponent.vue';

interface CameraDetail {
  label: string;
  value?: string | undefined;
  copyable?: boolean;
  format?: (value: string) => string;
  link?: string;
}

// Stores
const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const route = useRoute();

// ** System Type **
const GABRIELCAMV1 = computed(() => currentCamera.value?.systemType === client.SupportedSystems.GABRIELCAMV1);

// Camera Information
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();

// View and Linked View Information
const linkedView = ref<client.View | null | undefined>();
const linkedProject = ref<client.Project | null | undefined>();
const linkedClient = ref<client.Client | null | undefined>();
const linkedCameraStats = ref<client.CameraStat | null | undefined>();

// Loading States
const isLoadingCameraDetails = ref<boolean>(false);
const isLoadingCamera = ref<boolean>(true);
// Combined Loading State
const isLoading = computed(() => isLoadingCamera.value || isLoadingCameraDetails.value);

/**
 * Computed values
 */
// Format the date of the last captured image
const lastImageCapturedFormatted = computed(() => {
  const lastCapturedUtc = linkedView.value?.lastCapturedUtc;
  return lastCapturedUtc
    ? dateTimeFormat().format(new Date(lastCapturedUtc))
    : 'No data';
});

// Get the client and project name
const clientProject = computed(() => {
  if (!linkedClient.value?.name) {
    return "No Client / Project found";
  }

  return linkedProject.value?.name
    ? `${linkedClient.value.name} / ${linkedProject.value.name}`
    : linkedClient.value.name;
});

// Last Updated Time from Stats
const getCameraUpdatedAt = computed(() => {
  const cameraUpdatedAt = linkedCameraStats.value?.updatedAt;
  return cameraUpdatedAt
    ? dateTimeFormat().format(new Date(cameraUpdatedAt))
    : 'Not available';
});

// ViewName
const viewName = computed(() => {
  if(linkedView.value?.name) return linkedView.value.name;
  return 'No view found';
});


// Get Specific View, Project, Client and Camera Stats Information for this Camera
async function getCameraDetails(): Promise<void> {
  isLoadingCameraDetails.value = true;

  try {
    const viewsResult = await client.listViews({
      organisation: applicationStore.activeOrganisation!.id,
    });

    if (!viewsResult.data || viewsResult.data.length === 0) {
      return;
    }

    const view = viewsResult.data.find((v) => v.camera === cameraId);
    if (!view) {
      return;
    }

    linkedView.value = view;

    // Fetch project, client, and stats in parallel, handling missing IDs
    const [projectResult, clientResult, statsResult] = await Promise.allSettled([
      view.project ? client.getProjectById({ projectId: view.project }) : Promise.resolve(null),
      view.client ? client.getClientById({ clientId: view.client }) : Promise.resolve(null),
      client.getCameraByIdStatLatest({ cameraId }),
    ]);

    linkedProject.value = projectResult.status === "fulfilled" ? projectResult.value : null;
    linkedClient.value = clientResult.status === "fulfilled" ? clientResult.value : null;
    linkedCameraStats.value = statsResult.status === "fulfilled" ? statsResult.value : null;

    if (statsResult.status === "rejected") {
      console.error("Failed to fetch camera stats:", statsResult.reason);
    }

  } catch (error) {
    console.error("Error fetching views:", error);
  } finally {
    isLoadingCameraDetails.value = false;
  }
}

onMounted(async () => {
  isLoadingCamera.value = true;
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
    await getCameraDetails();
  } catch (error) {
    console.error("Error fetching camera or view data:", error);
  } finally {
    isLoadingCamera.value = false;
  }
});

// Camera Details compromising of linked view, project, client
const cameraDetails = computed<CameraDetail[]>(() => [
  { label: 'Serial Number', value: currentCamera.value?.serialNumber, copyable: true },
  { label: 'ID', value: currentCamera.value?.id, copyable: true },
  { label: 'View', value: linkedView.value?.name, link: `/views/${linkedView.value?.id}`, copyable: false },
  { label: 'View ID', value: linkedView.value?.id, copyable: true },
  { label: 'Project', value: linkedProject.value?.name, copyable: true },
  { label: 'Project ID', value: linkedProject.value?.id, copyable: true },
  { label: 'Client', value: linkedClient.value?.name, copyable: true },
  { label: 'Client ID', value: linkedClient.value?.id, copyable: true },
  { label: 'System Type', value: currentCamera.value?.systemType, copyable: true },
  { label: 'Status', value: currentCamera.value?.status, copyable: false, variant: 'status'},
  {
    label: 'Created At',
    value: currentCamera.value?.createdAt,
    format: (value: string | undefined): string =>
      value ? dateTimeFormat().format(new Date(value)) : 'Not available',
    copyable: true,
  },
  { label: 'Modem IMEI', value: currentCamera.value?.meta?.['wpImei'] as string | undefined, copyable: true },
  { label: 'Modem Serial Number', value: currentCamera.value?.meta?.['wpSerialNumber'] as string | undefined, copyable: true },
  { label: 'Balena UUID', value: currentCamera.value?.meta?.['balenaUuid'] as string | undefined, copyable: true },
]);

</script>

<template>
  <div class="overview overview__small-tile">
    <SkeletonLoading v-if="isLoading"
                     :variant="SkeletonVariant.InfoTile"
                     :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <ClockIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Last Updated
          </Heading>
          <div class="info-tile__count">
            {{ getCameraUpdatedAt }}
          </div>
        </div>
      </div>
    </ContainerCard>

    <SkeletonLoading v-if="isLoading"
                     :variant="SkeletonVariant.InfoTile"
                     :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <ChartBarIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Total Images Captured
          </Heading>
          <div class="info-tile__count">
            {{ linkedCameraStats?.imagesTaken?.toLocaleString() ?? '0' }}
          </div>
        </div>
      </div>
    </ContainerCard>

    <SkeletonLoading v-if="isLoading" :variant="SkeletonVariant.InfoTile" :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <PhotoIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            View
          </Heading>
          <div v-if="linkedView?.name" class="info-tile__button">
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :size="ButtonSize.Small"
                             :to="`/admin/views/${linkedView?.id}`">
              {{ viewName }}
            </ButtonComponent>
          </div>
          <div v-else class="info-tile__count">
            {{ viewName }}
          </div>
        </div>
      </div>
    </Containercard>

    <SkeletonLoading v-if="isLoading"
                     :variant="SkeletonVariant.InfoTile"
                     :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <ClockIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Uptime
          </Heading>
          <div class="info-tile__count">
            {{ linkedCameraStats?.upTime || "No data" }}
          </div>
        </div>
      </div>
    </ContainerCard>

    <SkeletonLoading v-if="isLoading"
                     :variant="SkeletonVariant.InfoTile"
                     :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <ClockIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Last Image Captured
          </Heading>
          <div class="info-tile__count">
            {{ lastImageCapturedFormatted }}
          </div>
        </div>
      </div>
    </ContainerCard>

    <SkeletonLoading v-if="isLoading"
                     :variant="SkeletonVariant.InfoTile"
                     :line-count="1" />
    <ContainerCard v-else :margin-bottom="false" class="info-tile">
      <div class="info-tile__content">
        <BuildingOfficeIcon class="info-tile__icon" />
        <div class="info-tile__details">
          <Heading level="4">
            Client / Project
          </Heading>
          <div class="info-tile__count">
            {{ clientProject }}
          </div>
        </div>
      </div>
    </Containercard>
  </div>

  <div class="overview overview__large-tile">
    <!-- GABRIELCAMV1 Only Information -->
    <ContainerCard v-if="GABRIELCAMV1 && linkedView" :margin-bottom="false">
      <BatteryPanel :view-id="linkedView?.id" />
    </Containercard>

    <ContainerCard :margin-bottom="false">
      <Heading level="4" :has-bottom-margin="true">
        Recent logs
      </Heading>
      <template v-if="currentCamera">
        <CameraRecentLogActivity :camera-id="currentCamera?.id" :is-loading="isLoading && !currentCamera" />
      </template>
    </Containercard>
  </div>

  <!-- Admin Panel -->
  <template v-if="adminMode">
    <CameraDebugItem :camera-details="cameraDetails" :is-loading="isLoading && !linkedView" />
  </template>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

$container-width: 240px;

.overview {
  display: grid;
  gap: $gap-desktop;
  margin-bottom: $margin-bottom;

  &__small-tile {
    grid-template-columns: repeat(auto-fit, minmax($container-width, 1fr));
  }

  &__large-tile {
    grid-template-columns: repeat(auto-fit, minmax(clamp($container-width, 50vw, $container-width * 2), 1fr));
  }
}

.info-tile {
  &__content {
    display: flex;
    gap: $gap-default;
  }

  &__icon {
    width: 1.5rem;
    min-width: 1.5rem;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: $gap-mobile;

    @media (min-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__count {
    font-size: var(--tls-font-size-xs);
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    align-items: center;
  }
}
</style>
