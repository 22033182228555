import * as client from '@gabrielcam/api-client';

/**
 * Extracts a meaningful error message from a given error object.
 *
 * If the error is a `client.ApiError`, it attempts to extract the `message` property from the error body.
 * If none of these apply, it falls back to a generic error message.
 *
 * @param error The error object to extract the message from.
 * @returns A meaningful error message.
 */
export function extractErrorMessage(error: unknown): string {
  // Handle `client.ApiError`
  if (error instanceof client.ApiError) {
    console.debug('Extracted ApiError:', error);

    const errorBody = error.body as {
      error?: {
        code?: string;
        message?: string;
      };
    };

    // Extract API-specific error message
    return errorBody?.error?.message || 'An unexpected error occurred.';
  }

  // Handle general `Error` objects
  if (error instanceof Error) {
    console.debug('Extracted generic Error:', error);

    // Return generic error message
    return error.message || 'An unknown error occurred. Please try again.';
  }

  // Fallback for non-Error type errors
  console.debug('Fallback for unknown error type:', error);
  return 'An unknown error occurred.';
}

export const extractErrorFields = (error: unknown): Record<string, string[]> | undefined => {
    if (error instanceof client.ApiError) {
        const errorBody = error.body as {
            error?: {
                errors?: Record<string, string[]>;
            };
        };
        if (errorBody?.error?.errors) {
            return errorBody.error.errors;
        }
    }
    return;
}


