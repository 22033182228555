<script setup lang="ts">
import { ref, watch } from 'vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ArrowsUpDownIcon } from '@heroicons/vue/24/solid';
import { vOnClickOutside } from '@vueuse/components';

const emit = defineEmits<{
  (e: 'sort-view-by', sortBy: string, sortOrder: string): void;
}>();

const filterDropdownOpen = ref<boolean>(false);
const sortDropdownOpen = ref<boolean>(false);
const selectedSortBy = ref<string>('createdAt');
const selectedSortOrder = ref<string>('-');

function toggleSortDropdown(): void {
  filterDropdownOpen.value = false;
  sortDropdownOpen.value = !sortDropdownOpen.value;
}

const sortOptions = [
  { value: 'name', displayValue: 'Name' },
  { value: 'createdAt', displayValue: 'Created Date' },
];
const sortOrderOptions = [
  { value: '-', displayValue: 'A-Z or Low to High' },
  { value: '', displayValue: 'Z-A or High to Low' },
];

function updateSort(sortBy: string, sortOrder: string): void {
  emit('sort-view-by', sortBy, sortOrder);
}

// Watch for changes in selectedSortBy and selectedSortOrder
watch([selectedSortBy, selectedSortOrder], ([newSortBy, newSortOrder]) => {
  updateSort(newSortBy, newSortOrder);
});
</script>

<template>
  <div class="videos-toolbar">
    <div v-on-click-outside="() => sortDropdownOpen = false"
         class="view-sort-dropdown">
      <ButtonComponent :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronDownIcon"
                       :variant="ButtonVariant.Dark"
                       class="view-sort-dropdown--btn"
                       :class="{ 'view-sort-dropdown--open': sortDropdownOpen }"
                       @click="toggleSortDropdown">
        <span class="view-sort-dropdown--text">Sort</span>
        <ArrowsUpDownIcon class="view-sort-dropdown--icon" />
      </ButtonComponent>

      <div class="view-sort-dropdown--content">
        <span>
          Sort on
        </span>
        <v-select v-model="selectedSortBy"
                  input-id="order-on"
                  aria-label="Order On"
                  placeholder="Name"
                  label="displayValue"
                  :reduce="(value: any) => value.value"
                  :options="sortOptions"
                  :clearable="false" />
        <span>
          Sort by
        </span>
        <v-select v-model="selectedSortOrder"
                  input-id="order-by"
                  aria-label="Order By"
                  placeholder="A-Z or Low to High"
                  label="displayValue"
                  :reduce="(value: any) => value.value"
                  :options="sortOrderOptions"
                  :clearable="false" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.videos-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-mobile;
  align-items: flex-start;
  margin-bottom: $gap-default * 2;

  @media screen and (max-width: $breakpoint-lg) {
    gap: calc($gap-mobile - 10px);
    justify-content: space-between;
  }

  &--break {
    @media screen and (max-width: $breakpoint-lg) {
      flex-basis: 100%;
    }
  }
}

.view-sort-dropdown {
  @media screen and (min-width: $breakpoint-lg) {
    position: relative;
  }

  &--btn {
    @media screen and (max-width: $breakpoint-lg) {
      padding: 10px 6px!important;
    }
  }

  &--btn .btn__icon {
    transition: transform 0.3s ease-in-out;
  }

  &--btn.view-sort-dropdown--open .btn__icon {
    transform: rotate(180deg);
  }

  @media screen and (max-width: $breakpoint-lg) {
    &--text {
      display: none;
    }
  }

  &--icon {
    width: 1.5rem;
    height: 1.5rem;

    @media screen and (width > $breakpoint-lg) {
      display: none;
    }
  }

  &--content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: $gap-mobile;
    align-items: center;
    position: absolute;
    left: 0;
    z-index: 2;
    width: calc(100% - 30px);
    padding: 15px;
    margin: 10px 0 0 15px;
    visibility: hidden;
    background: var(--tls-gray-100);
    border: 1px solid var(--tls-gray-400);
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    opacity: 0;
    transition: opacity 0.15s ease,
    transform 0.15s ease,
    visibility 0.15s;
    transform: translateY(-10px);

    @media screen and (min-width: $breakpoint-lg) {
      left: -15px;
      min-width: 400px;
      padding: 30px;
    }
  }

  &--open {
    + .view-sort-dropdown--content {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
