<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue';
import { Line } from 'vue-chartjs';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import * as client from '@gabrielcam/api-client';

import { reduceData } from '@utils/dataUtils';
import { useMobileDetection } from '@utils/isMobile';

import Loading from '@components/Loading.vue';

const { isMobile } = useMobileDetection();

const props = defineProps({
  stats: {
    type: Object as PropType<client.CameraStat[]>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  unauthorised: {
    type: Boolean,
    required: false,
  },
});

class MeasurementViewModel {
  x: Date = new Date();
  y = 0;
}

// Reduce the dataset by setting true/false
const useReduceData = ref(false);

const RSNSI = 3;
const inputVoltages = ref<MeasurementViewModel[]>([]);
// const inputCurrents = ref<MeasurementViewModel[]>([]);
// const batteryCurrents = ref<MeasurementViewModel[]>([]);
const ina219ShuntCurrents = ref<MeasurementViewModel[]>([]);
const watts = ref<MeasurementViewModel[]>([]);
const data = ref();


// Get the value of a CSS variable from the DOM
const getCSSVariable = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

// Theme Colors
const themeColors: string[] = [
  getCSSVariable('--tls-primary-color'),
  getCSSVariable('--tls-secondary-color'),
  getCSSVariable('--tls-primary-100'),
  getCSSVariable('--tls-gray-800'),
  getCSSVariable('--tls-gray-300'),
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

// Desktop View Options
const options: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      title: {
        display: true,
        text: 'Time',
        color: themeColors[3],
      },
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
        color: themeColors[3],
      },
      grid: {
        display: true,
        color: themeColors[4],
      },
    },
    y: {
      type: 'linear',
      position: 'right',
      title: {
        display: true,
        text: 'Power (W)',
      },
      ticks: {
        padding: 10,
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            return value.toFixed(1) + 'w';
          }
          return value.toString();
        },
      },
      grid: {
        drawOnChartArea: false,
      },
      display: false,
    },
    y2: {
      type: 'linear',
      position: 'right',
      title: {
        display: true,
        text: 'Current (mA)',
      },
      ticks: {
        padding: 10,
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            return value.toFixed(2) + 'mA';
          }
          return value.toString();
        },
      },
      grid: {
        drawOnChartArea: false,
      },
      display: false,
    },
    y3: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Voltage (V)',
        color: themeColors[3],
      },
      grid: {
        display: true,
        color: themeColors[4],
      },
      ticks: {
        padding: 10,
        color: themeColors[3],
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            return value.toFixed(1) + 'v';
          }
          return value.toString();
        },
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    title: {
      display: true,
      text: 'Power Status',
      position: 'top',
      align: 'center',
      color: themeColors[3],
      font: {
        size: 18,
        family: 'Open Sans, sans-serif',
      },
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        useBorderRadius: true,
        borderRadius: 2,
        padding: 20,
        color: themeColors[3],
        font: {
          size: 14,
          family: 'Open Sans, sans-serif',
        },
      },
    },
  },
};

// Mobile View Options
const mobileOptions: Partial<ChartOptions<'line'>> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
      },
      grid: {
        display: true,
      },
    },
    y: {
      display: false,
    },
    y2: {
      display: false,
    },
    y3: {
      display: false,
    },
  },
};

// Merge Chart Options based on isMobile
const finalOptions = computed<ChartOptions<'line'>>(() => (isMobile.value ? { ...options, ...mobileOptions } : options));

watch(
  () => props.stats,
  () => {
    // Toggle the use of reduceData with a reactive variable
    const applyReduceData = useReduceData.value;

    // Calculate raw data first
    const rawInputVoltages = props.stats.map((stat) => ({
      x: new Date(stat.timestamp),
      y: stat.lt4015InputVoltage * 0.001 * 1.648,
    }));
    inputVoltages.value = applyReduceData ? reduceData(rawInputVoltages) : rawInputVoltages;

    const rawWatts = props.stats.map((stat) => ({
      x: new Date(stat.timestamp),
      y: ((stat.lt4015InputCurrent * (1.46487 / RSNSI)) / 1000) * (stat.lt4015InputVoltage * 0.001 * 1.648),
    }));
    watts.value = applyReduceData ? reduceData(rawWatts) : rawWatts;

    const rawIna219ShuntCurrents = props.stats.map((stat) => ({
      x: new Date(stat.timestamp),
      y: stat.ina219ShuntVoltage / 1000000 / 0.025,
    }));
    ina219ShuntCurrents.value = applyReduceData ? reduceData(rawIna219ShuntCurrents) : rawIna219ShuntCurrents;

    // Update the datasets for the chart
    data.value = {
      datasets: [
        {
          label: 'Voltage (V)',
          backgroundColor: themeColors[0],
          data: inputVoltages.value,
          borderWidth: 2,
          borderColor: themeColors[0],
          xAxisID: 'x',
          yAxisID: 'y3', // Aligned to Voltage scale
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'Current (mA)',
          backgroundColor: themeColors[1],
          data: ina219ShuntCurrents.value,
          borderWidth: 2,
          borderColor: themeColors[1],
          xAxisID: 'x',
          yAxisID: 'y2', // Aligned to Current scale
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'Power (W)',
          backgroundColor: themeColors[2],
          data: watts.value,
          borderWidth: 2,
          borderColor: themeColors[2],
          xAxisID: 'x',
          yAxisID: 'y', // Aligned to Power scale
          cubicInterpolationMode: 'monotone',
        },
      ],
    };
  },
  { immediate: true }
);
</script>

<template>
  <div class="mb-20">
    <Loading v-if="loading" />

    <div v-else-if="unauthorised"
         class="unauthorised-content">
      <b>Power</b>
      <p>Unauthorised</p>
    </div>

    <div v-else>
      <Line id="chart-power"
            :data="data"
            :options="finalOptions"
            :style="{ height: isMobile ? '400px' : '500px' }" />
    </div>
  </div>
</template>
