<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useThemeStore } from '@stores/useThemeStore';
import { useRoute, useRouter } from 'vue-router';
import Loading from '@components/Loading.vue';

const themeStore = useThemeStore();
const route = useRoute();
const router = useRouter();

// Block rendering until the org or client theme is fully applied
const isThemeLoading = computed(() => !themeStore.isThemeReady);

// Initialise the theme
onMounted(() => themeStore.initTheme(route, router));
</script>

<template>
  <Loading v-if="isThemeLoading" />
  <router-view v-else />
  <v-notification />
</template>
