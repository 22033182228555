<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import * as yup from 'yup';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import timezones, { TimeZone } from 'timezones-list';
import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import AccountLayout from '@layouts/AccountLayout.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import router from '@/router';
import { ref } from 'vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';

// Extended the RegisterOrganisationRequest to include confirmPassword
interface RegisterFormValues extends client.RegisterOrganisationRequest {
  confirmPassword: string;
}

const applicationStore = useApplicationStore();
const isPasswordVisible = ref(false);

// Form validation
const { handleSubmit, isSubmitting, meta, setErrors } = useForm<RegisterFormValues>({
  validationSchema: yup.object({
    name: yup
      .string()
      .min(3, 'Organisation Name must be at least 3 characters')
      .max(200, 'Organisation Name must be at most 200 characters')
      .required('Organisation Name is required'),
    forename: yup
      .string()
      .min(2, 'First Name must be at least 2 characters')
      .max(200, 'First Name must be at most 200 characters')
      .required('First Name is required'),
    surname: yup
      .string()
      .min(2, 'Last Name must be at least 2 characters')
      .max(200, 'Last Name must be at most 200 characters')
      .required('Last Name is required'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Email is required'),
    password: yup
      .string()
      .trim()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(200, 'Password must be less than 200 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[!@#$£%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Password is required'),
    timezone: yup
      .string()
      .nullable()
      .notRequired(),
  }),
});

// Attach fields to form
const { value: organisationNameValue, meta: organisationNameMeta } = useField<string>('name', { validateOnValueUpdate: false });
const { value: forenameValue, meta: forenameMeta } = useField<string>('forename', { validateOnValueUpdate: false });
const { value: surnameValue, meta: surnameMeta } = useField<string>('surname', { validateOnValueUpdate: false });
const { value: emailValue, meta: emailMeta } = useField<string>('email', { validateOnValueUpdate: false });
const { value: passwordValue, meta: passwordMeta } = useField<string>('password', { validateOnValueUpdate: false });
const { value: confirmPasswordValue, meta: confirmPasswordMeta } = useField<string>('confirmPassword', { validateOnValueUpdate: true });
const { value: timezoneValue } = useField<string>('timezone', { validateOnValueUpdate: true });

/**
 * Handles the form submission for user registration.
 *
 * @param {RegisterFormValues} values - The form values submitted by the user.
 */
const onSubmit = handleSubmit(async (values: RegisterFormValues) => {
  try {
    // Remove confirmPassword from request body as we don't need it
    const { confirmPassword: _confirmPassword, ...requestBody } = values;

    // If timezone is left empty, remove it from the request
    if (!requestBody.timezone) {
      delete requestBody.timezone;
    }

    await client.registerOrganisation({
      requestBody: requestBody as client.RegisterOrganisationRequest,
    });

    // Redirect to success page
    await router.push({ name: PageNames.RegisterSuccess });

  } catch (error: unknown) { // Use unknown
    // Extract the error message from the error object
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error registering: ${errorMessage}`,
    });

    return;
  }
});

function togglePasswordVisibility(): void {
  isPasswordVisible.value = !isPasswordVisible.value;
}
</script>

<template>
  <AccountLayout>
    <div class="account-form">
      <form @submit.prevent="onSubmit">
        <section class="account-form--content">
          <div class="fields">
            <div class="field">
              <label for="organisationName">Organisation Name</label>
              <input id="organisationName"
                     v-model="organisationNameValue"
                     type="text"
                     autocomplete="organization"
                     :class="{ 
                       'input-error': organisationNameMeta.touched && !organisationNameMeta.valid, 
                       'input-valid': organisationNameMeta.touched && organisationNameMeta.valid 
                     }"
                     @input="organisationNameMeta.touched = true">
              <ErrorMessage name="name" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="forename">First Name</label>
              <input id="forename"
                     v-model="forenameValue"
                     type="text"
                     autocomplete="given-name"
                     :class="{ 
                       'input-error': forenameMeta.touched && !forenameMeta.valid,
                       'input-valid': forenameMeta.touched && forenameMeta.valid 
                     }"
                     @input="forenameMeta.touched = true">
              <ErrorMessage name="forename" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="surname">Last Name</label>
              <input id="surname"
                     v-model="surnameValue"
                     type="text"
                     autocomplete="family-name"
                     :class="{ 
                       'input-error': surnameMeta.touched && !surnameMeta.valid,
                       'input-valid': surnameMeta.touched && surnameMeta.valid 
                     }"
                     @input="surnameMeta.touched = true">
              <ErrorMessage name="surname" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="timezone">Timezone (optional)</label>
              <v-select id="timezone"
                        v-model="timezoneValue"
                        :reduce="(timezone: TimeZone) => timezone.tzCode"
                        :options="timezones"
                        autocomplete="off" />
              <ErrorMessage name="timezone" class="message-error message" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="email-address">Email address</label>
              <input id="email-address"
                     v-model="emailValue"
                     type="email"
                     autocomplete="email"
                     :class="{ 
                       'input-error': emailMeta.touched && !emailMeta.valid, 
                       'input-valid': emailMeta.touched && emailMeta.valid 
                     }"
                     @input="emailMeta.touched = true">
              <ErrorMessage name="email" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="password">Password</label>
              <input id="password"
                     v-model="passwordValue"
                     :type="isPasswordVisible ? 'text' : 'password'"
                     autocomplete="new-password"
                     :class="{ 
                       'input-error': passwordMeta.touched && !passwordMeta.valid,
                       'input-valid': passwordMeta.touched && passwordMeta.valid 
                     }"
                     @input="passwordMeta.touched = true">
              <ErrorMessage name="password" class="message message-error" as="p" />
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="confirmPassword">Confirm password</label>
              <input id="confirmPassword"
                     v-model="confirmPasswordValue"
                     :type="isPasswordVisible ? 'text' : 'password'"
                     autocomplete="new-password"
                     :class="{ 
                       'input-error': confirmPasswordMeta.touched && !confirmPasswordMeta.valid,
                       'input-valid': confirmPasswordMeta.touched && confirmPasswordMeta.valid 
                     }"
                     @input="confirmPasswordMeta.touched = true">
              <ErrorMessage name="confirmPassword" class="message message-error" as="p" />
            </div>
          </div>
        </section>

        <ButtonContainer justify-content="space-between">
          <ButtonComponent :type="ButtonType.Button"
                           :variant="ButtonVariant.Light"
                           :is-outline-btn="true"
                           :is-block-btn="true"
                           @click="togglePasswordVisibility">
            {{ isPasswordVisible ? 'Hide' : 'Show' }} Passwords
          </ButtonComponent>
          <ButtonComponent :disabled="!meta.touched || !meta.valid"
                           :loading="isSubmitting"
                           :type="ButtonType.Submit"
                           :variant="ButtonVariant.Dark"
                           :is-block-btn="true">
            Create Account
          </ButtonComponent>
        </ButtonContainer>
      </form>
    </div>

    <div class="account-link-container">
      <span>Already have an account? 
        <router-link :to="{ name: PageNames.Login }"
                     class="account-link">
          Log in
        </router-link>
      </span>
    </div>
  </AccountLayout>
</template>
