<script setup lang="ts">
import { onMounted, ref } from 'vue';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useRoute, useRouter } from 'vue-router';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import { extractErrorMessage } from '@utils/errorUtils';
import { useApplicationStore } from '@stores/application';
import { BadgeVariant, ButtonType, ButtonVariant } from '@viewModels/enums';
import Forbidden from '@layouts/Forbidden.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import BadgeComponent from '@components/BadgeComponent.vue';

interface CameraProvisioningForm {
  provisioningCode: string;
  serialNumber: string;
}

interface CameraNameBadgeInput {
  value: string | undefined;
  valid: boolean;
}

interface ProvisioningCodeBadgeInput {
  value: string | undefined;
  length: number;
  valid: boolean;
}

// Constants
const route = useRoute();
const router = useRouter();
const applicationStore = useApplicationStore();
const form = ref<InstanceType<typeof HTMLFormElement>>();
const isSubmitting = ref<boolean>(false);

// Page Permissions
const createCamera = applicationStore.canUser(client.Entitlements.CREATE_CAMERA, applicationStore.activeOrganisation!);
const registerCamera = applicationStore.canUser(client.Entitlements.REGISTER_CAMERA, applicationStore.activeOrganisation!);

// Form Schema configuration variables
const provisioningCodeRegex = /^[0-9]+$/; // Only numbers
const provisioningCodeLength = 6;
const serialNumberCodeRegex = /^[a-zA-Z0-9- ]*$/; // Allow letters, numbers, hyphens, and spaces
const serialNumberMaxLength = 24;

// Form Validation Schema
const { handleSubmit, meta } = useForm<CameraProvisioningForm>({
  validationSchema: yup.object({
    provisioningCode: yup
      .string()
      .required('Provisioning code is required')
      .matches(provisioningCodeRegex, 'Provisioning code can only contain numbers')
      .length(provisioningCodeLength, `Provisioning code must be ${provisioningCodeLength} digits`),
    serialNumber: yup
      .string()
      .required('Camera name is required')
      .matches(serialNumberCodeRegex, 'Camera name can only contain letters, numbers, hyphens, and spaces')
      .max(serialNumberMaxLength, `Camera name must be at most ${serialNumberMaxLength} characters`),
  }),
});

/**
 * Extracts the provisioning code field value and its metadata using `useField`.
 */
const { value: provisioningCode, meta: provisioningCodeMeta } = useField<string>(
  'provisioningCode',
);

/**
 * Extracts the camera name field value and its metadata using `useField`.
 */
const { value: serialNumber, meta: serialNumberMeta } = useField<string>(
  'serialNumber',
);



/**
 * Handles the form submission for camera provisioning.
 *
 * @param {CameraProvisioningForm} values - The form values containing the provisioning code and serial number.
 */
const onSubmit = handleSubmit(async (values: CameraProvisioningForm) => {
  isSubmitting.value = true;

  try {
    // Attempt to create a new camera using the provided form values
    const camera = await client.createCamera({
      requestBody: {
        provisioningCode: values.provisioningCode,
        organisation: applicationStore.activeOrganisation?.id,
        serialNumber: values.serialNumber,
      },
    });

    // Check if the camera creation was successful
    if (!camera) {
      console.error('Failed to provision camera');
      applicationStore.publishErrorNotification({
        text: 'Failed to provision camera. Please try again.',
      });
      return;
    }

    applicationStore.publishSuccessNotification({
      text: 'Successfully provisioned.',
      autoCloseMs: 3000,
    });

    // Redirect the user to the camera details page after a short delay
    setTimeout(() => {
      router.push({ name: 'camera-default', params: { id: camera.id } });
    }, 1000);

  } catch (error: unknown) {
    // Extract the error message from the caught error
    const errorMessage = extractErrorMessage(error);

    // Display an error notification to the user
    applicationStore.publishErrorNotification({
      text: errorMessage,
    });

    console.error('Error during provisioning:', error);
    throw new Error(errorMessage);
  } finally {
    isSubmitting.value = false;
  }
});


// Provisioning Badge. Default, valid and invalid states
const getBadgeVariantForProvisioningCode = ({value, valid, length }: ProvisioningCodeBadgeInput): BadgeVariant => {
  if (!value) {
    return BadgeVariant.Outline;
  }
  // Check exact length and valid input
  if (value.length === length && valid) {
    return BadgeVariant.Success;
  }
  return BadgeVariant.Danger;
};

// Camera Name Badge. Default, valid and invalid states
const getBadgeVariantForSerialNumber = ({ value, valid }: CameraNameBadgeInput): BadgeVariant => {
  if (!value) {
    return BadgeVariant.Outline;
  }
  if (valid) {
    return BadgeVariant.Success;
  }
  return BadgeVariant.Danger;
};

/**
 * Handles the population of the provisioning code from URL parameters on component mount.
 */
onMounted(() => {
  const provisioningCodeParam = route.params['code'] && String(route.params['code']);

  // If the provisioning code is in the URL params
  if (provisioningCodeParam) {
    if (provisioningCodeParam.match(provisioningCodeRegex)) {
      provisioningCode.value = provisioningCodeParam; // Populate valid code
      provisioningCodeMeta.touched = true; // Mark as touched for validation
    } else {
      console.warn('Invalid provisioning code in URL param:', provisioningCodeParam);
    }
  }
});
</script>

<template>
  <template v-if="createCamera || registerCamera">
    <SubHeader heading="Provision Camera"
               level="2" />

    <ContainerCard>
      <form ref="form"
            @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              Provisioning code
            </Heading>
            <p>Enter the code provided to link it to your account.</p>
          </div>

          <div class="fields">
            <div class="row-half">
              <div class="field">
                <div class="d-flex align-center justify-between">
                  <label for="provisioning-code">Provisioning code</label>
                  <BadgeComponent :variant="getBadgeVariantForProvisioningCode({
                                    value: provisioningCode,
                                    valid: provisioningCodeMeta.valid,
                                    length: provisioningCodeLength,
                                  })"
                                  :is-pill="true">
                    {{ provisioningCode?.length || 0 }}|{{ provisioningCodeLength }}
                  </BadgeComponent>
                </div>
                <input id="provisioning-code"
                       v-model="provisioningCode"
                       inputmode="numeric"
                       placeholder="123456"
                       autocomplete="off"
                       type="text"
                       :maxlength="provisioningCodeLength"
                       :class="{
                         'input-error': provisioningCodeMeta.touched && !provisioningCodeMeta.valid,
                         'input-valid': provisioningCodeMeta.touched && provisioningCodeMeta.valid
                       }"
                       @input="provisioningCodeMeta.touched = true">
                <ErrorMessage name="provisioningCode" class="message message-error" as="p" />
              </div>
            </div>
          </div>
        </div>

        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              Camera Name
            </Heading>
            <p>Enter a name to help you identify this device</p>
          </div>

          <div class="fields">
            <div class="row-half">
              <div class="field">
                <div class="d-flex align-center justify-between">
                  <label for="serial-number">Camera Name</label>
                  <BadgeComponent :variant="getBadgeVariantForSerialNumber({
                                    value: serialNumber,
                                    valid: serialNumberMeta.valid,
                                  })"
                                  :is-pill="true">
                    {{ serialNumber?.length || 0 }}|{{ serialNumberMaxLength }}
                  </BadgeComponent>
                </div>
                <input id="serial-number"
                       v-model="serialNumber"
                       placeholder="Camera One"
                       autocomplete="off"
                       type="text"
                       :maxlength="serialNumberMaxLength"
                       :class="{ 
                         'input-error': serialNumberMeta.touched && !serialNumberMeta.valid,
                         'input-valid': serialNumberMeta.touched && serialNumberMeta.valid
                       }"
                       @input="serialNumberMeta.touched = true">
                <ErrorMessage name="serialNumber" class="message message-error" as="p" />
              </div>
            </div>
          </div>
        </div>


        <ButtonContainer>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :disabled="isSubmitting || (!meta.touched || !meta.valid)"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Provision
          </ButtonComponent>
        </ButtonContainer>
      </form>
    </ContainerCard>
  </template>

  <template v-else>
    <ContainerCard>
      <Forbidden heading-text="Unauthorised Access">
        <p>You do not have permission to access this page</p>
      </Forbidden>
    </ContainerCard>
  </template>
</template>

<style lang="scss" scoped>
:deep(.badge) {
  min-width: 48px;
}
</style>
