<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useApplicationStore } from '@stores/application';
import { useViewStore, View } from '@stores/view';
import { ButtonType, ButtonVariant, CardVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const route = useRoute();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();
const viewId = route.params['id'] as string;
const isSubmitting = ref<boolean>(false);
const currentView = ref<View>();

const emit = defineEmits<{
  (e: 'onImported'): void;
  (e: 'onSkipped'): void;
}>();

const onImportViewSource = async (e: any): Promise<void> => {
  e.preventDefault();
  isSubmitting.value = true;
  const response = await viewStore.importViewImages(viewId);
  currentView.value = await viewStore.getViewById(viewId);
  isSubmitting.value = false;

  if (response.error !== undefined) {
    applicationStore.publishErrorNotification({ text: response.error });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully imported images to the view.',
    autoCloseMs: 3000,
  });

  emit('onImported')
};

const fetchViewData = async (): Promise<void> => {
  currentView.value = await viewStore.getViewById(viewId);
};

onMounted(async () => {
  await fetchViewData();
});

</script>

<template>
  <ContainerCard v-if="applicationStore.adminMode" :variant="CardVariant.Dark" :has-border="true">
    <form @submit="onImportViewSource">
      <Heading :has-bottom-margin="true" level="3">
        View Source Import Admin Panel
      </Heading>
      <p>Import images from AWS {{ `${currentView?.source!.bucket}/${currentView?.source!.prefix}` }}.</p>
      <p>Note: If there are no images in the configured source bucket, skip this step.</p>
      <p>Note: Only the database records are created, the actual images will stay hosted on AWS.</p>

      <ButtonContainer>
        <ButtonComponent :disabled="!(currentView?.source?.provider === 'AWS') || currentView?.imageCount > 0"
                         :variant="ButtonVariant.Light"
                         :loading="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Import Images
        </ButtonComponent>

        <ButtonComponent :disabled="isSubmitting"
                         :variant="ButtonVariant.Light"
                         :is-block-btn="true"
                         :type="ButtonType.Button"
                         @click="() => emit('onSkipped')">
          Skip
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </ContainerCard>
</template>
