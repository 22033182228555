// Notification States
export enum NotificationState {
  Error = 'error',
  Success = 'success',
}

//Camera Statuses
export enum CameraStatus {
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED',
}

// User Statuses
export enum UserStatus {
  Active = 'ACTIVE',
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
}

// Define the Router Page Names for Views
export enum PageNames {
  Account = 'account',
  Camera = 'admin-camera',
  Cameras = 'admin-cameras',
  CameraEdit = 'admin-camera-edit',
  CameraAPI = 'admin-camera-api',
  CameraNetwork = 'admin-camera-network',
  CameraNew = 'admin-camera-new',
  CameraLogs = 'admin-camera-logs',
  CameraMonitor = 'admin-camera-monitor',
  CameraPower = 'admin-camera-power',
  CameraProvision = 'admin-camera-provision',
  CameraRegister = 'admin-camera-register',
  CameraSchedule = 'admin-camera-schedule',
  CameraSettings = 'admin-camera-settings',
  CameraOverview = 'admin-cameras-overview',
  ChangePassword = 'change-password',
  CameraUnregistered = 'admin-cameras-unregistered',
  CameraUnregisteredEdit = 'admin-cameras-unregistered-edit',
  Clients = 'clients',
  ClientCreate = 'client-create',
  TransferSettingCreate = 'transfer-settings-create',
  TransferSettings = 'transfer-settings',
  ClientProjectsCreate = 'client-projects-create',
  ClientSettings = 'client-settings',
  Events = 'events',
  Forgot = 'forgot',
  Home = 'home',
  Login = 'login',
  Logout = 'logout',
  NotFound = 'not-found',
  Organisation = 'organisation',
  Organisations = 'organisations',
  OrganisationSettings = 'organisation-settings',
  Project = 'project',
  ProjectEdit = 'project-edit',
  ProjectCreate = 'project-create',
  Register = 'register',
  RegisterSuccess = 'register-success',
  User = 'user',
  Users = 'users',
  UserEdit = 'user-edit',
  UserInvitation = 'user-invitation',
  UserManageRoles = 'user-manage-roles',
  VideoNew = 'video-new',
  Videos = 'videos',
  View = 'view',
  ViewDownloads = 'view-downloads',
  ViewGallery = 'view-gallery',
  ViewEdit = 'view-edit',
  ViewAdmin = 'view-admin',
  ViewOverview = 'view-overview',
  ViewAws = 'view-aws',
  ViewLogs = 'admin-view-logs',
  ViewMonitor = 'view-monitor',
  ViewTransfers = 'view-transfers',
  ViewTransfersCreate = 'view-transfers-create',
  ViewMap = 'view-map',
  ViewNew = 'view-new',
  ViewVideos = 'view-videos',
  ViewViewer = 'view-viewer',
  ViewPublic = 'view-public-viewer',
  ViewWallboard = 'view-wallboard',
  Views = 'views',
  Welcome = 'welcome',
  AdminViews = 'admin-views',
  AdminClients = 'admin-clients',
  AdminTransfers = 'admin-transfers',
}

// Define the Breadcrumbs
export enum BreadcrumbTitles {
  API = 'API',
  AllViews = 'All Views',
  AllVideos = 'All Videos',
  AllCameras = 'All Cameras',
  AllProjects = 'All Projects',
  AllClients = 'All Clients',
  AllTransfers = 'All Transfers',
  AllUsers = 'All Users',
  AllOrganisations = 'All Organisations',
  EditOrganisation = 'Edit Organisation',
  EditCamera = 'Edit Camera',
  Cameras = 'Cameras',
  CreateClient = 'Create Client',
  CreateTransfer = 'Create Transfer',
  UpdateTransfer = 'Update Transfer',
  CreateCamera = 'Create Camera',
  RegisterCamera = 'Register Camera',
  Clients = 'Clients',
  CreateProject = 'Create Project',
  CreateVideo = 'Create Video',
  CreateView = 'Create View',
  Downloads = 'Downloads',
  Gallery = 'Gallery',
  Home = 'Home',
  Information = 'Information',
  Network = 'Network',
  Power = 'Power',
  Projects = 'Projects',
  Schedule = 'Schedule',
  Status = 'Status',
  UpdateClient = 'Update Client',
  UpdateProject = 'Update Project',
  Views = 'Views',
  ViewTransfers = 'View Transfers',
  Viewer = 'Viewer',
  Videos = 'Videos',
  InviteUser = 'Invite User',
  ManagerRoles = 'Manage Roles',
  UnregisteredCameras = 'Unregistered Cameras',
}

// Define the Breadcrumb Paths
export enum BreadcrumbPaths {
  Home = '/',
  Views = '/views',
  ViewTransfers = '/admin/views/:id/transfers',
  AllTransfers = '/admin/transfers',
  AllViews = '/admin/views',
  AllCameras = '/admin/cameras',
  Clients = '/admin/clients',
  Projects = '/admin/projects',
  Organisations = '/admin/organisations',
  AllUsers = '/admin/users',
  Users = '/users',
  Videos = '/videos',
  UnregisteredCameras = '/admin/cameras/unregistered',
}

// Define the Main Menu
export enum MainMenuTitles {
  Admin = 'Admin',
  AllViews = 'Views',
  Cameras = 'Cameras',
  Settings = 'Settings',
  Status = 'Status',
  Videos = 'Videos',
}

// Admin Menu
export enum AdminMenuTitles {
  Organisations = 'Organisations',
  Users = 'Users',
  Views = 'Views',
  Projects = 'Projects',
  Clients = 'Clients',
  TransferSettings = 'Transfer settings',
}

// MultiStep Progress Steps
export enum StepStatus {
  Default = 'default',
  Pending = 'pending',
  Completed = 'completed',
  Error = 'error',
}

// MultiStep Fetch Status
export enum FetchStatus {
  Idle = 'idle',
  Fetching = 'fetching',
  Success = 'success',
  Error = 'error',
}

// Alert Banner Variants
export enum AlertVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Link = 'link',
}

// Button Variants
export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Link = 'link',
  Transparent = 'transparent',
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export enum ButtonSize {
  Small = 'small',
  Large = 'large',
}

export enum ButtonStyle {
  Rectangle = 'rectangle',
  Pill = 'pill',
  Rounded = 'rounded',
}

export enum ToggleVariant {
  Success = 'success',
  Danger = 'danger',
  Dark = 'dark',
}

// Badge Theme Variants
export enum BadgeVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Link = 'link',
  Black = 'black',
  Transparent = 'transparent',
  Outline = 'outline',
}

// Card Theme Variants
export enum CardVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Light = 'light',
  Dark = 'dark',
  LightGray = 'light-gray',
  TransparentDark = 'transparent-dark',
  TransparentLight = 'transparent-light',
  TransparentDanger = 'transparent-danger',
}

// Heading Variants
export enum HeadingVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Inherit = 'inherit',
}

// Progress Bar Variants
export enum ProgressBarVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
}

export enum NotificationCountVariant {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Light = 'light',
  Dark = 'dark',
}

export enum ProgressBarSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum DatePickerTheme {
    Dark = 'dark',
    Light = 'light',
}

export enum ImageSize {
  Thumbnail = '/thumbnail',
  Medium = '/card',
  Large = '/large',
}

export enum CardContainerLayout {
  Default = 'default',
  GoogleMap = 'googleMap',
  Downloads = 'downloads',
}

export enum SkeletonVariant {
  Card = 'card',
  InfoTile = 'info-tile',
  Default = 'default',
  List = 'list',
  Table = 'table',
  Button = 'button',
  ButtonSmall = 'button-small',
  Badge = 'badge',
}

export enum SubNavItemPosition {
  Left = 'left',
  Right = 'right',
}
