<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';

interface ProjectUpdateForm {
  name: string;
}
const schema = yup.object({
  name: yup.string().required(),
});

const { handleSubmit, setErrors } = useForm<ProjectUpdateForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');

const router = useRouter();
const route = useRoute();
const applicationStore = useApplicationStore();

const isSubmitting = ref<boolean>(false);
const projectId = route.params['id'] as string;

const currentProject = await client.getProjectById({ projectId });
nameValue.value = currentProject.name;

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;

  const requestBody: client.UpdateProjectRequest = {
    name: values.name,
  };

  try {
    await client.updateProjectById({ projectId, requestBody });
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error updating project: ${errorMessage}`,
    });

    return;
  } finally {
    isSubmitting.value = false;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully updated project.',
    autoCloseMs: 3000,
  });

  router.push({ name: PageNames.ClientSettings, params: { id: currentProject.client } });
});

// Handle navigation
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /projects
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Projects as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Project Information
        </Heading>
        <p>Update a project.</p>
      </div>
      <div class="fields">
        <div class="field">
          <label for="project-name">Name</label>
          <input id="project-name"
                 v-model="nameValue"
                 type="text">
          <p class="message message-error">
            {{ nameError }}
          </p>
        </div>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :disabled="isSubmitting">
        Update
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
