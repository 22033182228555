<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';
import { storeToRefs } from 'pinia';
import Forbidden from '@layouts/Forbidden.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import Heading from '@components/Heading.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import {
  BadgeVariant,
  BreadcrumbPaths,
  BreadcrumbTitles,
  ButtonType,
  ButtonVariant, CardVariant,
  HeadingVariant, PageNames,
} from '@viewModels/enums';
import HorizontalRule from '@components/HorizontalRule.vue';

interface BadgeInput {
  value: string | undefined;
  length: number;
  valid: boolean;
}

// Computed values
const breadcrumbs = computed((): BreadCrumbItem[] => [
  { title: BreadcrumbTitles.AllCameras, to: BreadcrumbPaths.AllCameras },
  { title: currentCamera.value?.serialNumber || 'Loading...', to: `/admin/cameras/${cameraId.value}` },
  { title: 'Edit', active: true },
]);

const pageHeading = computed((): string => {
  if (currentCamera.value?.serialNumber) return `Camera ${currentCamera.value.serialNumber} Edit`;
  return 'Loading...';
});

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const route = useRoute();
const isSubmitting = ref(false);
const cameraId = ref(route.params['id'] as string);
const currentCamera = ref<client.Camera>();
const isLoading = ref(true);

// Validation for serialNumber only
const serialNumberLength = 10;
const alphanumericDashRegex = /^[a-zA-Z0-9-]*$/;

const { handleSubmit, meta, setErrors } = useForm({
  validationSchema: yup.object({
    serialNumber: yup
      .string()
      .required('GabrielCam Serial Number is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(serialNumberLength, `GabrielCam Serial Number must be exactly ${serialNumberLength} characters`),
  }),
});
const { value: serialNumber, meta: serialNumberMeta, resetField } = useField<string>('serialNumber');

/**
 * Returns the badge variant based on the input value, length, and validity.
 *
 * @param {BadgeInput} input - The input object containing value, length, and valid properties.
 * @returns {BadgeVariant} The badge variant corresponding to the input state.
 */
const getBadgeVariant = ({ value, length, valid }: BadgeInput): BadgeVariant => {
  // If the input is empty
  if (!value || value.length === 0) {
    return BadgeVariant.Outline;
  }
  // If the input is valid and has the correct length
  if (value.length === length && valid) {
    return BadgeVariant.Success;
  }
  // Otherwise
  return BadgeVariant.Danger;
};

const handleSubmitForm = handleSubmit(async () => {
  isSubmitting.value = true;
  if (!adminMode.value) return;

  try {
    currentCamera.value = await client.updateCameraById({
      cameraId: cameraId.value,
      requestBody: { serialNumber: serialNumber.value },
    }) as client.Camera;

    applicationStore.publishSuccessNotification({
      text: 'Camera updated successfully.',
      autoCloseMs: 3000,
    });

    resetField({ value: serialNumber.value });

  } catch (error: unknown) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error);
    if (errorFields) {
      setErrors(errorFields);
    }
    applicationStore.publishErrorNotification({
      text: `Error updating camera: ${errorMessage}`,
    });
  } finally {
    isSubmitting.value = false;
  }
});



onMounted(async () => {
  isLoading.value = true;
  try {
    currentCamera.value = await client.getCameraById({ cameraId: cameraId.value });
    serialNumber.value = currentCamera.value.serialNumber || '';
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <template v-if="adminMode">
    <SubHeader :heading="pageHeading" level="2" />

    <Breadcrumb :is-sticky="true" :items="breadcrumbs" />

    <ContainerCard>
      <Loading v-if="isLoading" />

      <section v-else>
        <form @submit="handleSubmitForm">
          <div class="field-group">
            <div class="field-group-info">
              <Heading level="3">
                Edit Camera Information
              </Heading>
              <p>Update the details of the camera.</p>
            </div>

            <div class="fields">
              <!-- Only the serialNumber field is kept -->
              <div class="row-half">
                <div class="field">
                  <div class="d-flex align-center justify-between">
                    <label for="serialNumber">GabrielCam Serial Number</label>
                    <BadgeComponent :variant="getBadgeVariant({ value: serialNumber, length: serialNumberLength, valid: serialNumberMeta.valid })"
                                    :is-pill="true">
                      {{ serialNumber?.length || 0 }}|{{ serialNumberLength }}
                    </BadgeComponent>
                  </div>
                  <input id="serialNumber"
                         v-model="serialNumber"
                         type="text"
                         placeholder="GC-1234-56"
                         :maxlength="serialNumberLength"
                         :class="{
                           'input-error': serialNumberMeta.touched && !serialNumberMeta.valid,
                           'input-valid': serialNumberMeta.touched && serialNumberMeta.valid
                         }"
                         @input="serialNumberMeta.touched = true">
                  <ErrorMessage name="serialNumber" class="message message-error" as="p" />
                </div>
                <div class="field" />
              </div>

              <!-- Danger Zone -->
              <template v-if="false">
                <HorizontalRule />

                <Heading level="3" :variant="HeadingVariant.Secondary" :has-bottom-margin="true">
                  Danger Zone
                </Heading>
                <ContainerCard :variant="CardVariant.TransparentDanger" :has-border="true">
                  <p class="text--size-xs mb-0 text--danger">
                    Warning: Delete Camera here
                  </p>
                </ContainerCard>
              </template>
            </div>
          </div>

          <ButtonContainer>
            <ButtonComponent :is-block-btn="true"
                             :variant="ButtonVariant.Dark"
                             :is-outline-btn="true"
                             :to="{ name: PageNames.CameraOverview, params: { id: cameraId } }">
              Cancel
            </ButtonComponent>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :loading="isSubmitting"
                             :disabled="!meta.touched || !meta.valid"
                             :is-block-btn="true"
                             :type="ButtonType.Submit">
              Save
            </ButtonComponent>
          </ButtonContainer>
        </form>
      </section>
    </ContainerCard>
  </template>

  <template v-else>
    <ContainerCard>
      <Forbidden heading-text="Unauthorised Access">
        <p>You do not have permission to access this page</p>
      </Forbidden>
    </ContainerCard>
  </template>
</template>

<style lang="scss" scoped>
:deep(.badge) {
  min-width: 48px;
}
</style>