<script lang="ts" setup>
import { reactive, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import { BadgeVariant, BreadcrumbTitles, ButtonSize, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import BadgeComponent from '@components/BadgeComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import TableComponent from '@components/table/TableComponent.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import { dateTimeFormat } from '@utils/date';
import { useTimeoutFn } from '@vueuse/core';

enum TableHeaders {
  Views = 'View Name',
  CreatedAt = 'Created At',
  ViewOverview = 'View Overview',
  Status = 'View Status',
  UpdateStatus = 'Update Status',
  Delete = 'Delete View',
}

interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, active: true },
];

// Stores
const applicationStore = useApplicationStore();
const viewStore = useViewStore();
const { activeUser } = applicationStore;

// View Permissions
const showNew = applicationStore.canUser(client.Entitlements.CREATE_CAMERA, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CAMERA, applicationStore.activeOrganisation!);
const createView = applicationStore.canUser(client.Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);

// Table Data
const cameraLiveStatus = ref(new Map<string, boolean>());

// Loading
const isLoading = ref<boolean>(false);

// Delete Modal
const showDeleteModal = ref<boolean>(false);
const selectedViewToDelete = ref<client.View | null>(null);

// Status Modal
const showStatusModal = ref<boolean>(false);
const selectedViewToUpdate = ref<client.View | null>(null);
const selectedViewStatus = ref<client.ViewStatus | null>(null);

// Dropdown
const statusOptions = [
  { label: client.ViewStatus.ACTIVE, value: client.ViewStatus.ACTIVE },
  { label: client.ViewStatus.INACTIVE, value: client.ViewStatus.INACTIVE },
  { label: client.ViewStatus.ARCHIVE, value: client.ViewStatus.ARCHIVE },
];

// Handle Status Change & Open Modal
function handleStatusChange(row: client.View, newStatus: client.ViewStatus): void {
  if (newStatus !== row.status) {
    showStatusConfirmModal(row, newStatus);
  }
}

// Table
const tableRef = ref<DirectoryTableType | null>(null);
const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.Views,
    dataField: 'name',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '94%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.CreatedAt,
    dataField: 'createdAt',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
    isSortable: true,
    sortableType: 'date',
    // Format the date only on the  UI to enable sorting within the table component
    formatFunction: (value: string) => dateTimeFormat(activeUser?.timezone).format(new Date(value)),
  },
  {
    labelText: TableHeaders.Status,
    dataField: 'status',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.UpdateStatus,
    dataField: 'updateStatus',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '120px',
  },
  {
    labelText: TableHeaders.ViewOverview,
    dataField: 'actions',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
  },
];

// Add the delete column if permissions allow
if (showDelete) {
  columns.push({
    labelText: TableHeaders.Delete,
    dataField: 'delete',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
  });
}

const table = reactive({
  columns: columns,
  sortable: {
    order: 'createdAt',
    sort: 'desc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

/**
 * Fetches all views and all cameras for the current organisation,
 * then maps the registered status of cameras to their corresponding views.
 * Uses camera IDs to determine if a view's camera is registered and updates the `cameraLiveStatus` map.
 *
 * @returns {Promise<client.View[]>} A promise that resolves to an array of views with their camera registration status mapped.
 */
async function updateViewCameraLiveStatus(): Promise<client.View[]> {
  isLoading.value = true;

  try {
    // Fetch views and cameras concurrently
    const [viewsResponse, camerasResponse] = await Promise.all([
      client.listViews({
        organisation: applicationStore.activeOrganisation!.id,
      } as client.ListViewsData),
      client.listCameras({
        organisation: applicationStore.activeOrganisation!.id,
      } as client.ListCamerasData),
    ]);

    const views = viewsResponse.data;
    const cameras = camerasResponse.data;

    // Create a map of live cameras by their ID and registered status
    const liveCameras: Map<string, boolean> = new Map(
      cameras
        .filter((camera: client.Camera) => camera.status === client.CameraStatus.REGISTERED)
        .map((camera: client.Camera) => [camera.id, true]),
    );

    // Iterate over each view and set its camera live status
    views.forEach((view: client.View) => {
      // Skip undefined cameras and update the camera live status
      if (view.camera) {
        const isRegistered = liveCameras.has(view.camera);
        cameraLiveStatus.value.set(view.camera, isRegistered);
      }
    });

    // Return the views with their live camera status
    return views;
  } catch (error) {
    console.error('Error fetching camera list or views:', error);
    // Return an empty array if an error occurs
    return [];
  } finally {
    isLoading.value = false;
  }
}

// View Status Badge Variant Map
const statusVariantMap = {
  [client.ViewStatus.ACTIVE]: { variant: BadgeVariant.Success, label: client.ViewStatus.ACTIVE },
  [client.ViewStatus.INACTIVE]: { variant: BadgeVariant.Danger, label: client.ViewStatus.INACTIVE },
  [client.ViewStatus.ARCHIVE]: { variant: BadgeVariant.Warning, label: client.ViewStatus.ARCHIVE },
};

function showDeleteConfirmModal(row: client.View): void {
  showDeleteModal.value = true;
  selectedViewToDelete.value = row;
}

function closeDeleteConfirmModal(): void {
  showDeleteModal.value = false;
  selectedViewToDelete.value = null;
}

async function deleteView(): Promise<void> {
  if (!selectedViewToDelete.value?.id) return;

  const result = await viewStore.deleteView(selectedViewToDelete.value.id);

  closeDeleteConfirmModal();

  if (!result) {
    applicationStore.publishErrorNotification({ text: 'Failed to delete view.' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted view.',
    autoCloseMs: 3000,
  });

  // Reload the table on deletion
  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}

function showStatusConfirmModal(row: client.View, newStatus: client.ViewStatus): void {
  selectedViewToUpdate.value = row;
  selectedViewStatus.value = newStatus;
  showStatusModal.value = true;
}

function confirmStatusChange(): void {
  if (selectedViewToUpdate.value && selectedViewStatus.value) {
    // Update the row's status after confirmation
    selectedViewToUpdate.value.status = selectedViewStatus.value;
    // Close the modal
    closeStatusConfirmModal();
  }
}

function closeStatusConfirmModal(): void {
  showStatusModal.value = false;
  selectedViewToUpdate.value = null;
  selectedViewStatus.value = null;
}

async function changeViewStatus(): Promise<void> {
  const viewId = selectedViewToUpdate.value?.id;
  const viewStatus = selectedViewToUpdate.value?.status;

  if (!viewId || !viewStatus) return;

  const isDeactivation = viewStatus === client.ViewStatus.ACTIVE;
  const result = isDeactivation
    ? await viewStore.deactivateView(viewId)
    : await viewStore.activateView(viewId);

  closeStatusConfirmModal();

  if (!result) {
    applicationStore.publishErrorNotification({ text: 'Failed to change view status.' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully changed view status.',
    autoCloseMs: 3000,
  });

  // Reload the table
  await tableRef.value?.reloadData();
}


const calculatePosition = (dropdown: HTMLElement, component: any) => {
  // Hide dropdown initially to prevent flash
  dropdown.style.opacity = "0";
  dropdown.style.visibility = "hidden";
  // Prevent body scrollbar flash
  document.body.style.overflow = "hidden";

  useTimeoutFn(() => {
    const parentColumn = component.$el.closest("td, th");
    const columnWidth = parentColumn ? parentColumn.offsetWidth : component.$el.offsetWidth;

    dropdown.style.setProperty("min-width", "unset", "important");
    dropdown.style.setProperty("width", `${columnWidth - 16}px`, "important");
    dropdown.style.top = `${window.scrollY + component.$el.getBoundingClientRect().bottom}px`;
    dropdown.style.left = `${component.$el.getBoundingClientRect().left}px`;

    // Show dropdown
    dropdown.style.opacity = "1";
    dropdown.style.visibility = "visible";

    // Show body scrollbar
    document.body.style.overflow = "";
  }, 20);
};
</script>

<template>
  <SubHeader heading="All Views"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="createView"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ViewNew }"
                       :variant="ButtonVariant.Primary"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add View
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <TableComponent ref="tableRef"
                          :columns="columns"
                          :retrieve-data="updateViewCameraLiveStatus"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No views found"
                          :icon-name="IconName.CameraIcon"
                          :icon-style="IconStyle.Outline">
                <template v-if="showNew">
                  <p>Get started by registering your first view</p>
                  <ButtonComponent :to="{ name: PageNames.ViewNew }"
                                   :variant="ButtonVariant.Dark">
                    Add View
                  </ButtonComponent>
                </template>
              </EmptyState>
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.labelText === TableHeaders.Views">
                <router-link :to="{ name: PageNames.ViewOverview, params: { id: row.id } }">
                  {{ row.name }}
                </router-link>
              </template>

              <template v-if="column.labelText === TableHeaders.CreatedAt">
                <div class="d-flex justify-center">
                  <span class="text--white-space-nowrap">
                    {{ column.formatFunction ? column.formatFunction(row[column.dataField]) : row[column.dataField] }}
                  </span>
                </div>
              </template>

              <template v-if="column.labelText === TableHeaders.Status">
                <div class="d-flex justify-center">
                  <BadgeComponent v-if="statusVariantMap[row.status as client.ViewStatus]"
                                  :is-pill="true"
                                  :variant="statusVariantMap[row.status as client.ViewStatus].variant">
                    {{ statusVariantMap[row.status as client.ViewStatus].label }}
                  </BadgeComponent>
                </div>
              </template>

              <template v-if="column.labelText === TableHeaders.UpdateStatus">
                <v-select :model-value="row.status"
                          :input-id="`status-${row.id}`"
                          aria-label="Select a Status"
                          :options="statusOptions"
                          :clearable="false"
                          label="label"
                          :append-to-body="true"
                          :calculate-position="calculatePosition"
                          :disabled="row.status === client.ViewStatus.ARCHIVE"
                          @option:selected="handleStatusChange(row, $event.value)" />
              </template>

              <template v-if="column.labelText === TableHeaders.ViewOverview">
                <div class="d-flex justify-center gap-20">
                  <ButtonComponent v-if="row.id"
                                   :to="{ name: PageNames.ViewOverview, params: { id: row.id } }"
                                   :variant="ButtonVariant.Dark"
                                   :size="ButtonSize.Small">
                    Overview
                  </ButtonComponent>
                </div>
              </template>

              <template v-if="column.labelText === TableHeaders.Delete">
                <div class="d-flex justify-center gap-20">
                  <ButtonComponent v-if="showDelete"
                                   :variant="ButtonVariant.Danger"
                                   :size="ButtonSize.Small"
                                   @click="showDeleteConfirmModal(row)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TableComponent>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!--   Delete View Modal -->
  <ModalComponent v-if="showDelete && selectedViewToDelete"
                  :visible="true"
                  heading-title="Delete View"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the view: <span class="text--semibold">{{ selectedViewToDelete.name }}</span>
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteView()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>


  <!--   Status View Modal -->
  <ModalComponent v-if="showStatusModal && selectedViewToUpdate"
                  :visible="true"
                  heading-title="Change View Status"
                  @confirm="confirmStatusChange"
                  @on-close="closeStatusConfirmModal">
    <template #modal-content>
      <p v-if="selectedViewStatus === client.ViewStatus.ARCHIVE">
        <span class="text--semibold">Important:</span> Archiving this view is a permanent action.
        While the images on the view will still be available,
        <span class="text--semibold">you will not be able to activate an archived view. You will need to create a new view.</span>
      </p>
      <p v-else>
        Are you sure you want to
        <span class="text--semibold">
          {{ selectedViewStatus === client.ViewStatus.ACTIVE ? 'activate' : 'deactivate' }}
        </span>
        the view:
        <span class="text--semibold">{{ selectedViewToUpdate.name }}</span>?
      </p>
    </template>

    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeStatusConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Success"
                       @click="changeViewStatus">
        Confirm
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
