<script setup lang="ts">
import { SkeletonVariant } from '@viewModels/enums';

interface SkeletonLoadingProps {
  variant: SkeletonVariant;
  lineCount?: number;
}

const props = defineProps<SkeletonLoadingProps>();
const variant = props.variant || SkeletonVariant.Default;
const lineCount = props.lineCount || 3; // Default to 3 lines
</script>

<template>
  <div :class="`skeleton skeleton-${variant}`">
    <!-- Card and Default variants -->
    <template v-if="variant === SkeletonVariant.Card || variant === SkeletonVariant.Default">
      <div class="skeleton-card__header" />
      <div class="skeleton-card__content">
        <div v-for="n in lineCount" :key="n" class="skeleton-card__content--line" />
      </div>
      <div class="skeleton-card__footer">
        <div class="skeleton-card__footer--button" />
        <div class="skeleton-card__footer--button" />
      </div>
    </template>

    <!-- Info Tile variants -->
    <template v-if="variant === SkeletonVariant.InfoTile">
      <div class="skeleton-info-tile">
        <div class="skeleton-info-tile__body">
          <div class="skeleton-info-tile__icon" />
          <div class="skeleton-info-tile__content">
            <div class="skeleton-info-tile__title" />
            <div class="skeleton-info-tile__lines">
              <div v-for="n in lineCount" :key="n" class="skeleton-info-tile__line" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- List and Table variants -->
    <template v-else-if="variant === SkeletonVariant.List || variant === SkeletonVariant.Table">
      <div class="skeleton-card__content">
        <div v-for="n in lineCount" :key="n" class="skeleton-card__content--line" />
      </div>
    </template>

    <!-- Button -->
    <template v-else-if="variant === SkeletonVariant.Button">
      <div />
    </template>

    <!-- Button Small -->
    <template v-else-if="variant === SkeletonVariant.ButtonSmall">
      <div />
    </template>

    <!-- Badge -->
    <template v-else-if="variant === SkeletonVariant.Badge">
      <div />
    </template>

    <slot v-else />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

/* Base Skeleton */
.skeleton {
  --initial-color: var(--tls-gray-300);
  --final-color: var(--tls-gray-100);

  background: linear-gradient(90deg, var(--initial-color), var(--final-color), var(--initial-color));
  background-size: 200% 100%;
  border-radius: $border-radius;
  animation: pulse 3000ms ease-in-out infinite;
  padding: clamp($gap-mobile, 2vw, $gap-desktop);

  &-button {
    width: 120px;
    height: 35px;
    background-color: var(--initial-color);
    border-radius: $border-radius;

    &-small {
      width: 70px;
      height: 33px;
    }
  }

  &-badge {
    width: 80px;
    height: 20px;
  }

  /* Info Tile Variant (Nested Inside Skeleton) */
  .skeleton-info-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-height: 50px;
    background: linear-gradient(90deg, var(--initial-color), var(--final-color), var(--initial-color));
    background-size: 200% 100%;
    border-radius: $border-radius;
    animation: pulse 3000ms ease-in-out infinite;

    &__body {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;
    }

    &__icon {
      width: 32px;
      height: 32px;
      background: var(--initial-color);
      border-radius: 4px;
      flex-shrink: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__title {
      width: 80%;
      height: 18px;
      background: var(--initial-color);
      border-radius: 4px;
      margin-bottom: 8px;
    }

    &__lines {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    &__line {
      width: 50%;
      height: 12px;
      background: var(--initial-color);
      border-radius: 4px;
    }
  }
}

/* Card and Default Variant */
.skeleton-card {
  padding: clamp($gap-mobile, 2vw, $gap-desktop);
  border-radius: $border-radius;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);

  &__header {
    width: 200px;
    height: 30px;
    margin-bottom: $margin-bottom;
    background-color: var(--initial-color);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--line {
      width: 100%;
      height: 24px;
      background-color: var(--initial-color);
    }
  }

  &__footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: $margin-bottom;

    &--button {
      width: 80px;
      height: 35px;
      background-color: var(--initial-color);
      border-radius: $border-radius;
    }
  }
}


</style>
