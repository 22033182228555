<script setup lang="ts">
import { onUnmounted, ref, watch, computed } from 'vue';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import { useApplicationStore } from '@stores/application';
import { useThemeStore } from '@stores/useThemeStore';
import { ColorPicker } from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import { AlertVariant, BreadcrumbPaths, ButtonSize, ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import Heading from '@components/Heading.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';
import AlertBanner from '@components/AlertBanner.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import router from '@/router';

enum ColorType {
  Primary = 'primary',
  Secondary = 'secondary',
}


// Stores
const route = useRoute();
const applicationStore = useApplicationStore();
const themeStore = useThemeStore();

// Setup
const organisationId = route.params['id'] as string;
const currentOrganisation = await client.getOrganisationById({ organisationId });
const showSaveAlert = ref(false);
const isRevertingTheme = ref(false);
const fileInputRef = ref<HTMLInputElement | null>(null);

// Loading
const isLoading = ref<boolean>(false);

// Default Colors
const defaultOrgPrimaryColor = ref('hsl(228, 53%, 19%)');
const defaultOrgSecondaryColor = ref('hsl(350, 86%, 43%)');

// Form Interface
interface OrganisationUpdateForm {
  name: string;
  primaryColour: string;
  secondaryColour: string;
  logo: string;
}

// Form Validation
const { handleSubmit, setErrors } = useForm<OrganisationUpdateForm>({
  validationSchema: yup.object({
    name: yup.string().required(),
    primaryColour: yup.string().nullable(),
    secondaryColour: yup.string().nullable(),
    logo: yup.string().nullable(),
  }),
});

// Form Fields
const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: primaryColourValue } = useField<string>('primaryColour', 'primaryColour');
const { value: secondaryColourValue } = useField<string>('secondaryColour', 'secondaryColour');
const { value: logoValue } = useField<string>('logo', 'logo');

// Form Values
nameValue.value = currentOrganisation.name;
primaryColourValue.value = currentOrganisation.settings?.primaryColour ?? '';
secondaryColourValue.value = currentOrganisation.settings?.secondaryColour ?? '';
logoValue.value = currentOrganisation.settings?.logoBase64 ?? themeStore.organisationTheme?.logoBase64 ?? '';

// Compare color picker values directly against the original API values | Used to revert changes
const primaryColorHasChanged = computed(() => {
  return primaryColourValue.value !== currentOrganisation.settings?.primaryColour;
});
const secondaryColorHasChanged = computed(() => {
  return secondaryColourValue.value !== currentOrganisation.settings?.secondaryColour;
});


const resetColorToDefault = (type: ColorType): void => {
  if (type === ColorType.Primary) {
    const defaultPrimary = defaultOrgPrimaryColor.value;
    primaryColourValue.value = defaultPrimary;

    if (organisationId === applicationStore.activeOrganisation?.id) {
      themeStore.setTemporaryPrimaryColor(defaultPrimary);
    }
  } else {
    const defaultSecondary = defaultOrgSecondaryColor.value;
    secondaryColourValue.value = defaultSecondary;

    if (organisationId === applicationStore.activeOrganisation?.id) {
      themeStore.setTemporarySecondaryColor(defaultSecondary);
    }
  }

  showSaveAlert.value = primaryColorHasChanged.value || secondaryColorHasChanged.value;
};

const revertPrimaryTheme = (): void => {
  isRevertingTheme.value = true;

  // Load the original primary color from the API response (or fallback to default)
  const originalPrimary = currentOrganisation.settings?.primaryColour ?? defaultOrgPrimaryColor.value;
  primaryColourValue.value = originalPrimary;

  // Update UI only if editing the active organisation
  if (organisationId === applicationStore.activeOrganisation?.id) {
    themeStore.setTemporaryPrimaryColor(originalPrimary);
  }

  setTimeout(() => {
    isRevertingTheme.value = false;
    showSaveAlert.value = primaryColorHasChanged.value || secondaryColorHasChanged.value;
  }, 100);
};

const revertSecondaryTheme = (): void => {
  isRevertingTheme.value = true;

  // Load the original secondary color from the API response (or fallback to default)
  const originalSecondary = currentOrganisation.settings?.secondaryColour ?? defaultOrgSecondaryColor.value;
  secondaryColourValue.value = originalSecondary;

  // Update UI only if editing the active organisation
  if (organisationId === applicationStore.activeOrganisation?.id) {
    themeStore.setTemporarySecondaryColor(originalSecondary);
  }

  setTimeout(() => {
    isRevertingTheme.value = false;
    showSaveAlert.value = primaryColorHasChanged.value || secondaryColorHasChanged.value;
  }, 100);
};

// Submit Form
const onSubmit = handleSubmit(async (values) => {
  isLoading.value = true;
  showSaveAlert.value = false;

  const requestBody: client.UpdateOrganisationRequest = {
    name: values.name,
    settings: {
      logoBase64: values.logo,
      primaryColour: values.primaryColour,
      secondaryColour: values.secondaryColour,
    },
  };

  try {
    await client.updateOrganisationById({ organisationId, requestBody });

    if (organisationId === applicationStore.activeOrganisation?.id) {
      await applicationStore.setActiveOrganisation(applicationStore.activeOrganisation.id);

      // Fetch the updated organisation theme
      themeStore.fetchOrganisationTheme();

      // Manually re-apply styles (ensures `--tls-logo` updates)
      themeStore.applyThemeStyles(organisationId);
    }

    applicationStore.publishSuccessNotification({
      text: 'Successfully updated organisation.',
      autoCloseMs: 3000,
    });

  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error updating organisation: ${errorMessage}`,
    });

    return;
  } finally {
    isLoading.value = false;
  }
});


/** Handle Logo Upload */
async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      resolve((reader.result as string | null) || '');
    };
    reader.onerror = (error): void => {
      reject(error);
    };
  });
}

async function handleFileChange(event: Event): Promise<void> {
  const inputElement = event.target as HTMLInputElement;
  if (!inputElement.files) return;

  const file = inputElement.files[0];
  if (file) {
    logoValue.value = await fileToBase64(file);
  }
}

/** Reset the Logo to the default */
const resetLogo = (): void => {
  logoValue.value = "/gabrielcam-logo.png";

  // Clear the file input field
  if (fileInputRef.value) {
    fileInputRef.value.value = "";
  }
};


// Watch for logo changes
watch(logoValue, (newValue: string) => {
  if (organisationId === applicationStore.activeOrganisation?.id) {
    themeStore.setTemporaryLogo(newValue);
  }

  // Show the alert if the logo is different from the originally saved logo
  showSaveAlert.value = newValue !== currentOrganisation.settings?.logoBase64;
});

// Watch for theme color changes
watch(primaryColourValue, (newValue: string) => {
  if (!isRevertingTheme.value && newValue) {
    // Only apply temporary color if editing the active organisation
    if (organisationId === applicationStore.activeOrganisation?.id) {
      themeStore.setTemporaryPrimaryColor(newValue);
    }

    showSaveAlert.value = true;
  }
});

watch(secondaryColourValue, (newValue: string) => {
  if (!isRevertingTheme.value && newValue) {
    // Only apply temporary color if editing the active organisation
    if (organisationId === applicationStore.activeOrganisation?.id) {
      themeStore.setTemporarySecondaryColor(newValue);
    }

    showSaveAlert.value = true;
  }
});


onUnmounted(() => {
  // Only reset UI if editing the active organisation
  if (organisationId === applicationStore.activeOrganisation?.id) {
    themeStore.resetUnsavedChanges();
  }
});

const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /admin/organisations
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Organisations as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Organisation Information
        </Heading>
        <p>Update the organisation settings, including logo and theme colors.</p>
      </div>

      <div class="fields">
        <div class="field">
          <label for="organisation-name">Organisation Name</label>
          <input id="organisation-name" 
                 v-model="nameValue" 
                 type="text">
          <p class="message message-error">
            {{ nameError }}
          </p>
        </div>

        <div class="row-half">
          <div class="field">
            <span class="label">Organisation Logo</span>
            <div class="file-input-wrapper">
              <input id="organisation-logo"
                     ref="fileInputRef"
                     aria-label="organisation-logo"
                     type="file"
                     @change="handleFileChange">

              <label for="organisation-logo" class="file-upload-button">
                Choose File
              </label>

              <span class="file-name">{{ logoValue?.slice(0, 20) || 'No Logo Selected' }}</span>

              <ButtonComponent :variant="ButtonVariant.Dark"
                               :is-outline-btn="true"
                               :is-block-btn="true"
                               :disabled="logoValue === '/gabrielcam-logo.png'"
                               :type="ButtonType.Button"
                               aria-label="Reset Logo"
                               class="ml-auto"
                               :icon-name="IconName.XMarkIcon"
                               :is-icon-btn="true"
                               @click="resetLogo" />
            </div>
          </div>
          <div class="field" />
        </div>

        <div class="row-half">
          <div class="field">
            <span class="label">Organisation Primary Colour</span>
            <div class="color-picker-wrapper">
              <ColorPicker id="organisation-primaryColour"
                           v-model:pure-color="primaryColourValue"
                           name="organisation-primaryColour"
                           :is-widget="true"
                           :disable-alpha="true"
                           :debounce="500"
                           use-type="pure"
                           format="hsl"
                           :z-index="1">
                <template #extra>
                  <ButtonContainer justify-content="space-between">
                    <ButtonComponent :variant="ButtonVariant.Dark"
                                     :disabled="isLoading || primaryColourValue === defaultOrgPrimaryColor"
                                     :is-block-btn="true"
                                     :size="ButtonSize.Small"
                                     @click="resetColorToDefault(ColorType.Primary)">
                      Default Color
                    </ButtonComponent>
                    <ButtonComponent :variant="ButtonVariant.Dark"
                                     :disabled="isLoading || !primaryColorHasChanged"
                                     :is-block-btn="true"
                                     :size="ButtonSize.Small"
                                     @click="revertPrimaryTheme">
                      Revert Changes
                    </ButtonComponent>
                  </ButtonContainer>
                </template>
              </ColorPicker>
            </div>

            <div class="organisation-theme organisation-theme--primary" :style="{ backgroundColor: primaryColourValue, color: themeStore.getTextColor(primaryColourValue) }">
              <Transition name="fade">
                <div v-if="logoValue" class="organisation-theme__logo">
                  <img :src="logoValue"
                       alt="Current logo"
                       aria-hidden="true">
                </div>
              </Transition>
              <Heading level="3" :has-bottom-margin="true">
                Primary Theme Example
              </Heading>
              <p>Paragraph Example</p>
            </div>
          </div>

          <div class="field">
            <span class="label">Organisation Secondary Colour</span>
            <div class="color-picker-wrapper">
              <ColorPicker id="organisation-secondaryColour"
                           v-model:pure-color="secondaryColourValue"
                           name="organisation-secondaryColour"
                           :is-widget="true"
                           :disable-alpha="true"
                           :debounce="500"
                           use-type="pure"
                           format="hsl"
                           :z-index="1">
                <template #extra>
                  <ButtonContainer justify-content="space-between">
                    <ButtonComponent :variant="ButtonVariant.Dark"
                                     :disabled="isLoading || secondaryColourValue === defaultOrgSecondaryColor"
                                     :is-block-btn="true"
                                     :size="ButtonSize.Small"
                                     @click="resetColorToDefault(ColorType.Secondary)">
                      Default Color
                    </ButtonComponent>
                    <ButtonComponent :variant="ButtonVariant.Dark"
                                     :disabled="isLoading || !secondaryColorHasChanged"
                                     :is-block-btn="true"
                                     :size="ButtonSize.Small"
                                     @click="revertSecondaryTheme">
                      Revert Changes
                    </ButtonComponent>
                  </ButtonContainer>
                </template>
              </ColorPicker>
            </div>

            <div class="organisation-theme organisation-theme--secondary" :style="{ backgroundColor: secondaryColourValue, color: themeStore.getTextColor(secondaryColourValue) }">
              <Heading level="3" :has-bottom-margin="true">
                Secondary Theme Example
              </Heading>
              <p>Paragraph Example</p>
            </div>
          </div>
        </div>

        <Transition name="fade">
          <AlertBanner v-if="showSaveAlert"
                       :has-bottom-margin="true"
                       :icon-name="IconName.ExclamationTriangleIcon"
                       :icon-style="IconStyle.Outline"
                       :variant="AlertVariant.Danger">
            <template #mainContent>
              You have unsaved theme changes! Click <strong>Save</strong> the confirm the changes.
            </template>
          </AlertBanner>
        </Transition>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       :is-block-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :disabled="isLoading"
                       :is-block-btn="true"
                       :type="ButtonType.Submit">
        Save
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

// Color Picker Overrides
:deep(.vc-colorpicker) {
  width: 100%;
}
:deep(.vc-colorpicker--container) {
  margin-bottom: $margin-bottom;
}

// File Input Layout
.file-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.organisation-theme {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: $border-radius;
  padding: $gap-mobile;

  &__logo {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius;
    margin-block: clamp($gap-mobile, 3vw, $gap-desktop);

    & img {
      height: 50px;
      object-fit: contain;

      @media screen and (min-width: $breakpoint-lg) {
        width: 200px;
        height: 60px;
        object-fit: contain;
      }
    }
  }
}


// Transition Fade
.fade-enter-active {
  animation: fadeIn 0.3s ease-out forwards;
}

.fade-leave-active {
  animation: fadeOut 0.3s ease-out forwards;
}
</style>
