<script setup lang="ts">
import { ref, watch } from 'vue';
import ViewDirectorySort, { ViewDirectorySortQueryParameters } from '@components/view/ViewDirectorySort.vue';
import ViewDirectoryFilter, { ViewDirectoryFilterQueryParameters } from '@components/view/ViewDirectoryFilter.vue';
import ViewDirectorySearch, { ViewDirectorySearchQueryParameters } from '@components/view/ViewDirectorySearch.vue';
import ViewDirectoryNavigation from '@components/view/ViewDirectoryNavigation.vue';
import { vOnClickOutside } from '@vueuse/components';

export interface ViewDirectoryQueryParameters extends
  ViewDirectorySearchQueryParameters,
  ViewDirectorySortQueryParameters,
  ViewDirectoryFilterQueryParameters {}

const emit = defineEmits<{ (e: 'criteriaSelected', { client, project, status, visibility } : ViewDirectoryQueryParameters): void }>();
const ViewDirectoryFilterValue = ref<ViewDirectoryFilterQueryParameters>({});
const ViewDirectorySortValue = ref<ViewDirectorySortQueryParameters>({});
const ViewDirectorySearchValue = ref<ViewDirectorySearchQueryParameters>();
const directorySort = ref();
const directoryFilter = ref();

watch([
  ViewDirectoryFilterValue,
  ViewDirectorySortValue,
  ViewDirectorySearchValue
], () => {
  emit('criteriaSelected', {
    ...ViewDirectoryFilterValue.value,
    ...ViewDirectorySortValue.value,
    ...ViewDirectorySearchValue.value
  });
})
</script>

<template>
  <div class="views-toolbar">
    <ViewDirectoryNavigation />

    <div class="views-toolbar__filters">
      <ViewDirectorySort ref="directorySort"
                         v-on-click-outside="() => directorySort.hideDropdown()"
                         @sort-selected="(sort) => ViewDirectorySortValue = sort" />

      <ViewDirectoryFilter ref="directoryFilter"
                           v-on-click-outside="() => directoryFilter.hideDropdown()"
                           @filter-selected="(filters) => ViewDirectoryFilterValue = filters" />
    </div>

    <ViewDirectorySearch class="views-toolbar__search"
                         @search="(searchText) => ViewDirectorySearchValue = searchText" />
  </div>
</template>

<style lang="scss">
@use '@scss/variables' as *;

.views-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-default;
  align-items: center;
  margin-bottom: $gap-default * 2;
  justify-content: space-between;

  &__filters {
    display: flex;
    gap: $gap-default;
    flex-grow: 1;
    justify-content: flex-end;

    @media screen and (width >= $breakpoint-lg) {
      justify-content: flex-start;
    }
  }

  &__search {
    flex-basis: 100%;
    @media screen and (width >= $breakpoint-lg) {
      flex-basis: unset;
    }
  }
}
</style>
