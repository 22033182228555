<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import {
  NotificationChannelType,
  NotificationFrequency,
  Resources_Camera,
} from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';
import { channelOptions, frequencyOptions, statusOptions } from '@components/notification/options';
import { ina219BusVoltageToValue, valueToIna219BusVoltage } from '@utils/voltages';

const { cameraId } = defineProps<{ cameraId: string }>()
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const isLoading = ref<boolean>(true);
const { handleSubmit, setErrors, resetForm } = useForm({
  validationSchema: yup.object({
    criteria: yup.object({
      batteryVoltageLessThanEqualTo: yup
        .number()
        .required('Voltage is required')
    }),
    channel: yup.object({
      value: yup
        .string()
        .required('Destination is required')
    }),
  }),
});
const { value: statusValue } = useField<boolean>('status');
const { value: batteryVoltageLessThanEqualToValue } = useField<number>('criteria.batteryVoltageLessThanEqualTo');
const { value: notificationChannelTypeValue } = useField<NotificationChannelType>('channel.type');
const { value: notificationChannelDestinationValue } = useField<string>('channel.value');
const { value: frequencyValue } = useField<NotificationFrequency>('frequency');
const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  try {
    await client.createOrUpdateNotification({ requestBody: {
        resource: client.Resources_Camera.CAMERA,
        resourceId: cameraId,
        type: client.NotificationType_CAMERA_BATTERY_VOLTAGE_LESS_THAN.CAMERA_BATTERY_VOLTAGE_LESS_THAN,
        channel: values['channel'],
        frequency: values['frequency'],
        active: values['status'],
        criteria: values['criteria']
      }
    });

    isSubmitting.value = false;
    applicationStore.publishSuccessNotification({
      text: 'Saved',
      autoCloseMs: 3000,
    });
  } catch (error: unknown) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error creating: ${errorMessage}`,
    });

    return;
  } finally {
    isSubmitting.value = false;
  }
});

const onTest = handleSubmit(async (values) => {
  isSubmitting.value = true;
  try {
    await client.createOrUpdateNotification({ requestBody: {
        resource: client.Resources_Camera.CAMERA,
        resourceId: cameraId,
        type: client.NotificationType_CAMERA_BATTERY_VOLTAGE_LESS_THAN.CAMERA_BATTERY_VOLTAGE_LESS_THAN,
        channel: values['channel'],
        frequency: values['frequency'],
        active: values['status'],
        criteria: values['criteria']
      }
    });

    await client.testNotificationByResourceAndType({
      notificationType: client.NotificationType.CAMERA_BATTERY_VOLTAGE_LESS_THAN,
      resource: Resources_Camera.CAMERA,
      resourceId: cameraId,
    });

    isSubmitting.value = false;
    applicationStore.publishSuccessNotification({
      text: 'Saved - A test notification has been sent to your Channel & Destination',
      autoCloseMs: 3000,
    });
  } catch (error: unknown) {
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error creating: ${errorMessage}`,
    });

    return;
  } finally {
    isSubmitting.value = false;
  }
});

async function fetchNotifications(): Promise<void> {
  try {
    let response;
    try {
      response = await client.getNotificationByResourceAndType({
        resource: Resources_Camera.CAMERA,
        resourceId: cameraId,
        notificationType: client.NotificationType.CAMERA_BATTERY_VOLTAGE_LESS_THAN,
      });
    } catch (error) {
      if (error instanceof client.ApiError) {
        if (error.status !== 404) {
          throw error;
        }
      }
    }

    resetForm({
      values: {
        status: response?.active ?? false,
        channel: {
          type: response?.channel.type ?? NotificationChannelType.EMAIL,
          value: response?.channel.value ?? '',
        },
        frequency: response?.frequency ?? NotificationFrequency.ALL,
        criteria: {
          batteryVoltageLessThanEqualTo: response?.criteria?.['batteryVoltageLessThanEqualTo'] ?? 0
        },
      }
    })
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  await fetchNotifications();
});

const ina219BusVoltageValue = computed({
  get: () => ina219BusVoltageToValue(batteryVoltageLessThanEqualToValue.value),
  set: (newValue: number) => batteryVoltageLessThanEqualToValue.value = valueToIna219BusVoltage(newValue)
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />
    <section v-else>
      <form @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              Battery Voltage Threshold
            </Heading>
            <p>Receive a notification when the Cameras battery falls below a Voltage threshold.</p>
          </div>
          <div class="fields">
            <div class="row-half">
              <div class="field">
                <label for="status">Status
                </label>
                <v-select id="status"
                          v-model="statusValue"
                          :clearable="false"
                          :searchable="false"
                          :reduce="(value: any) => value.value"
                          :options="statusOptions"
                          autocomplete="off" />
                <ErrorMessage name="status" class="message message-error" as="p" />
              </div>

              <div class="field">
                <label for="value">
                  Voltage (less than)
                </label>
                <input id="value"
                       v-model="ina219BusVoltageValue"
                       maxlength="3"
                       type="number"
                       step="0.01">
                <ErrorMessage name="criteria.batteryVoltageLessThanEqualTo" class="message message-error" as="p" />
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="channel">Notification Channel
                </label>
                <v-select id="channel"
                          v-model="notificationChannelTypeValue"
                          :clearable="false"
                          :searchable="false"
                          :reduce="(value: any) => value.value"
                          :options="channelOptions"
                          autocomplete="off" />
                <ErrorMessage name="channel.type" class="message message-error" as="p" />
              </div>

              <div class="field">
                <label for="destination">
                  Destination
                </label>
                <input id="destination"
                       v-model="notificationChannelDestinationValue"
                       type="text">
                <ErrorMessage name="channel.value" class="message message-error" as="p" />
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="frequency">
                  Frequency
                </label>
                <v-select id="frequency"
                          v-model="frequencyValue"
                          :clearable="false"
                          :searchable="false"
                          :reduce="(value: any) => value.value"
                          :options="frequencyOptions"
                          autocomplete="off" />
                <ErrorMessage name="frequency" class="message message-error" as="p" />
              </div>
            </div>
          </div>
        </div>

        <ButtonContainer>
          <ButtonComponent :variant="ButtonVariant.Info"
                           :loading="isSubmitting"
                           :is-block-btn="true"
                           :type="ButtonType.Button"
                           @click="() => onTest()">
            Save & Test
          </ButtonComponent>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :loading="isSubmitting"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Save
          </ButtonComponent>
        </ButtonContainer>
      </form>
    </section>
  </ContainerCard>
</template>
