<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue';
import { Bar } from 'vue-chartjs';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import * as client from '@gabrielcam/api-client';

import { reduceData } from '@utils/dataUtils';
import { useMobileDetection } from '@utils/isMobile';

import Loading from '@components/Loading.vue';

const { isMobile } = useMobileDetection();

const props = defineProps({
  stats: {
    type: Object as PropType<client.CameraStat[]>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  unauthorised: {
    type: Boolean,
    required: false,
  },
});

class MeasurementViewModel {
  x: Date = new Date();
  y = '';
}

// Reduce the dataset by setting true/false
const useReduceData = ref(false);

const heaterOn = ref<MeasurementViewModel[]>([]);
const cameraOn = ref<MeasurementViewModel[]>([]);
const piOn = ref<MeasurementViewModel[]>([]);
const data = ref();

// Get the value of a CSS variable from the DOM
const getCSSVariable = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

// Theme Colors
const themeColors: string[] = [
  getCSSVariable('--tls-primary-color'),
  getCSSVariable('--tls-secondary-color'),
  getCSSVariable('--tls-primary-100'),
  getCSSVariable('--tls-gray-800'),
  getCSSVariable('--tls-gray-300'),
];



ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale);

// Desktop View Options
const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      title: {
        display: true,
        text: 'Time',
        color: themeColors[3],
      },
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
        color: themeColors[3],
      },
      grid: {
        display: true,
        color: themeColors[4],
      },
    },
    y: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Power (On | Off)',
        color: themeColors[3],
      },
      ticks: {
        padding: 10,
        stepSize: 1,
        color: themeColors[3],
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            if (value === 1) return 'ON';
            if (value === 0) return 'OFF';
          }
          return value.toString();
        },
      },
      min: 0,
      max: 1,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (context: any) {
          const value = Number(context.raw.y); // Convert the y value to a number
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (value === 1) {
            label += 'ON';
          } else if (value === 0) {
            label += 'OFF';
          } else {
            label += value.toString();
          }
          return label;
        },
      },
    },
    title: {
      display: true,
      text: 'Equipment Status',
      position: 'top',
      align: 'center',
      color: themeColors[3],
      font: {
        size: 18,
        family: 'Open Sans, sans-serif',
      },
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        useBorderRadius: true,
        borderRadius: 2,
        padding: 20,
        color: themeColors[3],
        font: {
          size: 14,
          family: 'Open Sans, sans-serif',
        },
      },
    },
  },
};

// Mobile View Options
const mobileOptions: Partial<ChartOptions<'bar'>> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
      },
      grid: {
        display: true,
      },
    },
    y: {
      display: false,
    },
  },
};



// Merge Chart Options based on isMobile
const finalOptions = computed<ChartOptions<'bar'>>(() => (isMobile.value ? { ...options, ...mobileOptions } : options));

watch(
  () => props.stats,
  () => {
    const applyReduceData = useReduceData.value;

    // Calculate raw data first
    const rawHeaterOn = props.stats.map((stat) => ({
      x: new Date(stat.timestamp),
      y: (stat.heaterOn === true ? 1 : 0).toString(),
    }));
    heaterOn.value = applyReduceData ? reduceData(rawHeaterOn) : rawHeaterOn;

    const rawPiOn = props.stats.map((stat) => ({
      x: new Date(stat.timestamp),
      y: (stat.piOn === true ? 1 : 0).toString(),
    }));
    piOn.value = applyReduceData ? reduceData(rawPiOn) : rawPiOn;

    const rawCameraOn = props.stats.map((stat) => ({
      x: new Date(stat.timestamp),
      y: (stat.cameraOn === true ? 1 : 0).toString(),
    }));
    cameraOn.value = applyReduceData ? reduceData(rawCameraOn) : rawCameraOn;

    // Update the datasets for the chart
    data.value = {
      name: 'Equipment',
      components: {
        Bar,
      },
      datasets: [
        {
          label: 'Heater',
          backgroundColor: themeColors[0],
          data: heaterOn.value,
          xAxisID: 'x',
          yAxisID: 'y',
          borderWidth: 0,
        },
        {
          label: 'Camera',
          backgroundColor: themeColors[1],
          data: cameraOn.value,
          xAxisID: 'x',
          yAxisID: 'y',
          borderWidth: 0,
        },
        {
          label: 'Module',
          backgroundColor: themeColors[2],
          data: piOn.value,
          xAxisID: 'x',
          yAxisID: 'y',
          borderWidth: 0,
        },
      ],
    };
  },
  { immediate: true }
);
</script>

<template>
  <div class="mb-20">
    <Loading v-if="loading" />

    <div v-else-if="unauthorised"
         class="unauthorised-content">
      <b>Equipment</b>
      <p>Unauthorised</p>
    </div>
    <Bar v-else
         id="chart-equipment"
         :data="data"
         :options="finalOptions"
         :style="{ height: isMobile ? '300px' : '300px' }" />
  </div>
</template>
