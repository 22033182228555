<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import LogLines from '@components/LogLines.vue';
import EmptyState from '@layouts/EmptyState.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { SkeletonVariant } from '@viewModels/enums';
import SkeletonLoading from '@components/SkeletonLoading.vue';

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});
const logs = ref<client.Log[]>();
const isLoading = ref<boolean>(false);

onMounted(async() => {
  isLoading.value = true;
  const response = await client.listLogsByResourceAndId({
    id: props.viewId,
    resource: client.Resources.VIEW,
    limit: 5
  });
  logs.value = response.data;
  isLoading.value = false;
})
</script>

<template>
  <SkeletonLoading v-if="isLoading" :variant="SkeletonVariant.Table" :line-count="8" />
  <template v-else>
    <LogLines v-if="logs?.length" :logs="logs" />
    <EmptyState v-else
                heading-text="No logs available"
                :icon-name="IconName.BookOpenIcon"
                :icon-style="IconStyle.Outline" />
  </template>
</template>
