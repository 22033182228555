<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { ToggleVariant } from '@viewModels/enums';

interface ToggleProps {
  variant?: ToggleVariant;
  modelValue?: boolean;
  toggled?: boolean;
  disabled?: boolean;
  title?: string;
}

const props = defineProps<ToggleProps>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'update:toggled', value: boolean): void;
}>();

// For uncontrolled mode, use the toggled prop as initial state.
const internalActive = ref(props.toggled ?? false);
watch(() => props.toggled, (newVal) => {
  internalActive.value = newVal ?? false;
});

// Use v-model (modelValue) if provided, otherwise fall back to internal state.
const isActive = computed(() =>
  props.modelValue
);

// Map variants to default background colors
const variantBgColors: Record<ToggleVariant, string> = {
  [ToggleVariant.Success]: 'color-mix(in oklab, var(--tls-success-color) 50%, white)',
  [ToggleVariant.Danger]: 'color-mix(in oklab, var(--tls-error-color) 50%, white)',
  [ToggleVariant.Dark]: 'var(--tls-gray-500)',
}

// Default background color
const variantBgColor = computed(() =>
  props.variant ? variantBgColors[props.variant] : variantBgColors[ToggleVariant.Success]
);

// Map variants to active background colors
const variantColors: Record<ToggleVariant, string> = {
  [ToggleVariant.Success]: 'var(--tls-success-color)',
  [ToggleVariant.Danger]: 'var(--tls-error-color)',
  [ToggleVariant.Dark]: 'var(--tls-gray-800)',
};

// Active background color
const activeBgColor = computed(() =>
  props.variant ? variantColors[props.variant] : variantColors[ToggleVariant.Success]
);


const toggle = (): void => {
  if (props.disabled) return;
  const newValue = !isActive.value;
  emit('update:modelValue', newValue);
};
</script>

<template>
  <div class="toggle-wrapper">
    <div class="toggle"
         :class="{ 'toggle--active': isActive, 'toggle--disabled': disabled }"
         :style="[{ '--variant-bg': variantBgColor}, { '--active-bg': activeBgColor }]"
         tabindex="0"
         @click="toggle"
         @keydown.enter.prevent="toggle"
         @keydown.space.prevent="toggle">
      <div class="toggle__track" />
      <div class="toggle__thumb" />
    </div>
    <span v-if="title" class="toggle__label">{{ title }}</span>
    <span v-else class="toggle__label">{{ isActive ? 'On' : 'Off' }}</span>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.toggle-wrapper {
  display: inline-flex;
  align-items: center;
}

.toggle {
  width: 50px;
  height: 25px;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: var(--variant-bg);

  &--variant {
    background-color: var(--variant-bg);
  }

  &--active {
    background-color: var(--active-bg);
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &__track {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  &__thumb {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--tls-gray-50);
    transition: transform 0.3s;
  }

  &--active .toggle__thumb {
    transform: translateX(25px);
  }
}

.toggle__label {
  font-size: var(--tls-font-size-xs);
  margin-left: 0.5rem;
}
</style>
